$search-input-height: 40px;
$search-input-padding: 0px 0px 0px 1px;
$search-font-size: 14px;
$search-line-height: 1;
$search-input-icon-size: $search-font-size * $search-line-height;
$search-btn-active: $background-color;

.searchComponent {

  input {
    color: $primary-text-color;
  }

  .searchbox-container {
    margin: 0 auto;
    height: $search-input-height;
    display: inline-block;
    background: $search-background !important;
    border-radius: 20px;
  }

  button.btn:disabled {
    opacity: 0;
  }

  .searchbox .input-group {
    border-radius: 20px !important;
  }

  button.btn.btn-light {
    padding-left: 12px;
    padding-right: 12px;

    &:active,
    &:focus,
    &:focus:active {
      background-color: $search-background;
      box-shadow: none;
      border-color: $search-background;
    }

    svg {
      width: 20px;
      width: 20px;
    }

    svg:not(:active) {
      color: $search-color;
      width: 20px;
      width: 20px;
    }
  }

  .form-control {
    height: 40px;
  }

  .customsearchbutton,
  .searchbox {
    font-size: $search-font-size;
    line-height: $search-line-height;
    border-radius: 20px;
    float: right;
    border: none;
    height: $search-input-height;
    outline: none;
    background-color: $search-background !important;
    border-color: $search-background !important;

    .searchClear {
      width: 32px;

      svg {
        margin-left: -3px;
        height: 20px;
        width: 20px;
      }
    }
  }

  input[type="search"] {
    border-radius: 0;
    -webkit-appearance: none;
    border-radius: 20px;
  }

  .searchbox input {
    padding: $search-input-padding;
    color: $primary-text-color;
    font-size: 1rem;
    background-color: $search-background !important;
    border-color: $search-background !important;
  }

  .searchbox input:focus {
    box-shadow: none;
  }

  .customsearchbutton:focus {
    box-shadow: none;
    outline: none;
  }

  .searchbutton {
    width: 50px;
    padding: 0;
    text-align: center;
    background-color: $search-background !important;
    border-color: $search-background !important;
  }

  .input-group>.form-control:not(:first-child),
  .input-group>.custom-select:not(:first-child) {
    border-top-right-radius: 20px !important;
    border-bottom-right-radius: 20px !important;
  }

  .btn-primary:not(:disabled):not(.disabled).active:focus,
  .show>.btn-primary.dropdown-toggle:focus {
    box-shadow: none;
  }

  .btn-light:not(:disabled):not(.disabled).active:focus,
  .show>.btn-light.dropdown-toggle:focus {
    box-shadow: none;
    outline: none;
  }
}