.onboardingModal {
  font-family: $lato;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px;
  gap: 64px;
  isolation: isolate;
  position: sticky;
  width: 560px;
  height: 689px;
  top: 120px;
  background: $white;
  border-radius: 16px;

  // Container that the modal scrolls within
  &.modal-dialog {
    // Actual modal

    .modal-content {
      align-items: center;
      display: flex;
      justify-content: center;
      flex-direction: column;
      width: 110%;
      color: $modal-content-color;
      pointer-events: auto;
      background-color: $modal-content-bg;
      background-clip: padding-box;
    }

    // Modal header
    // Top section of the modal w/ title and dismiss
    .modal-header {
      align-items: flex-start; // so the close btn always stays on the upper right corner
      background-color: $white;

      .close {
        // auto on the left force icon to the right even when there is no .modal-title
        margin: (-$modal-header-padding-y) (-$modal-header-padding-x) (-$modal-header-padding-y) auto;
        top: 1px;
        position: absolute;
        height: fit-content;
        margin-top: -30px;
        margin-right: -11px;
      }

      .modal-title {
        margin: 3px;
      }
    }

    // Modal body
    .modal-body {
      position: relative;
      flex: 1 1 auto;
      padding: 1rem;
      width: 100%;

      p {
        margin: 0px;
      }
    }

    // Scale up the modal
    @include media-breakpoint-up(sm) {

      // Automatically set modal's width for larger viewports
      .modal-dialog {
        max-width: $modal-md;
        margin: $modal-dialog-margin-y-sm-up auto;
      }
    }

    .modal-content {
      @include box-shadow($modal-content-box-shadow-sm-up);
      border-bottom-left-radius: 16px;
      border-bottom-right-radius: 16px;
    }

    img {
      height: 365px;
      width: 365px;
    }

    .player-wrapper {
      position: relative;
      display: flex;
      justify-content: center;
      height: 275px !important;
    }

    .react-player {
      position: absolute;
      top: 0;
      left: 0;
      border-radius: 10%;
      overflow: hidden;
    }
  }

  p {
    margin-bottom: 0;
    padding-left: 28px;
    padding-right: 28px;
    font-size: 19px;
    font-weight: 600;
    color: $campaign-name-black;
  }

  h2 {
    font-size: 37px;
  }

  h1 {
    font-size: 37px;
  }

  body>div:nth-child(12)>div>div.modal.fade.show>div>div>div.backgroundColor.modal-body>div.modalContentText>p {
    font-weight: 500;
  }

  button.btn.btn-secondary {
    background: $primary-color;
  }

  .singleButton {
    height: 48px;
    padding: 8px 16px;
    border-radius: 8px;
    color: $color-text;
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.4;
    letter-spacing: normal;
    margin: 40px auto 0 auto;
    border: none;
    width: 480px;

    &:hover {
      color: $color-text;
      opacity: 0.8;
    }
  }

  .buttonRight {
    height: 48px;
    width: 232px;
    margin-top: 5px;
    border-radius: 8px;
    background: $white;
    color: $color-text;
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.4;
    letter-spacing: normal;
  }

  .buttonLeft {
    height: 48px;
    width: 232px;
    margin-top: 5px;
    border-radius: 8px;
    background: $white;
    color: $color-text;
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.4;
    letter-spacing: normal;

    .text {
      margin: auto auto;
    }
  }

  &.foundersModal {
    &.modal-dialog .modal-body {
      padding: 2rem;
    }

    &.modal-dialog img {
      padding: 0px;
      display: none;
    }

    button.singleButton {
      margin-top: 30px !important;
    }

    .modal-header .close {
      font-size: 24px;
    }

    button.close {
      font-size: 24px;
    }

    .modal-content {
      max-width: 35vw;
    }

    h3 {
      padding-top: 0px;
    }
  }

  .marginTop30 {
    margin-top: 30px;
  }

  @media (max-width: 500px) {
    .container {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      text-align: center;
    }
  }

  @media (min-width: 501px) {
    .container {
      display: flex;
      justify-content: space-between;
      text-align: center;
    }
  }
}

.completeStepModal {
  text-align: center;
  p {
    margin: 0px;
  }
}