.actionPlanChecklist {
    h1 {
        margin-bottom: 1em;
        font-size: 20px;
        font-weight: 600;
        padding: 0px;
    }

    .bottomMargin {
        margin-bottom: 3em;
    }

    div.btn-styled {
        width: fit-content;
    }
}

.paddingOverride {
    &.neo-tab-container {
        div.col-12:last-child {
            padding: 0px;
        }
    }
}