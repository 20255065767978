.ProspectingView {
  color: $primary-text-color;

  .tabContainer.neo-tab-container {
    div.col-12 {
      padding-left: 24px;
      padding-right: 24px;
    }

  }

  .neo-grid {
    .progress-bar {
      color: $primary-text-color;
    }

    button.btn.btn-light {
      max-height: 32px;
      width: 90px;

      svg {
        margin-bottom: 8px;
      }
    }
  }

  .col-6.col-sm-6.col-md-6.col-lg-3.col-xl-3 {
    padding: 0px;
  }

  .uploadProspect {
    .neo-file-drop {
      width: inherit;
    }
  }

  .marginTop2rem {
    margin-top: 2.5rem;
  }

  .domainURLColor {
    font-size: 13.5px;
    color: $mediumBlue;
    cursor: pointer;
  }

  .domainFontSize {
    font-size: 15px;
    font-weight: 600;
    display: block;
  }

  .marginAuto {
    margin-left: auto;
    display: flex;
  }

  .marginRight10 {
    margin-right: 10px;
  }

  .highlightedText {
    color: $secondary-color;
  }

  .marginBottomMinus30 {
    margin-bottom: -20px;
  }

  .marginLeft10 {
    margin-left: 10px;
  }

  .marginRight6 {
    margin-right: 6px;
  }

  .responsiveOverflow {
    overflow: auto;
    margin: 0px;
  }

  .customPaddingBottom {
    padding-bottom: 48px;
  }

  table {
    td {
      button {
        width: 100%;
      }
    }

    tbody {
      tr {
        border-right: 2px solid #edeeee !important;
      }
    }

  }

  .regionWarning {
    color: rgb(209, 66, 66);
  }

  .redownloadButton {
    min-width: 50px;
    border: none !important;
  }

  .paddingRight30 {
    padding-right: 30px;
  }

  .stopButton {
    &:hover {
      cursor: pointer;
      fill: $lightGrey;
    }

    &:active {
      fill: $white
    }
  }

  @media (max-width: 768px) and (min-width: 375px) {
    label.text-secondary {
      font-size: 12px;
      font-weight: 600;
    }

    div.text-secondary {
      font-size: 11px;
    }

    .jobLevelsFontSizeResponsive {
      font-size: 14px;
    }
  }
}