.CampaignMessageComingView {
    display: flex;
    justify-content: center;
    color: $primary-text-color;

    .bordered {
        border: 2px solid $onBoardingCampaignMessageBorder;
        position: absolute;
        top: 20%;
    }

    .centered {
        justify-content: center;
    }

    .flipIcon {
        -webkit-transform: scaleY(-1);
        transform: scaleY(-1);
    }

    .w-60 {
        width: 50%;
    }

    .form-group {
        .input-group {
            input {
                width: auto;
            }
        }
    }

    .password {
        font-size: 0.65vw;
        font-weight: 400;
        margin: 0px;
        color: $secondary-text-color;
    }

    .bodyContainer {
        background-color: $pillBackground;
        font-size: 18px;
        padding: 50px;
        border-radius: 8px;

        p {
            font-size: 18px;
        }
    }

    .buttonContainer {
        justify-content: center;
        display: flex;
    }

    .scheduleMeetingButton {
        border-radius: 8px;
        width: 320px;
        height: 56px;
        background-color: $switchIconOrange;
        color: $white;
        border: none;

        &:hover {
            opacity: 0.8;
            cursor: pointer;
        }
    }
}