table.table {
  width: 100%;
  margin-bottom: 0px;
  color: $color-text;
  border: 2px solid transparent;
  width: 100%;
  margin-bottom: 0px;
  color: $color-text;
  border: 2px solid transparent;
  border-bottom: 1px solid $bs-light;

  &.neo-grid th .column-sort-icon {
    display: inline;
    position: absolute;
    margin-top: 4px;
    right: 5px;
    bottom: 10px;
  }
  th.column-is-sorted.column-sortable {
    padding-right: 0px !important;
  }

  &.neo-grid th .column-sort-icon {
    display: inline;
    position: absolute;
    margin-top: 4px;
    right: 5px;
    bottom: 10px;
  }

  th.column-is-sorted.column-sortable {
    padding-right: 0px !important;
  }

  > thead th {
    vertical-align: bottom;
    padding-left: 24px;
    vertical-align: middle;
    cursor: pointer;
    font-size: 11px;
    font-weight: 600;
    color: $secondary-text-color;
    text-transform: uppercase;
    border-bottom: 1px solid $border-bottom-table-color;
  }

  .table-bordered thead td,
  .table-bordered thead th {
    border-bottom-width: 1px;
  }

  &.table-bordered th,
  &.table-bordered td {
    border-right: none;
    border-left: none;
  }

  td {
    padding-top: 7px;
    padding-bottom: 8px;
    padding-left: 24px;
    padding-right: 24px;
    color: $primary-text-color;
    vertical-align: middle;
    border-bottom: 1px solid $border-bottom-table-color;

    div.custom-control.custom-checkbox {
      label.custom-control-label::before {
        border-radius: 0rem;
      }

      .custom-control-input:checked ~ .custom-control-label::before {
        color: $white;
        border-color: $mediumGrey;
        background: $mediumGrey;
      }

      .custom-control-input:indeterminate ~ .custom-control-label::before {
        border-color: $mediumGrey;
        background: $mediumGrey;
      }
    }

    div.custom-control.custom-switch {
      label.custom-control-label::before {
        background: $table-switch-color;
        color: $white;
      }

      label.custom-control-label::after {
        background: $white;
      }

      .custom-control-input:checked ~ .custom-control-label::before {
        color: $white;
        border-color: $table-switch-color;
        background: $bs-success;
        outline: none;
      }
    }
  }

  th {
    padding-top: 0px;
    padding-bottom: 10px;
    vertical-align: middle;
  }

  table.neo-grid th.column-is-sorted {
    padding-right: 0px !important;
  }

  .table-bordered th,
  .table-bordered td {
    border-top: 1px solid #dee2e6;
    border-right: 2px solid transparent;
    border-left: 2px solid transparent;
  }
}

// Grid
table.neo-grid {
  th {
    cursor: pointer;
    border-top: none !important;
    background-color: unset !important;
  }

  .number-col {
    text-align: right;
  }

  .date-col {
    text-align: center;

    input {
      text-align: center;
    }

    tr {
      border: 1px solid $border-bottom-table-color;
    }
  }

  .neo-grid-button-column {
    text-align: center;
    width: 1px;
    white-space: nowrap;

    .btn + .btn {
      margin-left: 0.5rem;
    }
  }

  .neo-grid-button-column {
    
    td{
      padding-right: 1px;
      padding-left: 8px;
    }
    
    th{
      padding-top: 0px;
      padding-bottom: 0px;
      padding-right: 24px;
      padding-left: 24px;
    }

    button.btn {
      padding: 5px 24px;
      min-width: 72px;
      height: 32px;
      max-height: 32px;
      text-align: center;
      &.btn-danger {
        min-width: 32px;
        width: 32px;
        height: 32px;
        max-height: 32px;
        padding: 6px 6px 6px 5px;
        svg {
          height: 20px;
          width: 20px;
        }
      }

      &.circled{
        &.btn-light{
          padding: 0;
          height: 32px;
          max-height: 32px;
          min-width: 32px;
          width: 32px;
          color:$primary-text-color;
          svg{
            margin-bottom: 2px;
            height: 16px;
            width: 16px;
          }
        }
      }

      &.btn-outline-info{
        margin-right: 24px;
        min-width: 32px;
        width: 32px;
        max-height: 32px;
        padding: 6px 6px 3px 6px;
      }
    }
  }
  .neo-grid-expand-column {
    width: 1px;
    white-space: nowrap;
  }
}

div.neo-child-row-container {
  overflow: hidden;
  transition: max-height 0.3s ease;
  max-height: 0;
}

.page-item.active .page-link {
  color: $primary-text-color;
  background-color: transparent;
  border-color: $bs-light;
}

.page-link {
  color: $primary-text-color;
}

div.data-scroller {
  position: relative;

  &.scroll {
    padding-right: 17px;
  }

  .scroller {
    position: absolute;
    overflow-x: hidden;
    overflow-y: scroll;
    width: 17px;
    height: 100%;
    right: 0;
    top: 0;
  }
}
