.FunctionsSection {
  .bracketFontSizeSpacing {
    font-size: 16px;
    display: inline;
    color: $secondary-text-color;
  }
  label.custom-control-label {
    font-weight: 600;
  }
  .displayInlineHeadLabels {
    position: relative;
    display: inherit;
  }
  .functRole {
    font-weight: 600;
  }
  .functSubRole {
    font-weight: 100;
    font-size: 12px;
  }
  .inner-card {
    margin: 10px 0px 10px 0px;
  }
  .vidEditing {
    padding-top: 0px;
    padding-bottom: 15px;
    cursor: pointer;
  }
  .col-md-12 {
    padding: 0px;
  }
  .paddingLeft22 {
    padding-left: 22px;
  }
  .negMarginLeft {
    margin-left: -22px;
  }
}
.underlineHashes h1 {
  font-size: inherit;
  font-weight: bold;
  text-decoration: underline;
}
.imgBox {
  width: 350px;
  height: 200px;
  border-radius: 5px;
  padding: 1em 1em;
}
.zIndex100 {
  z-index: 100;
}
.zIndex2 {
  z-index: 2;
}
.topVidHeader {
  background-color: $primary-color;
  text-align: right;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}
.bottomVidHeader {
  background-color: $primary-color;
  text-align: right;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}
.exitIcon {
  padding-right: 5px;
  cursor: pointer;
  color: $headingGrey;
}
