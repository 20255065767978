
.content{
    .spin {
        border-radius: 50%;
        border-top: 1px solid #FBA174;
        border-right: 1px solid #597D9B;
        border-bottom: 1px solid #FBA174 ;
        border-left: 1px solid #597D9B ;
        width: 20px;
        height: 20px;
        -webkit-animation: spin 1.5s linear infinite;
        animation: spin 1.5s linear infinite;
        float: left;
        position: relative;
      }
      span{
          padding-left: 2px;
      }
}
  @-webkit-keyframes spin {
    0% {
      -webkit-transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
    }
  }
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }