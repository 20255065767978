@import "~bootstrap/scss/_functions.scss";
@import "~bootstrap/scss/_variables.scss";

button {
  &.circled {
    &.btn.btn-danger {
      min-width: 32px;
      max-height: 32px;
      height: 32px;
      width: 32px;
      padding: 0.375rem 0.75rem;
      color: $validation-shadow-error-color;

      &:disabled {
        &:hover {
          opacity: 0.4;
          background-color: $bs-light;
        }
      }

      &:active {
        &:hover {
          color: $validation-shadow-error-color;
          opacity: 0.8;
          background: none;
        }
      }

      svg {
        padding: 0;
        left: -2px;
        top: -3px;
        margin-bottom: 0px;
        height: 24px;
        width: 24px;
      }
    }

    &.bulkAction {
      &:active {}

      svg {
        margin-right: -7px;
        margin-left: -8px;
        margin-top: -8px;
      }
    }
  }

  &.circled {
    &.btn.btn-light {
      min-width: 32px;
      max-height: 32px;
      height: 32px;
      width: 32px;
      padding: 0.375rem 0.75rem;
      color: $bs-dark;

      &:disabled {
        &:hover {
          opacity: 0.4;
          background-color: $bs-light;
        }
      }

      &:active {
        &:hover {
          color: $bs-dark;
        }
      }

      svg {
        padding: 0;
        left: -2px;
        top: -3px;
        margin-bottom: 0px;
        height: 24px;
        width: 24px;
      }
    }

    &.bulkAction {
      color: $validation-shadow-error-color;

      &:active {
        color: $validation-shadow-error-color;
      }

      svg {
        margin-right: -7px;
        margin-left: -8px;
        margin-top: -8px;
      }
    }
  }

  &.btn {
    width: auto;
    font-size: 13px;
    font-weight: bold;
    border-radius: 2px;
    outline: none;

    &:focus,
    &:focus-within {
      outline: none !important;
      text-decoration: none;
      border: none;
    }

    &.btn-light {
      padding: 5px 24px;
    }

    &.btn-secondary {
      padding: 5px 24px;
      background-color: $bs-secondary;
      color: $primary-text-color;
      border: $bs-secondary;

      &:hover {
        background: $yellow-spectre-82;
        border: none;
        box-shadow: none;

        &:active {
          background: $yellow-spectre-82;
          border: none;
          box-shadow: none;

          &.active {
            background: $yellow-spectre-82;
            border: none;
            box-shadow: none;

            &:focus {
              background: $yellow-spectre-82;
              border: none;
              box-shadow: none;

              &:visited {
                background: $yellow-spectre-82;
                border: none;
                box-shadow: none;
              }
            }
          }
        }
      }
    }
  }

  &.btn-secondary-alternate.btn.btn-primary {
    background: $secondary-color-alternate;
    border: 1px solid $secondary-color-alternate;

    &:hover,
    &:active,
    &.active,
    &:focus,
    &:visited {
      background: $secondary-color-alternate;
      border: 1px solid $secondary-color-alternate;
      opacity: 0.8;
    }
  }

  &.btn-danger {
    background-color: $bs-danger;
    color: $validation-shadow-error-color;

    &:hover,
    &:not(:disabled):not(.disabled):active,
    &:focus {
      color: $validation-shadow-error-color;
    }
  }

  &.btn-outline {
    background-color: none !important;
  }

  &.btn-primary {
    color: $white;
  }

  &.btn-primary:hover {
    background: $primary-color;
    opacity: 0.8;
    color: $white;

    &:focus {
      color: $white;
    }
  }

  &.btn-outline-primary:hover {
    color: $white;
  }

  &.btn-outline-info:hover {
    color: $white;
    background-color: $secondary-color;
  }

  &.btn-info:hover {
    color: $white;

    &:focus {
      color: white;
    }
  }

  &.btn-light {
    color: $primary-text-color;
    background: $bs-light;

    &:hover {
      background: $emailModal;
    }
  }

  &.btn-sm {
    min-width: 35px;
    width: auto;
    padding: 0px;
  }

  &.btn-primary:active {
    color: $white;
  }

  &.btn-primary:focus {
    color: $white;
  }

  &.btn-warning {
    color: $white;
  }

  &.btn-outline-secondary {
    background-color: transparent;
    border: 1px solid $bs-secondary;
    color: $bs-secondary;

    &:hover {
      background-color: $offWhite;
      border: 1px solid $bs-secondary;
      color: $bs-secondary;
    }
  }

  &.btn-primary:not(:disabled):not(.disabled):active,
  //these are not duplicates one is for dot (.) and other for colon (:)
  &.btn-primary:not(:disabled):not(.disabled).active,
  .show>.btn-primary.dropdown-toggle {
    color: $white;
  }

  &.btn-warning:not(:disabled):not(.disabled):active,
  //these are not duplicates one is for dot (.) and other for colon (:)
  &.btn-warning:not(:disabled):not(.disabled).active,
  .show>.btn-warning.dropdown-toggle {
    color: $white;
  }

  .btn-secondary:not(:disabled):not(.disabled):active,
  .btn-secondary:not(:disabled):not(.disabled).active,
  .show>.btn-secondary.dropdown-toggle {
    background-color: $yellow-spectre-82 !important; //overriding bootstrap
    border-color: $yellow-spectre-82 !important;
    box-shadow: none;
  }

  &.btn-primary.disabled,
  &.btn-primary:disabled {
    color: $white;
    background-color: $bs-primary-disabled;
    border-color: $bs-primary-disabled;
  }

  &.btn-warning.disabled,
  &.btn-warning:disabled {
    color: $white;
  }

  &.btn [class^="icon-"],
  &.btn [class*=" icon-"] {
    display: inline-block;
    margin-top: -2px;
    vertical-align: middle;
    cursor: pointer;
  }

  &.btn-link {
    font-weight: 400;
    color: $primary-color-alternate;
    text-decoration: none;
  }

  td &.btn.btn-primary {
    min-width: 130px;
    width: auto;

    .fa.fa-plus {
      display: none;
    }
  }

  &.btn-primary.disabled,
  .btn-primary:disabled {
    background-color: $bs-primary-disabled !important;
    border-color: $bs-primary-disabled !important;
    background-image: none;
  }

  &.btn-secondary.disabled,
  .btn-secondary:disabled {
    background-color: $bs-secondary-disabled !important;
    border-color: $bs-secondary-disabled !important;
    background-image: none;
  }

  &.btn.disabled,
  &.btn:disabled {
    opacity: 0.4;
  }
}

.input-group-append button.btn-primary {
  &:hover {
    background: $secondary-color;
    border: 1px solid $secondary-color;
    color: $white;
  }
}

.prev-next-buttons.btn-block.btn.btn-primary {
  &:disabled {
    background-color: $bs-primary-disabled;
    border: 1px solid $bs-primary-disabled;
  }

  .btn-outline-primary.active,
  .btn-outline-primary:active {
    color: $white !important;
  }

  .btn-outline-primary:not(:disabled):not(.disabled):active,
  .btn-outline-primary:not(:disabled):not(.disabled).active,
  //these are not duplicates one is for dot (.) and other for colon (:)
  .show>.btn-outline-primary.dropdown-toggle {
    color: $white !important;
  }

  .btn-outline-primary:active {
    color: $white !important;
  }
}

.btn-danger:disabled {
  color: $validation-shadow-error-color;
}