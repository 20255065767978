@import '~bootstrap/scss/_functions.scss';
@import '~bootstrap/scss/_variables.scss';

.neo-file-drop {
    display: flex;
    
    border: 2px dashed $tabBorder;
    background-color: $form-background-color;
    border-radius: 8px;
    height: 200px;

    .btn.btn-light {
        color: $tertiary-color;
        background-color: $form-background-color;

        &:active,
        &:focus,
        &:focus-visible {
            background-color: $form-background-color;
            background: none;
            box-shadow: none;
        }

        &:hover {
            text-decoration: underline;
            opacity: 0.8;
        }
    }

    p {
        margin-bottom: 0;
    }

    .dropZoneText {
        margin: auto;
        svg {
            height: 20px;
            width: 20px;
            margin: 0px 8px 3px 8px;
        }
    }
}