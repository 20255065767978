.InformationManagementView {
  .CardBorder {
    border: 1px solid $bs-light;
  }
  button.btn.btn-light{
    padding: 0;
    svg{
      height: 20px;
      width: 20px;
      margin-bottom: 0;
    }
  }

}
