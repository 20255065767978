.ClientCampaignsView {

  .archiveTabHeader,
  .pagination {
    li {
      font-size: initial;
      font-family: "Lato-regular";
    }
  }

  table.neo-grid {
    th {
      padding-right: 24px;
    }
  }

  button.btn-outline-secondary {
    border: none;
  }

  div.tabs-header {
    padding-left: 0px;
    padding-right: 0px;
  }

  .cogStyle {
    border: none !important; // Overwriting bootstrap button
    margin-bottom: 4.5px;
    font-size: larger;
  }

  .campaignGridMargin {
    margin-bottom: 42px;
  }

  .campaignNameUneditable {
    .input-group {
      background-color: white;

      .form-control:disabled {
        background-color: white;
      }

      .pencilEditName {
        background-color: white !important;
      }
    }
  }

  .campaignNameEditable {
    &.input-group {
      background-color: $form-background-color;
      font-size: 13px;

      .input-group-append.btn {
        background: unset;

        .nameEditSaveCancel {
          button.btn.btn-danger {
            background-color: $bs-danger !important;
            color: $validation-shadow-error-color;
          }
        }
      }
    }

    svg {
      margin-bottom: 0;
    }
  }

  .bordered {
    border: 2px solid $onBoardingCampaignMessageBorder;
  }

  .fa-cogs {
    color: $secondary-color;
  }

  button.primaryBackground.btn-primary {
    background: white;

    &:hover {
      background-color: $offWhite;
    }
  }
}

td.CampainMessageStatusColumnDraft {
  color: #808080;
}

td.CampainMessageStatusColumnActive {
  color: #8cb08c;
}

td.CampainMessageStatusColumnClientReview {
  color: #fba174;
}

.newCommentStyling textarea {
  color: #b3b7b7;
  height: 71px;
  padding-top: 12px;
  padding-bottom: 9px;
}

.comXEditsColumn {
  position: relative;
  font-size: 14px;
  border-top: none;
  height: 71px;
  padding-top: 12px;
  padding-bottom: 9px;
  background-color: #ffffff !important;
  border: solid 1px #dcdddd;
  border-radius: 1px;
  color: #111e1e;
}

textarea.EmailTextArea {
  height: 337px;
}

textarea.signatureTextArea {
  height: 337px;
}

//For open/use follow up templates modal
.openUseTemplateModal {
  min-width: 800px;
  font-family: $lato;

  h3 {
    font-size: 21px;
  }

  .noTemplateMessage {
    text-align: center;
    margin-top: 180px;
  }

  .modal-body {
    padding-top: 0px;
    padding-bottom: 0px;
  }

  textarea.EmailTextArea {
    min-height: 389px;
    max-height: 447px;
    background-color: white;
    resize: none;
  }

  .modal-header {
    display: none;
  }

  .modal-footer {
    border-top: none;
    padding: 0px;
  }
}

.openTemplateVersionDiv {
  background-color: $form-background-color;
}

.openTemplateModalX {
  position: relative;
  bottom: 17px;
  font-size: 18px;
  left: 5px;
  cursor: pointer;
}

.modalBorderBottom {
  border-bottom: 1px solid $modalSideMenuGrey;
}

.openFollowUpTemplateVersion {
  margin: 0px;
  padding-top: 33px;
  padding-bottom: 33px;

  &:hover {
    background-color: $openFolloUpTemplateModalBlue;
    border-bottom: none;
    cursor: pointer;
  }
}

.activeOpenTemplateSidemenu {
  background-color: $openFolloUpTemplateModalBlue;
  border-bottom: none;
  cursor: pointer;
}

.yourEditsColumn {
  position: relative;
  float: right;
  width: 101%;
  font-size: 14px;
  border-top: none;
  height: 71px;
  padding-top: 12px;
  padding-bottom: 9px;
}

.text-indent {
  text-indent: 6px;
}

$validationColor: #cb5151;

.validation-border-red {
  border-color: $validationColor;

  input {
    border-color: $validationColor;
  }
}

.tooltip-style {
  div {
    display: inline;
  }
}

.campaignsTabHeader {
  li {
    font-size: 32px;
    font-family: $lato-bold;
  }

  .nav-item {
    a.nav-link {
      padding: 0;
    }
  }

  .neo-tab-container {
    .col-12:not(.tabs-header) {
      padding-left: 24px;
      padding-right: 24px;
    }
  }
}