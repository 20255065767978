.ReportingView {

  .columnChartStyling {
    padding-left: 0;
    padding-right: 0;

    .hideTooltip {
      opacity: 0;
    }

    .hoverEffect {
      .highcharts-series-group {
        .highcharts-series {
          .highcharts-point {
            &:not(.highcharts-point-hover) {
              opacity: 0.1;
            }
          }
        }
      }
    }

    .highcharts-exporting-group {
      .highcharts-button {
        rect.highcharts-button-box {
          width: 34px;
        }
      }
    }

    .noHoverEffect {
      .highcharts-series {
        .highcharts-point {
          &:not(.highcharts-point-hover) {
            opacity: 1;
          }
        }
      }
    }

    .highcharts-crosshair {
      opacity: 0;
    }

    .highcharts-data-label {
      &:not(.highcharts-point-hover) {
        opacity: 0.5;
      }
    }
  }

  .filterDropDown {
    z-index: 3;
    width: 176px;
  }

  .filterDropDown:after {
    right: 8px;
  }

  .datepicker.form-group {

    .neo-datepicker-container {
      z-index: 3;
      width: 176px;

      input.form-control {
        border-radius: 20px;
        float: right;
        height: 40px;
      }
    }

    select {
      height: 40px;
    }
  }

  .notEnoughChartDataWords {
    position: absolute;
    text-align: center;
    z-index: 2;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    user-select: none;
    display: flex;
    align-items: center;

    .form-group {
      padding-left: 24px;
      width: 500px;
      pointer-events: none;
      margin: auto;

      .form-control {
        text-align: center;
        font-size: 18px;
        font-weight: bold;
        color: $mediumGrey;
      }

      input {
        border-radius: 32px;
      }
    }
  }

  .react-select-bs {
    width: 250px;
    height: 40px;
    z-index: 3;

    .react-select-bs__control {
      border-radius: 16px;
    }

    svg {
      margin-bottom: 0px;
    }
  }
}

.dropdown {
  position: relative;
}

.dropdown-content {
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-top: none;
  z-index: 1;
}

.dropdown-item {
  padding: 10px;
  cursor: pointer;
}

.dropdown-item:hover {
  background-color: #f1f1f1;
}