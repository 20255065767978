//menu is active message
.menuIsActivePopOut {
  
  &.modal-dialog {
    position: absolute;
    left: 10px;
    top: -5px;
    border: none;
    font-family: $roboto-font;

    &.app-right-panel{
      .modal-content {
        background: $bs-secondary;
        border: none;
        width: 400px;
      }
      
      .backgroundColor.modal-body {
        padding-top: 0px;
        padding-bottom: 0px;
        padding-right: 0px;
      }

      .fas.fa-lightbulb {
        margin-top: 9px;
        margin-right: 20px;
        margin-left: 20px;
        color: $bs-secondary;
        font-size: 21px;
      }

      button.close {
        margin-left: 33px;
        margin-right: 0px;
        margin-bottom: -2px;
        position: relative;
        color: $primary-text-color;
      }

      #triangle-left {
        width: 0;
        height: 0;
        border-top: 10px solid transparent;
        border-bottom: 10px solid transparent;
        border-right: 10px solid $bs-secondary;
        position: relative;
        left:-10px;
        top:15px;
      }
    }
  }
}
