.CustomerProfilesView {
  table.neo-grid {
    th {
      padding-right: 24px;
    }
  }

  button.btn-outline-secondary {
    border: none;
  }

  div.tabs-header {
    padding-left: 0px;
    padding-right: 0px;
  }

  .gridFont {
    font-family: $lato;
  }

  .informationBox {
    background-color: $informationBox;
  }

  .noICPBorder {
    border: 1px solid $onBoardingCampaignMessageBorder;
  }

  button.primaryBackground.btn-primary {
    background: white;

    &:hover {
      background-color: $offWhite;
    }
  }

  td.profileName {
    font-size: 14px;
    ;
  }

  td.customerProfileStatus {
    font-size: 14px;
  }

  .custom-select {
    background: #000;
  }
}