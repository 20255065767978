$light-grey-color : $bs-default;

.onboardingStepsMenu {
  background: transparent;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  margin-top: 54px;
  position: fixed;
  min-width: 299px;

  .technical-integration-divider{
    border-top: 1px solid $light-grey-color;;
  }

  .app-header,
  .app-footer,
  .sidebar,
  .main,
  .aside-menu {
    transition: none !important;
    border: none !important;
  }
  .aside-menu-fixed .aside-menu .tab-content {
    height: calc(100vh - 2.375rem - 524px) !important;
    border-radius: 15px;
  }
  .marginTop2 {
    margin-top: 2px;
  }
  //elements
  #triangle-up {
    width: 0;
    height: 0;
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    border-bottom: 15px solid $innerCardBackground;
    margin-left: 10px;
    top:1px;
    position: relative;
  }

  .greenCheck{
    color: $bs-success;
  }

  .smallText {
    font-size: 14px;
    text-transform: none;
  }
  .largeText {
    font-size: 22px;
    padding-top: 8px;
  }
  .list-group-item {
    background-color: $innerCardBackground;
  }
  .app a {
    color: $light-grey-color;
  }
  .list-group-item.active {
    // z-index: 2;
    color: $white !important; //overwriting theme
    background-color: $onboardingMenuActive;
    border-color: $onboardingMenuActive;
    padding: 17px 12px 16px 33px;
    height: 56px;
  
  }
  .list-group-accent .list-group-item.list-group-item-divider {
    position: relative;
    height: 53px;
    padding-left: 33px;
    padding-top: 16px;
  }
  .list-group-item-divider.disabled {
    color: #aaa;
  }
  .list-group-item-action {
    color: $color-text;
}
.text-muted{
  color:$color-text!important;
}
  #root
    > main
    > div
    > div.app-header-panel.pinned
    > div.app-header
    > div
    > div.col-xs-3.col-sm-3.col-md-3.col-lg-3.col-xl-3
    > div
    > div.tab-content
    > div
    > div
    > a:nth-child(7) {
    border-top: 1px solid #ddd;
  }
  .fa.fa-cog.mr-3.mt-1 {
    color: $bs-primary;
  }
  .imgWidth {
    width: 100%;
  }
  .backgroundColor {
    background-color: $bs-info;
  }
  .colorWhite {
    color: $white;
  }
  
  .colorSecondary{
    color:$primary-text-color;
  }
  .list-group-accent.list-group {
    z-index: 1;
  }
  h2 {
    font-size: 32px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
  }
  h5 {
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.56;
    letter-spacing: normal;
  }
  .app-body .main {
    flex: 1 1;
    min-width: 0;
    margin-left: 306px !important;
  }

  .tab-content {
    display: inline-flex;

    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    .active {
      display: inline-flex;
    }
    .tab-pane {
      &.active {
        padding: 0px !important; //overridding bootstrap
      }
    }
  }

  .orange{
    color: $bs-primary;
  }
}
//onboarding content styling (responsive)
@media (min-width: 375px) and (max-width: 768px) {
//   .onboardingStepsMenu {
//     right: 42%;
// }
}
@media (min-width: 769px) and (max-width: 1360px) {
//   .onboardingStepsMenu {
//     right: 42%;
// }
}
//onboarding content styling (responsive)

@media (max-width: 991.98px) {
  .d-md-down-none {
    display: initial !important;
  }
}
@media (min-width: 992px) {
  .aside-menu-off-canvas .app-header .app-body .aside-menu {
    height: calc(100vh - 423px) !important;
  }
}
@media (min-width: 445px) and (max-width: 799px) {
  .aside-menu-show .aside-menu,
  .aside-menu-lg-show .aside-menu {
    margin-right: 62% !important;
    height: calc(100vh - 423px) !important;
  }
}

@media (min-width: 800px) and (max-width: 990px) {
  .aside-menu-show .aside-menu,
  .aside-menu-lg-show .aside-menu {
    margin-right: 71% !important;
    height: calc(100vh - 423px) !important;
  }
}
@media (min-width: 991px) and (max-width: 1100px) {
  .aside-menu-show .aside-menu,
  .aside-menu-lg-show .aside-menu {
    margin-right: 74% !important;
    height: calc(100vh - 423px) !important;
  }
}
@media (min-width: 1101px) and (max-width: 1500px) {
  .aside-menu-show .aside-menu,
  .aside-menu-lg-show .aside-menu {
    margin-right: 80% !important;
    height: calc(100vh - 423px) !important;
  }
}
@media (min-width: 1501px) {
  .aside-menu-show .aside-menu,
  .aside-menu-lg-show .aside-menu {
    margin-right: 82% !important;
    height: calc(100vh - 423px) !important;
  }
}
@media (min-width: 992px) {
  .aside-menu-show.aside-menu-fixed .main,
  .aside-menu-show.aside-menu-fixed .app-footer,
  .aside-menu-lg-show.aside-menu-fixed .main,
  .aside-menu-lg-show.aside-menu-fixed .app-footer {
    margin-right: 0px !important;
  }
}
@media (min-width: 1600px) {
  .onboardingStepsMenu {
    left: 25.5rem;
  }
}
@media (min-width: 992px) {
  .aside-menu-fixed {
    &.aside-menu 
    {
      &.tab-content {
        height: calc(100vh - 327px) !important;
        width: 100%;
        background-color: $innerCardBackground;
      }
    }
  }
}

.marginLeft146{
  margin-left: 146px;
}

.marginLeft100{
  margin-left: 100px;
}

.marginLeft67{
  margin-left: 67px;
}

.marginLeft55{
  margin-left: 55px
}
