.listSelectorModal {
    font-family: $lato;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 40px;
    gap: 64px;
    isolation: isolate;
    position: sticky;
    height: 689px;
    top: 120px;
    background: $white;
    border-radius: 16px;

    // Container that the modal scrolls within
    &.modal-dialog {
        // Actual modal
        max-width: 90%;

        .modal-content {
            //align-items: center;
            display: flex;
            justify-content: center;
            flex-direction: column;
            width: 100%;
            color: $modal-content-color;
            pointer-events: auto;
            background-color: $modal-content-bg;
            background-clip: padding-box;
        }

        // Modal header
        // Top section of the modal w/ title and dismiss
        .modal-header {
            align-items: flex-start; // so the close btn always stays on the upper right corner
            background-color: $white;

            .close {
                // auto on the left force icon to the right even when there is no .modal-title
                margin: (-$modal-header-padding-y) (-$modal-header-padding-x) (-$modal-header-padding-y) auto;
                top: 1px;
                position: absolute;
                height: fit-content;
                margin-top: -30px;
                margin-right: -11px;
            }

            .modal-title {
                margin: 3px;
            }
        }

        .modal-content {
            @include box-shadow($modal-content-box-shadow-sm-up);
            border-bottom-left-radius: 16px;
            border-bottom-right-radius: 16px;
        }
    }

    .clearAll {
        color: $switchIconGrey;

        &:hover {
            cursor: pointer;
            color: $black;
        }
    }

    .selectColumn {
        background-color: $form-background-color;
        width: 100%;
        height: 440px;
        padding: 15px;
        border-radius: 16px;
        font-size: 14px;
    }

    .footerContainer {
        margin-top: 25px;
        border-top: 1px solid $bs-light;

        .footerButton {
            border: none;
            width: 320px;
            height: 56px;
            border-radius: 8px;

            &.cancel {
                background-color: $white;
                color: $onboardingMenuActive;
                width: 100px;

                &:hover {
                    opacity: 0.2;
                }
            }

            &.continue {
                background-color: $onboardingMenuActive;
                color: $white;
                margin-left: 50px;

                &:hover {
                    opacity: 0.8;
                }
            }
        }
    }

    .itemsContainer {
        max-height: 100%;
        color: $mediumGrey;
    }

    .selectColumnInsideContainer {

        &.selectColumn {
            background-color: $form-background-color;
            height: 86%;
            border-radius: 8px;
            overflow: auto;
            word-wrap: break-word;
        }

        &.selectedColumn {
            background-color: $white;
            max-height: 100%;
            border-radius: 8px;
            overflow: auto;
            word-wrap: break-word;

            &.potentialAccountsOffset {
                max-height: calc(100% - 55px);
            }
        }
    }

    .potentialAccounts {
        background-color: $yellow-spectre-50;
        height: 40px;
        border-radius: 8px;
        font-size: 16px;
        margin: 0px 0px 16px 0px;
    }

    .itemHeaderContainer {
        border-bottom: 1px solid $bs-light;
    }

    svg {
        padding: 1px;
        margin-bottom: 2px;

        &:hover {
            color: $black;
            cursor: pointer;
        }

        &.expandChev {
            &:hover {
                color: $mediumGrey;
                cursor: default;
            }
        }
    }

    .selectedItemFont {
        font-weight: bold;
    }

    .searchBar {
        width: 100%;
        max-width: 100%;
        border: none;
        padding-left: 15px;
        padding-right: 5px;
        height: 40px;
        border-radius: 100px;
        margin: 0px;
    }

    .priorityText {
        margin-top: 2px;
    }

    .noSelectedItemsTextArea {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        color: $mediumGrey;
    }

    .error {
        color: $validationRed;
    }

    body>div:nth-child(12)>div>div.modal.fade.show>div>div>div.backgroundColor.modal-body>div.modalContentText>p {
        font-weight: 500;
    }

    .marginTop30 {
        margin-top: 30px;
    }

    .itemCollapsible {
        &:hover {
            color: $black;
            cursor: pointer;
        }
    }

    div.custom-control.custom-switch {
        label.custom-control-label::before {
            background: $table-switch-color;
            color: $white;
        }

        label.custom-control-label::after {
            background: $white;
        }

        .custom-control-input:checked~.custom-control-label::before {
            color: $white;
            border-color: $switchIconOrange;
            background: $switchIconOrange;
            outline: none;
        }
    }
}