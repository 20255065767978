.overflowScroll {
  overflow: scroll;
  overflow-y: hidden;
  overflow-x: auto;
  margin: 0px 24px;
  padding: 0;
}

.headerRow {
  border-bottom: 1px solid $tabBorder;
  left: 0px;
  right: 0px;
  position: absolute;
}

.emailFooter {
  position: fixed;
  margin: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  height: 96px;
  background-color: $form-background-color;
  display: flex;
  padding-left: 115px;
  padding-right: 394px;
  border-top: 1px solid $tabBorder;

  button {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px 8px;
    gap: 8px;
    user-select: none;
  }

  .disabled {
    pointer-events: none;
  }

  .statusCircle {
    width: 16px;
    height: 16px;
    background: #94c446;
    border-radius: 100px;
    flex: none;
    order: 0;
    flex-grow: 0;
  }

  .primaryActionLeadsButton {
    color: white;
    border: none;
    margin: 24px 0px;
    width: 240px;
    height: 48px;
    justify-content: center;

    background: $switchIconOrange;
    border-radius: 8px;

    &:active {
      opacity: 0.8;
    }
  }

  .secondaryActionLeadsButton {
    color: $tertiary-color;
    margin: 24px 0px;
    width: 240px;
    height: 48px;
    justify-content: center;
    border-radius: 8px;
    border: 1px solid $tertiary-color;
  }

  .searchButtonForEmail {
    width: 48px;
    height: 48px;

    svg {
      margin: 12px;
    }

    background: #ffffff;

    border: 1px solid #dcdddd;
    border-radius: 100px;
  }

  .searchTemplatesBordered {
    background-color: white;
    border: 1px solid $tabBorder;
    border-radius: 100px;
    margin: 24px 0px;

    .searchButtonForEmail {
      width: 40px;
      height: 40px;
      margin: auto 4px;

      svg {
        margin: 7px;
      }
    }

    .searchTemplates {
      margin: 0;
    }
  }

  .searchTemplates {
    box-sizing: border-box;

    margin: 24px 0px;
    width: 211px;
    height: 48px;

    background: #ffffff;
    border: 1px solid #dcdddd;
    border-radius: 100px;
  }
}

.searchBar {
  z-index: 10;
  margin: 12px 0px 8px 0px;
  border: none;
  font-size: 14px;

  display: inline;

  max-width: 146px;
  overflow: auto;
  overflow-wrap: normal;
  overflow-y: hidden;

  :active {
    border: none;
  }

  &:focus-visible {
    border: none;
    outline: none;
  }
}

.followUpTemplatesList {
  padding-right: 16px;
  margin: 10px 0px 0px 0px;

  display: inline-flex;

  font-size: 12px;

  .followUpTemplateButton {
    &.newMessage {
      button {
        border: 1px solid $tertiary-color;
        color: $tertiary-color;
      }
    }

    z-index: 1;
    white-space: nowrap;
    display: flex;
    justify-content: center;

    .hoverContainer {
      display: none;
    }

    &:hover {
      .hoverContainer {
        position: absolute;
        bottom: 30px;
        display: block;
        width: 250px;
        z-index: 10;
        background-color: $tmTooltip;
        color: $primary-text-color;
        margin: auto;
        width: min-content;
        text-align: center;
        justify-content: center;
        padding: 4px 16px;
      }
    }

    button {
      position: relative;
      padding: 4px 16px;
      margin-right: 8px;
      border-radius: 100px;
      min-width: 100px;
      max-width: 400px;
      background-color: white;
      white-space: nowrap;
      float: right;
      z-index: 1;
      border: 1px solid $switchIconOrange;
      color: $switchIconOrange;
      overflow: hidden;
    }

    svg {
      margin: 0;
      min-width: 14px;
      min-height: 14px;
    }
  }
}

.actionListView {
  .bold {
    font-weight: 600 !important;
  }

  .checkbox {
    .custom-control-label::before {
      transition: none;
    }
  }

  .row {
    margin: 0px;
  }

  .actionListTabs {
    display: grid;
    grid-template-columns: repeat(3, 1fr) 0.2fr;
    grid-gap: 10px;
  }

  .noleads {
    margin-top: -24px;
  }

  .actionListCardContainer {
    padding: 0px;

    div.neo-card {
      margin-left: 0px;
      margin-right: 0px;
      padding-left: 0px;
      padding-right: 0px;

      .card-body {
        padding: 16px 0px;
      }
    }

    svg {
      margin-bottom: 0px;
    }

    .actionlist-tooltip {
      top: 150%;
    }

    .actionListTableContainer {
      .actionListRowContainer {
        border-bottom: solid 1px $dashboard-row-segregator;
        margin: auto;
        color: $primary-text-color;
        vertical-align: auto;

        .checkboxCol {
          .custom-checkbox {
            margin-left: -15px;
          }
        }

        /* Default styles for the archiveButton */
        .archiveButton {
          color: $switchIconGrey;
          font-weight: 500;
          font-size: 14px;
          line-height: 140%;

          button.btn.btn-light {
            font-weight: 500;
            height: 32px;
            width: 109px;
            padding-top: 3px;
            padding-left: 0;
            padding-right: 0;

            svg {
              height: auto;
              width: auto;
            }
          }
        }

        .custom-control-label::before,
        .custom-control-label::after {
          border-width: 2px;
          box-shadow: none;
        }

        .custom-control-input:focus:not(:checked)~.custom-control-label::before {
          border-color: $checkbox-unchecked-border;
        }

        .selectedCheckbox {

          .custom-control-label::before,
          .custom-control-label::after {
            background-color: $checkbox-background;
          }
        }

        .notAllSelected {

          .custom-control-label::before,
          .custom-control-label::after {
            border-color: $checkbox-background;
            background: 50% / 50% 50% no-repeat;

            // Stroke with alternative color.
            // To change the color, change the stroke here (after the %23) with a hex code (002648 is the hex for this stroke color)
            background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 4 4'%3e%3cpath stroke='%23002648' d='M0 2h4'/%3e%3c/svg%3e");
          }

          .custom-control-label {
            color: $checkbox-background;
            background: $checkbox-background;
          }
        }

        .selectAllLink {
          color: $primary-text-color;
          text-decoration: underline;

          &:hover {
            color: $primary-text-color;
            text-decoration: none;
          }

          cursor: pointer;
        }

        .roundedDropDown.form-group {
          width: 100% !important;
          padding: 0px;
        }

        button.leadOverview {
          position: relative;
        }

        .updateTime {
          width: 100%;
          justify-content: right;
          display: flex;
          font-size: 12px;
          padding: 2px 0px;
        }

        .unseenDot {
          width: 19px;
          height: 19px;
          display: block;
          border-radius: 50%;
          border: 3px solid $white;
          position: absolute;
          color: $validation-shadow-error-color;
          background-color: $validation-shadow-error-color;
          right: 0px;
          top: -5px;
        }

        .actionListTableHeader {
          font-size: 12px;
          font-weight: 500;
          color: $secondary-text-color;
        }

        .leadName {
          font-size: 18px;
          color: $sidemenu-inactive-text;
        }

        .columnSubText {
          font-size: 12px;
          font-weight: 400;
          color: $switchIconGrey;

          &.campaignTitle {
            font-size: 14px;
            font-weight: 500;
            color: $campaign-name-black;
          }
        }

        .columnTitleText {
          font-size: 14px;
          font-weight: 500;
          color: $campaign-name-black;

          svg {
            margin-bottom: 0;
          }
        }

        .darkText {
          color: $primary-text-color;
          font-size: 16px;
          font-weight: bold;
        }

        .mediumGrey {
          color: $mediumGrey;
          font-size: 16px;
          font-family: $lato;
        }

        body {
          margin: 100px;
        }

        * {
          box-sizing: border-box;
        }
      }
    }
  }

  .width-fit-content {
    width: fit-content;
    min-width: 188px;
  }
}

.actionListGrid {
  display: grid;
  grid-column: span;
  grid-template-columns: 0.1fr 0.75fr 1fr 0.9fr 0.9fr 0.40fr;
  gap: 16px;

  padding: 0px 24px;
}