@import "../../../Styles/Variables.scss";

@font-face {
  font-family: "Apple Color Emoji";
  src: url("../../../assets/font/5ab304ade0d4f62d027d12464ca69443.eot");
  /* IE9*/
  src: url("../../../assets/font/5ab304ade0d4f62d027d12464ca69443.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */
    url("../../../assets/font/5ab304ade0d4f62d027d12464ca69443.woff2") format("woff2"),
    /* chrome firefox */
    url("../../../assets/font/5ab304ade0d4f62d027d12464ca69443.woff") format("woff"),
    /* chrome firefox */
    url("../../../assets/font/5ab304ade0d4f62d027d12464ca69443.ttf") format("truetype"),
    /* chrome firefox opera Safari, Android, iOS 4.2+*/
    url("../../../assets/font/5ab304ade0d4f62d027d12464ca69443.svg#Apple Color Emoji") format("svg");
  /* iOS 4.1- */
}

$roboto: "Roboto", sans-serif;

.fa-cogs {
  color: $validation-color-info;
  font-size: 18px;
}

.downloadFileButton{
  min-width: 50px;
  border: none !important;
}

.imgBox {
  width: 350px;
  height: 200px;
  border-radius: 5px;
  padding: 1em 1em ;
}


.z-index {
  z-index: 1000;
}
.vidEditing{
  padding-top: 0px;
  padding-bottom: 15px;     
  cursor: pointer;   
}
.topVidHeader {
  background-color:$primary-color;
  text-align: right;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}
.bottomVidHeader {
  background-color:$primary-color;
  text-align: right;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}
.exitIcon {
  padding-right: 5px;
  cursor: pointer;
  color: $headingGrey;
}
.addICPButton.btn {
  width: 149px;
  height: 40px;
  margin: 0px 0 7px 24px;
  padding: 0 8px 0 8px;
  border-radius: 20px;
}
