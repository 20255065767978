.helpCentreContainer {
    position: sticky;
    top: 50px; // Account for header
    left: 0px;
    z-index: 10;
    min-height: calc(100vh - 50px);
    max-height: calc(100vh - 50px);
    width: 376px;
    background: $white;
    margin: 0px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: stretch;
    align-content: stretch;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    transition: none !important;
    border-left: 1px solid #dcdddd;
    letter-spacing: normal;
    padding: 10px;
    padding-left: 24px;
    color: $primary-text-color;

    .sc-irTswW.kWjQyt {
        background-color: $white;
        min-height: auto;
    }

    .messageContainerBlock{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 16px;
        padding-right: 3px;
        grid-gap: 16px;
        gap: 16px;
        line-height: 140%;
        max-height: calc(100vh - 396px);
        position: fixed;
        top: 130px;
        width: 328px;
        word-wrap: break-word;
        background: $form-background-color;
        border-radius: 16px;
        flex: none;
        order: 0;
        flex-grow: 0;

        &.smallerHeight {
            max-height: calc(100vh - 568px);
        }

        p{
            padding-right: 14px;
        }
    }

    .messageContainer {
        overflow: auto;
    }

    .headerDiv {
        position: fixed;
        top: 70px;
    }

    .supportLinkMainButton {
        align-items: center;
        border: none;
    }

    .exerciseContainer {
        margin-top: 3px;
        flex-direction: column;
        align-items: flex-start;
        padding-left: 16px;
        grid-gap: 24px;
        gap: 24px;
        width: 328px;
        height: 76px;
        background: $form-background-color;
        border-radius: 16px;
        z-index: 1;
        flex: none;
        order: 2;
        flex-grow: 0;

        .dropdownContainer {
            width: 315px;
            display: contents;
        }

        .styles__StyledInput-sc-14t7xlo-1 {
            width: 316px;
            border: none;
            padding: 8px 16px;
            background: $form-background-color;
            border-radius: 8px;
            color: $sidemenu-inactive-text;
            height: 56px;
        }
    }

    .helpCenterFooter {
        position: fixed;
        bottom: 100px;
    }

    .helpCenterDropdownBox {
        border-radius: 16px;
        background: $form-background-color;
        position: relative;
        bottom: 8px;
    }

    .dropdownContainer {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding-left: 16px;
        grid-gap: 24px;
        gap: 24px;
        width: 328px;
        height: 56px;
        border-radius: 16px;
        background: $form-background-color;
        z-index: 1;
        flex: none;
        order: 2;
        border: none;
        flex-grow: 0;

        &.option {
            height: 48px;

            &:hover {
                background: $bs-light;
            }
        }

        &.comXHub {
            border-top: 1px solid $bs-light;
            height: 80px;
            padding-top: 8px;
            padding-left: 16px;
            .styles__StyledInput-sc-14t7xlo-1 {
                margin-top: 3px;
                padding-bottom: 6px;
            }

            label {
                margin: 0px;
                margin-bottom: 3px;
            }
        }

        svg {
            text-align: right;
            justify-items: center;
            margin-bottom: 0;
        }

        .linkIcon {
            border: none;
            background-color: $white;
            height: 40px;
            border-radius: 10px;
            margin-top: 21px;
            margin-left: 3px;

            svg {
                color: $primary-text-color;
            }
        }

        .textDiv {
            text-align: left;
            margin-top: 17px;
        }

        .exerciseTextDiv {
            text-align: left;
        }

        .subTextDiv {
            text-align: left;
            font-size: 12px;
            color: grey;

            svg {
                padding: 5px;
                padding-left: 0px;
                margin-bottom: 10px;
                margin-top: 8px;
            }
        }

        text {
            margin-left: 17px;
            font-size: 14px;
        }

        .dropdownComXHub {
            background-color: $white;
            height: 40px;
        }
    }

    button.btn {
        min-width: 100%;
    }

    p {
        font-weight: 300;
        font-size: 16px;
        margin-bottom: 0px;
    }

    h4,
    h5 {
        font-size: 0.9vw;
        font-family: $lato;
    }

    h5 {
        font-weight: normal;
    }

    h4 {
        font-weight: bold;
    }

    .row {
        margin-left: 0px;
        margin-right: 0px;
    }

    .header-box {
        align-content: flex-start;
        flex-shrink: 0;
        flex-grow: 0;
        background-color: $yellow-spectre;
        flex: 0 0 auto;
        margin: 0px;
        padding-bottom: 0px;

        h1 {
            font-size: 32px !important;
            line-height: 1.31;
            font-weight: 500;
            margin-bottom: 0px;
            margin-top: 10px;
            padding-left: 16px;
            padding-right: 16px;
        }

        iframe {
            width: 100%;
            height: 100%;
        }
    }

    .tab-button-box {
        flex-shrink: 0;
        flex-grow: 0;

        .tab-buttons {
            box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.3);
            background-color: $yellow-spectre;
            color: $onboarding-inactive;
            width: 100%;
            height: 40px;
            font-size: 0.7vw;
            font-weight: normal;
            border-radius: 0px;
            border-bottom: 2px solid $onboarding-inactive-border;
            box-shadow: none;

            //overwriting bootstrap with important
            &:hover {
                background: $yellow-spectre !important;
                border: 1px solid $yellow-spectre !important;
                color: $primary-text-color !important;
                height: 40px;
                font-size: 14px;
                opacity: 0.8;
            }

            &:active {
                background-color: $yellow-spectre !important;
                color: $primary-text-color;
                font-weight: 500;
                width: 100%;
                border-bottom: $primary-text-color 3px solid;
                box-shadow: none;
            }
        }

        .selected-btn {
            background-color: $yellow-spectre !important;
            color: $primary-text-color;
            font-weight: normal;
            width: 100%;
            font-size: 0.8vw;
            border-bottom: $primary-text-color 3px solid !important;

            &:active {
                background-color: $yellow-spectre !important;
                color: $primary-text-color;
                font-weight: 500;
                width: 100%;
                border-bottom: $primary-text-color 3px solid !important;
                box-shadow: none;
            }
        }
    }

    .text-box {
        max-height: calc(100vh - 35vh - 120px);
        overflow-y: scroll;
        flex-shrink: 1;
        flex-grow: 1;
        margin: 0px;
        padding: 16px;
        overflow-wrap: break-word;

        span {
            font-family: $lato !important; //overwriting database
        }
    }

    .prev-next-box {
        flex-shrink: 0;
        flex-grow: 0;
        min-height: 80px;
        align-content: flex-end;
        padding: 20px 16px 20px 16px;
        border: 1px solid $yellow-spectre-50 !important;

        .prev-next-buttons {
            width: 100%;
            height: 40px;
            border-radius: 6px;
            margin: 0 0;
            font-size: 16px;
            font-weight: 500;
            background: $yellow-spectre-32;
            color: $primary-text-color;
            font-weight: bold;
            border-radius: 20px;

            &.btn-primary {
                background: $secondary-color;
                color: $primary-text-color;
                border: none;

                &:hover {
                    background: $secondary-color;
                    color: $primary-text-color;
                    box-shadow: 0 0px 0px 0 $secondary-color;
                    background-image: none;
                    opacity: 0.8;
                }

                &:focus,
                :focus-within {
                    border: none;
                    outline: none;
                }

                &:disabled {
                    background-color: $yellow-spectre-32;
                    color: $yellow-spectre-108;
                    box-shadow: 0 0px 0px 0 $yellow-spectre-32;
                    background-image: none;

                    &:hover {
                        background-color: $yellow-spectre-32;
                        color: $yellow-spectre-108;
                        box-shadow: 0 0px 0px 0 $yellow-spectre-32;
                        background-image: none;
                    }
                }
            }
        }

        .single-next-button {
            width: 100%;
            height: 40px;
            border-radius: 20px;
            margin: 0 auto;
            font-size: 0.8vw;
            font-weight: bold;

            &:hover {
                background: $bs-secondary;
                border: 1px solid $bs-secondary;
                opacity: 0.8;
            }
        }

        .prev-button-color {
            background-color: $bs-light;
            color: $primary-text-color;
            box-shadow: 0 0px 0px 0 $bs-light;
            border-radius: 20px;
            outline: none;
            border: none;

            &:hover {
                background: $bs-light;
                opacity: 0.8;
                outline: none;
                border: none;
            }

            &:focus,
            &:focus-visible,
            &:visited {
                border: none;
                outline: none;
            }
        }

        .stuck-wording {
            width: 100%;

            .email-link {
                color: $white !important;
                text-decoration: underline;
            }
        }
    }

    svg {
        margin-top: -4px;
    }

    .sc-cRZddA.kYfpiL {
        position: absolute;
        bottom: 60px;

        .arrowIcon {
            float: right;
        }

        svg {
            float: right;
            margin: 0px;
            padding: 3px;
        }
    }

    .styles__StyledInput-sc-14t7xlo-1 {
        width: 98%;
        border: none;
        padding: 8px 16px;
        background: $form-background-color;
        border-radius: 8px;
        color: $sidemenu-inactive-text;
        height: 40px;
    }

    .sc-iCmkLe.dfekNd {
        margin-right: 10px;
    }
}