.ClientView {
  color: $primary-text-color;
  .btn-outline-info {
    padding: 5px 24px;
    min-width: 32px;
    height: 32px;
    max-height: 32px;
  }

  table.neo-grid.table-bordered{
    th{
      padding-right: 24px;
    }
  }

  .clientbtn {
    button.btn {
      padding: 5px 24px;
      min-width: 72px;
      height: 32px;
      max-height: 32px;
    }
  }

  .client-view-small-button {
    margin: 8px 8px;
  }
}
