.badge {
  min-width: 15px;
  height: auto;
  width: auto;
  color: gray;
  background: none;
  border: solid;
  border-width: thin;
  font-size: x-small;
  vertical-align: text-bottom;
}

.commentBadge {
  min-width: 30px;
  height: auto;
  width: auto;
  color: white;
  // background: none;
  background-color: #fba174;
  border: solid;
  border-width: thin;
  font-size: 14px;
  padding-bottom: 3px;
}

.commentDeletePadding {
  padding-left: 10px;
  color: red;
  &:hover {
    cursor: pointer;
  }
}

.labelBadgeInt {
  min-width: 60px;
  height: auto;
  width: 101%;
  color: #ca774d;
  background-color: #ffe0d1;
  border: solid;
  border-width: thin;
  font-size: 16px;
  padding-bottom: 3px;
  text-align: center;
  border-color: #ced4da;
  font-weight: 600;
}

$validationColor: #cb5151;

.labelBadgeInt-val {
  border-bottom-color: $validationColor;
}

.labelBadgeExt {
  min-width: 60px;
  height: auto;
  width: 101%;
  color: #597d9b;
  background-color: #ecf0f4;
  border: solid;
  border-width: thin;
  font-size: 16px;
  padding-bottom: 3px;
  text-align: center;
  border-color: #ced4da;
  font-weight: 600;
  float: right;
}

.labelBadgeExt-val {
  border-bottom-color: $validationColor;
}
