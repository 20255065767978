.pager {
    color: $mediumGreyText;
    height: 100%;
    margin-top: 7px !important;
    margin-left: 14px !important;

    .navButton {
        margin-top: 8px;
        color: $tabBorder;

        &:hover {
            cursor: pointer;
            color: $mediumGreyText;
        }
    }

    .navButtonDisabled {
        margin-top: 8px;
        color: $tabBorder;

        &:hover {
            // cursor:not-allowed;
        }
    }

    .currentPage {
        display: inline-flex;
        flex-direction: column;


        input[type=number] {
            width: 50px;
            padding: 5px;
            color: $mediumGreyText;
            border: 1px solid $tabBorder;
            border-radius: 8px;
        }
    }


    .currentPage .arrows {
        display: flex;
        flex-direction: column;

    }

    .currentPage .arrow-up,
    .currentPage .arrow-down {
        width: 15%;
        padding: 50px;
        border: none;
        background-color: none;
    }

    // Style the dropdown
    select:valid {
        color: $mediumGreyText;
        border-radius: 8px;
        border: 1px solid $tabBorder;
        margin-top: 1px;
        padding-top: 2px;
    }

    .marginTop2 {
        margin-top: 2px;
    }

    .marginTop8 {
        margin-top: 8px;
    }
}