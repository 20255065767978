.overlay {
    position: absolute;
    z-index: 3;
    &.overlayManagementFunctionsRoles {
        width: 49%;
        height: 100%;
        background-color: #000;
        cursor: pointer;
        padding: 0px;
        opacity: 0.4;
        .disabledText {
            position: absolute;
            top: 50%;
            bottom: 50%;
            left: 41%;
            font-size: 18px;
            color: #fffcfb;
            font-weight: 600;
        }
    }
    &.displayNone {
        display: none;
    }
    &.overlayRoles {
        width: 97.7%;
        height: 1.9%;
        background-color: #000;     
        cursor: pointer;
        padding: 0px;
        opacity: 0.4;
        right: 3.5%;
        left: 1.2%;
        .disabledText {
            position: absolute;
            top: 22%; 
            left: 41%;
            font-size: 15px;
            color: #fffcfb;
            font-weight: 600;
        }
    }
}
