.ReminderSideMenu {
  // Styling applied to the menu
  min-width: 370px;
  background: $form-background-color;
  border-left: 1px solid $tabBorder;
  padding: 24px 16px;
  height: calc(100vh);
  max-height: calc(100vh);
  display: flex;
  right: 0;
  flex-direction: column;
  gap: 16px;
  position: fixed;
  z-index: 1;

  overflow-y: auto;
  color: $sidemenu-inactive-text;
  font-weight: bold;

  .clickableCursor {
    cursor: pointer;
  }

  .reminder-loading {
    display: flex;
    margin-top: 16px;
    justify-content: center;
  }
}

.ReminderForm {
  color: $sidemenu-inactive-text;
  font-weight: 500;

  .neo-card.card {
    margin: 0;
    padding: 16px;
    border-radius: 8px;
  }

  .reminder-form-label {
    font-size: 12px;
    line-height: 16.8px;
  }

  .reminder-validation-failure {
    font-size: 12px;
    color: $validationRed;
  }

  .form-group {
    color: $sidemenu-inactive-text;

    label,
    textarea {
      font-size: 12px;
      line-height: 16.8px;
    }

    .form-control {
      border-radius: 4px;
    }

    .neo-datepicker-container {
      .form-control {
        padding-left: 24px;
        padding-right: 24px;
      }
    }
  }

  .time-selector {
    z-index: 2;
    height: 40px;
    width: 224px;
    background-color: white;
    border: 1px solid $form-background-color;
    border-radius: 4px;
    display: flex;
    align-items: center;

    .time-container {
      margin: auto;
    }

    .timeBlock {
      width: 32px;
      height: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: $form-background-color;

      cursor: pointer;
      user-select: none;

      &:hover {
        background-color: $sidemenu-skyblue;
        opacity: 0.8;
      }
    }
  }

  .form-button-group {
    font-size: 12px;
    font-weight: 500;

    .btn-styled {
      height: 40px;
      padding: 10px 24px;

      margin-top: 16px;
      margin-right: 8px;
    }

    .btn-styled:last-child {
      margin-right: 0;
      margin-left: 8px;
    }
  }
}

.ReminderList {
  font-weight: 500;

  .reminderCard {
    background-color: white;
    border-radius: 8px;
    border: 1px solid $bs-light;
    padding: 16px;
    margin-bottom: 16px;
  }

  .reminder-title {
    font-weight: bold;
    max-width: 224px;
  }

  .reminder-description {
    font-weight: 500;
    max-width: 224px;
  }

  .reminder-date-section {
    color: $switchIconGrey;

    svg {
      margin-bottom: 0;
    }

    .reminder-date-card {
      background-color: $form-background-color;
      padding: 4px 8px;
      border-radius: 4px;
      width: 212px;
    }

    .reminder-button {
      display: flex;
      align-items: center;
      justify-content: center;

      cursor: pointer;

      margin-left: 8px;
      background-color: $form-background-color;
      border-radius: 4px;
      height: 32px;
      width: 32px;
    }

    .complete {
      &:hover {
        color: $bs-success;
      }
    }
  }
}