.screenNavigator {

  &.row {
    width: 100%;
    background-color: $white;

    &.allItemsComplete {
      background-color: $screenNavigatorCompleteBackground;
    }
  }

  .navigationBar {
    background-color: $white;
    height: 40px;
    padding: 0;
  }

  .navigatorButton {
    height: 40px;
    background: $sidemenu-skyblue;
    border-radius: 8px;
    color: $sidemenu-inactive-text;
    width: 134px;
    font-weight: 500;
    font-size: 20px;
    z-index: 1;
  }

  .stepsCompleted {
    color: $sidemenu-inactive-text;
    font-weight: 500;
    flex-basis: 0;
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .navigatorDropdownButton {
    width: 40px;
    height: 40px;
    background: $screenNavigatorBackground;
    border-radius: 8px;
    padding: 7px;
    padding-left: 14px;
    cursor: pointer;
  }
}