.AddEditTargetMarketsView {

  .neg-m-md {
    margin-top: -25px;
  }

  .custom-control {
    padding-left: 34px;
  }

  .roleText .custom-control {
    padding-left: 24px;
    position: relative;
    display: inherit;
  }

  .highlightedText {
    color: $secondary-color;
  }

  .expandRegionAdvancedSection {
    height: 37px;
    width: 37px;
    border: unset;
    border-radius: 24px;
    padding-top: 7px;
    padding-left: 8px;
    margin: 28px 0px 8px 20px;
  }

  .cardStyles {
    margin: 24px !important;

    svg {
      margin-right: 5px;
      width: 20px;
      height: 23px;
    }
  }

  .marginAuto {
    margin-left: auto;
    display: flex;
  }

  table.table {
    border: 1px solid $border-bottom-table-color;
    margin-bottom: 0px;
    color: #343332;

    //This is specific to TM grids because they are different to global styles so overwriting with important
    thead th {
      border-top: 1px solid transparent !important;
      border-right: 1px solid transparent !important;
      border-left: 1px solid transparent !important;
    }

    td.neo-grid-expand-column {
      button.btn {
        height: 32px;
        min-width: 32px;
        max-height: 32px;
        border-radius: 20px;
      }

      .btn.btn-light.btn-sm {
        padding: 0px;
      }
    }
  }

  div.custom-control.custom-switch {
    label.custom-control-label::before {
      background: $table-switch-color;
      color: $white;
    }

    label.custom-control-label::after {
      background: $white;
    }

    .custom-control-input:checked~.custom-control-label::before {
      color: $white;
      border-color: $switchIconOrange;
      background: $switchIconOrange;
      outline: none;
    }
  }

  .tMScreen5 {
    .otherFunctionsAddButton {
      border: none;
      width: 200px;
      height: 56px;
      border-radius: 8px;
      background-color: $onboardingMenuActive;
      color: $white;
      margin-top: 25px;
      margin-left: 50px;
      padding-left: 0px;

      &:hover {
        cursor: pointer;
        opacity: 0.8
      }
    }
  }

  .targetMarketAddEditSubQuestion {
    color: $mediumGreyText;
    font-size: 14px;

    label {
      font-size: 14px;
    }
  }

  .css-1n7v3ny-option {
    background-color: $bs-light;
  }

  .screen1 {
    min-height: 64vh;
  }

  .target-market-modified {
    font-size: 12px;
  }

  .css-1rhbuit-multiValue {
    border-radius: 4px;
    font-weight: normal;
    padding: 0 2px 0 2px;
    font-weight: 400;
    color: $sidemenu-inactive-text;

    .css-xb97g8 {
      svg {
        color: $sidemenu-inactive-text;
      }
    }
  }

  .css-12jo7m5 {
    padding: 8px;
  }

  .css-1okebmr-indicatorSeparator {
    background-color: transparent;
  }

  .form-group div.custom-control.custom-checkbox {
    label.custom-control-label::before {
      border-radius: 0rem;
    }

    .custom-control-input:checked~.custom-control-label::before {
      color: $white;
      border-color: $mediumGrey;
      background: $mediumGrey;
    }

    .custom-control-input:indeterminate~.custom-control-label::before {
      border-color: $mediumGrey;
      background: $mediumGrey;
    }
  }

  .paddingRight {
    padding-right: 10px;
  }

  td div.custom-control.custom-switch {
    padding-left: 43px;
  }

  .width11Percent {
    width: 11%;
  }

  .width16Percent {
    width: 16%;
  }

  .width12Percent {
    width: 12%;
  }

  .width10Percent {
    width: 10.6%;
  }

  .width69Percent {
    width: 69%;
  }

  .width91Percent {
    width: 91%;
  }

  .width20Percent {
    width: 20%;
  }

  div:nth-child(2)>div>div.card-body>div>div>table>thead>tr>th.neo-grid-expand-column {
    width: 1%;
  }

  .infoLabel.primary.info {
    margin-bottom: 10px;
  }

  .subIndustryHide {
    thead {
      th {
        display: none;
      }
    }
  }

  .subIndustryHide {
    thead {
      th {
        display: none;
      }
    }
  }

  .managementFunctionSubHide {
    thead {
      th {
        display: none;
      }
    }
  }

  .managementFunction {
    .hideButtonExpandButton {
      .neo-grid-expand-column {
        button {
          display: none;
        }
      }
    }
  }

  .displayNone {
    display: none;
  }

  .mandatoryText {
    color: #cb5151;
    font-size: 12.8px;
    padding-bottom: 2px;
  }

  .pl-12 {
    padding-left: 12px;
  }

  .validationRedOutline {
    input {
      border: #cb5151 solid 1px;

      &:focus {
        box-shadow: 0 0 0 0.2rem rgba(203, 81, 81, 0.25);
      }
    }
  }

  .validationRedOutline {
    select {
      border: #cb5151 solid 1px;

      &:focus {
        box-shadow: 0 0 0 0.2rem rgba(203, 81, 81, 0.25);
      }
    }
  }

  .infoLabel.info {
    color: $primary-text-color;
  }

  .infoLabelHeading label {
    font-size: 11px;
    color: $secondary-text-color;
  }

  .questionTextBox {
    border-radius: 8px;
    min-height: 48px;

    svg {
      margin-bottom: 0px;
    }

    select,
    input {
      &.form-control {
        border-radius: 8px;

        &:disabled {
          opacity: 0.4;
          background-color: $form-background-color;
        }
      }
    }

    &.isNotEditable {

      select,
      input {
        &.form-control {
          &:disabled {
            opacity: 1;
          }
        }
      }
    }
  }

  .numberOfEmployeesContainer {
    margin-bottom: 120px;
  }

  .potentialAccounts {
    background-color: $yellow-spectre-50;
    height: 40px;
    border-radius: 8px;
    font-size: 16px;

    margin: 0px 0px 16px 0px;
  }
}

#confirm {
  display: none;
  background-color: #91ff00;
  border: 1px solid #aaa;
  position: fixed;
  width: 250px;
  left: 50%;
  margin-left: -100px;
  padding: 6px 8px 8px;
  box-sizing: border-box;
  text-align: center;
}

#confirm button {
  background-color: #48e5da;
  display: inline-block;
  border-radius: 5px;
  border: 1px solid #aaa;
  padding: 5px;
  text-align: center;
  width: 80px;
  cursor: pointer;
}

#confirm .message {
  text-align: left;
}