.legacy {
    .infoCard {
        background-color: $informationBox;
        margin-left: 0px;
        margin-right: 0px;
    }

    .infoCardTextPadding {
        padding-right: 0;
        padding-bottom: 10px;
        padding-left: 0px;
        padding-top: 10px;
    }

    .infoCardArrow {
        display: flex;
        justify-content: center;
        align-items: center;
        flex: none;
        float: right;
        -moz-border-radius: 50%;
        -webkit-border-radius: 50%;
        border-radius: 50%;
        font-size: 15px;
        width: 30px;
        height: 30px;
        color: $dashboard-expand-indicator;
        border: none;
        background-color: $yellow-spectre-32;
        padding-top: 8px;
    }
}

.newCardStyle {
    padding: 24px;
    width: 100%;

    .infoCardArrow {
        display: flex;
        justify-content: center;
        align-items: center;
        flex: none;
        float: right;
        font-size: 16px;
        width: 32px;
        height: 32px;
        color: $dashboard-expand-indicator;
        padding-top: 8px;
        border: none;
        background: none;
    }

    h4 {
        padding: 0px;
        margin: 0px;
    }

    .bodyText{
        padding-left: 0px;
        padding-right: 0px;
        padding-top: 16px;
    }
}