.email-setup-view {
  display: flex;
  justify-content: center;
  color: $primary-text-color;
  .width-100 {
    width: 100%;
  }

  img {
    margin-top: 5vw;
    margin-bottom: 2vw;
  }

  .container {
    display: flex;
    justify-content: center;
    margin: 0%;
  }
}

.skip-for-now {
  color: $secondary-text-color;
  font-size: 13px;
}

.technical-requirements-background {
  background-color: #dddddd;
}

.technical-requirements-background>div>textarea {
  resize: none;
}

.technical-requirements-box {
  height: 438px;
  padding: 21px 20px 17px;
  border-radius: 2px;
  border: solid 2px lightgray;
  background-color: #dddddd;
}

.technical-requirements-header {
  font-size: 16px;
  line-height: 1.31;
  letter-spacing: normal;
}

.technical-requirements-details {
  font-size: 16px;
  line-height: 1.31;
  letter-spacing: normal;
}

.newCustomButton {
  border-radius: $btn-radius;
  height: 40px;
  padding: 0%;
  font-family: $lato;
  font-weight: bold;
  font-size: 16px;
  padding: 6px;
  color: $primary-text-color;
  text-align: center;
  width: 100%;
}

a.hide-show-technical-requirements-link {
  font-size: 16px;
  color: $primary-text-color;
  font-weight: bold;
  line-height: 1.31;
  letter-spacing: normal;
  cursor: pointer;
}

a.skip-for-now {
  line-height: 1.31;
  letter-spacing: normal;
  cursor: pointer;
}