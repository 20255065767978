.IROJobSection {
    .fa-times:before {
      position: relative;
        top: 1.5px;
    }
    .fa-check:before {
      position: relative;
        top: 1.5px;
    }
    .col-2{
        max-width: none;
        &:first-child{
            padding-left: 0px;
        }
    }


    @media (max-width: 1075px) and (min-width: 375px) {
        .jobLevelsFontSizeResponsive {
            font-size: 12px;
            display: inline-flex;
        }
        .fa-times:before {
            top: 4.2px;
        }
        .fa-check:before {
            top: 4.2px;
          }
          .responsiveMarginLeft{
              margin-left:5px;
          }
    }
}
