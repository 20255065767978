.CustomerProfileQuestionView {
    .numberWidth {
        max-width: 60px;
    }

    .input-group{
        button.btn.btn-light{
            padding: 0;
            margin-right: 5px;
            margin-top: 2px;
            min-width: 32px;
            width: 32px;
            height: 32px;
        }
        svg{
            width: 20px;
            height: 20px;
            margin-bottom: 0;
        }
    }
}