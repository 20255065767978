.toolbar {
  padding-bottom: 20px;
}

.bottom-toolbar {
  padding-top: 20px;
}

.tab-pane {
  padding-top: 18px;
}

.manage-user-group {
  .tab-content {
    padding: 15px 0px 15px 0px;
  }
}

.group-roles {
  li {
    list-style-type: none;
  }

  .role-item {
    button.btn.btn-light {
      background: none;
      border: none;
      min-width: 35px;
      box-shadow: none;
      margin-left: 0px;
      margin-right: 5px;
      padding-left: 0px;
      padding-right: 0px;
      &:active:focus{
        box-shadow: none;
      }
    }

    .role-lock {
      color: #d90000;
    }

    .role-unlock {
      color: #109110;
    }

    .role-undefined {
      color: #ffc107;
    }

    .role-item-text {
      cursor: pointer;
      display: inline;
      vertical-align: middle;
    }

    &.no-select {
      .btn {
        cursor: default;
      }

      .role-item-text {
        cursor: default;
      }
    }

    .role-expander {
      margin-right: 10px;
    }
  }
}

.role-tools {
  margin-left: 20px;
}