div.ClientSettingsView {
  color: $primary-text-color;

  button.btn-outline-secondary {
    border: none;
  }

  div {
    &.neo-tab-container {
      div {
        &.col-12 {
          padding: 0;
        }

        &.addEmail.col-12 {
          padding-right: 24px;
        }

        &.col-md-6 {
          padding-left: 0px;
          padding-right: 0px;

          label {
            margin: 0;
          }
        }
      }
    }
  }

  .m-0.marginTop24.row.neo-tab-container.neo-tab-card-mode {
    margin-top: 0px !important;
    border: 1px solid lightgray;
  }

  //clientReviewResponsive customMarginTop35

  // Could be made global, but unsure about margins
  .informationBox {
    background-color: $informationBox;
    margin-top: 1.5vw;
  }

  ins {
    font-size: 16px;
    font-family: $lato;
    font-weight: bold;
  }

  .blacklistSVG svg {
    margin-top: 29px;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  li,
  p,
  b,
  ins,
  strong {
    color: $primary-text-color;
  }

  .input-group {
    button.btn svg {
      width: auto;
      height: auto;
    }

    .btn.btn-light {
      padding: 0px;
    }
  }

  .form-control {
    color: $primary-text-color;
  }

  h5 {
    margin: 0;
  }

  p {
    margin-bottom: 0px;
    margin-top: 16px;
    font-size: 16px;
  }

  h6 {
    font-family: "Lato";
    font-size: 16px;
  }

  .textPadding {
    padding-top: 1vw;
    padding-left: 1vw;
  }

  .woodPeckerAPIKey {
    margin-top: 32px;
    margin-bottom: 10px;
    color: $mediumGrey;
    font-size: 13px;
  }

  .playPadding {
    margin-bottom: 0.15vw;
    margin-right: 0.25vw;
  }

  .externalLinkPadding {
    margin-right: 0.25vw;
  }

  .highlyRecommendedCS {
    border-radius: 50%;
    padding: 0.1vw;
    background-color: $blue-spectre;
    color: $white;
    bottom: 0.1vw;
    position: relative;
    font-size: 0.5vw;
  }

  .hrecommendedBody {
    font-size: 0.55vw;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    color: $primary-text-color;
  }

  @media screen and (min-width: 345px) and (max-width: 769px) {
    .clientReviewResponsive {
      position: relative;
      bottom: 15px;
    }

    .buttonWidthResponsive {
      min-width: 80px;
      width: auto;
    }

    .nav-link {
      height: 40px;
      font-size: 12px;
    }
  }

  @media screen and (min-width: 345px) and (max-width: 580px) {
    div.custom-control.custom-checkbox {
      position: relative;
      top: 74px;
    }

    button.text-center.gridButton.buttonHover.btn.btn-danger {
      position: relative;
      top: 34px;
      left: 43px;
    }

    div.input-group {
      width: 50%;
      float: right;
      margin-bottom: 10px;
    }
  }

  @media screen and (min-width: 1080px) and (max-width: 1800px) {
    .dangerButtonPositionResponsive {
      margin-top: 25px;
    }
  }

  @media screen and (min-width: 345px) and (max-width: 1235px) {
    div.custom-control.custom-checkbox {
      padding-left: 26px;
    }

    .dangerButtonPositionResponsive {
      margin-top: 25px;
    }
  }

  .AddClient {
    div.col-md-6 {
      padding-left: 0px;
      padding-right: 24px;

      label {
        margin: 0;
      }
    }

    button.btn.btn-secondary {
      background: $tertiary-color;
      color: $white;

      &:hover,
      &:active,
      &.active,
      &:focus,
      &:visited {
        background: $tertiary-color;
        color: $white;
        opacity: 0.8;
      }

      &:disabled {
        opacity: 0.4;
      }
    }
  }

  .internalViewStyle {
    margin-top: -2.3rem;
    margin-left: -3rem;
    margin-right: -3rem;
  }
}