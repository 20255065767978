.infoLabel {
    padding: 2px;
    font: inherit;
    font-size: inherit;
    &.primary {
      color: $bs-primary;
    }
    &.secondary {
      color: $bs-secondary;
    }
    &.tertiary {
      color: $bs-tertiary;
    }
    &.info {
      color: $bs-info;
    }
    &.success {
      color: $bs-success;
    }
    &.danger {
      color: $validationRed;
    }
    &.warning {
      color: $bs-warning;
    }
    &.light {
      color: $bs-light;
    }
    &.dark {
      color: $bs-dark;
    }
    &.default {
      color: $bs-default;
    }
    &.displayNone {
      display: none;
    }
    .textFontSize{
      font-size:13px;
    }
    .textFontSize20{
      font-size:20px;
    }
    .textFontSize15{
      font-size:15px;
    }
  }