//video component / pop out
.videoPopOut {
  &.batch-review-shift {
    top: 315px !important;
  }

  &.modal-dialog {
    height: 325px;
    position: absolute;
    left: -10px;
    top: 260px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    font-size: 18px;
    color:$primary-text-color;
    font-weight: 600;
  }
  &.app-right-panel {
    .modal-content {
      height: 325px;
      width: 463px;
      background: $yellow-spectre;
      color:$primary-text-color;
      font-weight: 600;
    }
    .backgroundColor.modal-body {
      background: $yellow-spectre;
      padding-top: 0px;
    }
    #triangle-left {
      width: 0;
      height: 0;
      border-top: 10px solid transparent;
      border-bottom: 10px solid transparent;
      border-right: 10px solid $yellow-spectre;
      position: relative;
      left: -10px;
      top: 15px;
    }
    .videoFontSize {
      font-size: 18px;
      padding: 10px;
    }
    button.close {
      margin-right: 0px;
      margin-top: -3px;
      position: relative;
      color: $black;
    }
  }

  @media (min-width: 769px) and (max-width: 1360px) {
    &.modal-dialog {
      height: 325px;
      position: absolute;
      left: 3px;
      top: 290px;
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
    }
  }

  .modalContentText {
    margin-bottom: 0px;
    padding-bottom: 0px;
    color: $primary-text-color;
  }
}
