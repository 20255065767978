.modal {
    .modal-header {
        padding: 7px;
        border-radius: 0px;
        border: none;
        text-align: center;
        display: initial;
        width: 99.9%;

        .close {
            padding: 0rem 0rem;
            margin: 0rem 0rem -1rem 0rem;
            position: relative;
            right: 8px;
            bottom: 27px;

            span {
                color: $primary-text-color;
            }
        }
    }

    .modal-title {
        margin-bottom: 0;
        color: $primary-text-color;
        font-size: 16px;
        font-weight: 400;
        text-align: center;
        margin-top: 2px;
        vertical-align: middle;
    }

    .modal-content {
        position: relative;
        display: flex;
        flex-direction: column;
        width: 100%;
        pointer-events: auto;
        background-color: $white;
        background-clip: padding-box;
        border: none;
        border-radius: 4px;
        outline: 0;
        color: $primary-text-color;
    }

    .modal-body {
        .neo-file-drop {
            padding: 40px;
        }
    }

    .modal-footer {
        button {
            margin: 0.5rem;
        }

        button.btn.btn-secondary {
            background: $tertiary-color;
            border-color: $tertiary-color;
            color: $white;

            &:hover {
                opacity: 0.8;
            }

            &:disabled {
                opacity: 0.4;
            }
        }
    }

    .custom-footer {
        button {
            margin: 0.5rem;
        }
    }
}

.hideModalFooter {
    .modal-footer {
        display: none;
    }

    .custom-footer {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: flex-end;
        border-top: 1px solid #dee2e6;
        border-bottom-right-radius: calc(0.3rem - 1px);
        border-bottom-left-radius: calc(0.3rem - 1px);

        button.modalButtonWidth {
            width: 123px !important;
            margin: 0px;
            padding: 0px;
        }
    }
}

.blacklistAndGreylistModal {
    &.modal-dialog {
        margin: 1.5rem auto;
        max-width: 760px;
    }

    .modal-header {
        padding: 40px 40px 24px 40px;

        .close {
            span {
                color: $svgDefaultColour;
            }

            font-size: 2rem;
            bottom: 0;
            right: 0;
        }
    }

    .modal-title {
        font-size: 20px;
        float: left;
    }

    .modal-body {
        display: inline-grid;
        align-items: flex-start;
        padding: 0px 40px 40px 40px;
        max-height: 704px;
    }

    .modal-content {
        width: 760px;
        height: 93vh;
        border-radius: 16px;
    }

    .modal-footer {
        display: none;
    }

    .modal-button {
        margin-bottom: -16px;
    }

    .quickAddParagraphBorder {
        border-bottom: solid 2px $bs-light;
    }

    .plusButton {
        border-radius: 8px;
        width: 48px;
        height: 48px;
    }

    form .form-control {
        border-radius: 8px;
        font-size: 16px;
    }

    button.btn.btn-light {
        padding: 0px;
        opacity: 1;
        background-color: $form-background-color;

        &:hover {
            cursor: pointer;
            opacity: 0.8;
        }

        &:active {
            background-color: $form-background-color;
        }

        svg {
            color: $onboardingMenuActive;
        }
    }

    button.quickAddModalButtonWidth {
        width: 90% !important;
        border-radius: 8px;
        background: $tertiary-color;
        border-color: $tertiary-color;
        margin-bottom: 20px;

        &.btn.btn-primary:active {
            background-color: $tertiary-color;
            border-color: $tertiary-color;
        }

        &.btn.btn-primary:focus {
            background-color: $tertiary-color;
            border-color: $tertiary-color;
            box-shadow: none;
        }

    }

    .errorMessage {
        color: $red;
        font-size: 16px;
    }

    .selectedItemsContainer {
        max-height: calc(400px);
        overflow-y: auto;
    }

    .modal-content {
        width: 760px;
        height: 800px;
        border-radius: 16px;
    }

    .modal-footer {
        display: none;
    }

    .modal-button {
        margin-bottom: -16px;
    }

    .footerContainer {
        position: absolute;
        bottom: 0;
    }
}