.gridChartSwitch {
    display: inline;

    .button1Shape {
        border-radius: 100px 0px 0px 100px;
    }

    .button2Shape {
        border-radius: 0px 100px 100px 0px;
    }

    button.unselectedButton {
        background: $form-background-color;
        border: none;

        &:focus, &:active {
            box-shadow: none !important;
        }
    }

    button.selectedButton {
        background: $selectedOrange;
        border: none;

        &:focus, &:active {
            box-shadow: none !important;
        }
    }

    .selectedIcon {
        color: $switchIconOrange;
        margin-bottom: 1px;
        padding-top: 2px;
    }

    .unselectedIcon {
        color: $switchIconGrey;
        margin-bottom: 1px;
        padding-top: 2px;
    }
}