@import "../../../Styles/Variables.scss";

@font-face {
  font-family: "Apple Color Emoji";
  src: url("../../../assets/font/5ab304ade0d4f62d027d12464ca69443.eot");
  /* IE9*/
  src: url("../../../assets/font/5ab304ade0d4f62d027d12464ca69443.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */
    url("../../../assets/font/5ab304ade0d4f62d027d12464ca69443.woff2") format("woff2"),
    /* chrome firefox */
    url("../../../assets/font/5ab304ade0d4f62d027d12464ca69443.woff") format("woff"),
    /* chrome firefox */
    url("../../../assets/font/5ab304ade0d4f62d027d12464ca69443.ttf") format("truetype"),
    /* chrome firefox opera Safari, Android, iOS 4.2+*/
    url("../../../assets/font/5ab304ade0d4f62d027d12464ca69443.svg#Apple Color Emoji") format("svg");
  /* iOS 4.1- */
}

$roboto: "Roboto", sans-serif;

//dashboard main version start
div.DashboardView,
div.DashboardViewMobileVersion {
  background-color: $form-background-color;
  padding: 24px 0;
  margin: -24px 0;

  .fa-cogs {
    color: $validation-color-info;
    font-size: 0.9vw;
  }

  .fa-check-square {
    color: $green;
    font-size: 0.9vw;
  }

  .fa-flag {
    color: $lightPurple;
    font-size: 0.9vw;
  }

  .fa-brain {
    color: $primary-color;
    font-size: 0.9vw;
  }

  .fa-envelope {
    color: rgb(209, 66, 66);
    font-size: 0.9vw;
  }

  .dashboardActionListCard {
    padding-bottom: 24px;

    input {
      height: 56px;
      background: $form-background-color;
      border-radius: 8px;
      align-items: center;
      font-style: normal;
      font-weight: 500;
      padding-right: 8px;
    }

    .dashboard-actionlist-item {
      background-color: $form-background-color;
      border-radius: 8px;
      font-style: normal;
      font-weight: 500;
      width: 100%;
      display: flex;
      height: 100%;
      align-items: center;
      flex-wrap: nowrap;
      padding-right: 8px;

      .actionlist-description {
        top: 100%;
        width: 260px;
      }

      .actionlist-cardblock {
        margin-right: 4px;
        display: flex;
        background-color: $inputEndIconColor;
        justify-content: end;
        height: 40px;
        width: 64px;
        border-radius: 4px;
        margin-left: auto;
      }

      .actionlist-card-value {
        margin: auto;
      }

      .actionlist-card-leadtype {
        padding-left: 16px;
      }
    }

    a.actionlist-nav-link {
      opacity: 1;
      color: $primary-text-color;
      text-decoration: none;

      &:hover {
        color: inherit;
        opacity: 0.8;
      }
    }

    // for endIcon prop in the input element
    .kqSSKW {
      margin-right: -12px;
    }

    .actionListButtonDiv {
      text-align: right;
    }

    .actionListbutton {
      width: 64px;
      border: none;
      height: 40px;
      background-color: $inputEndIconColor;
      font-weight: 500;
      border-radius: 4px;
      margin-top: 4px;

      &:hover {
        cursor: default;
      }
    }

    .goToActionListButton {
      align-items: center;
      width: 188px;
      height: 40px;
      padding: 8px 16px;
      border-radius: 8px;
      font-weight: normal;

      &:hover {
        opacity: 0.8;
        background: $primary-color;
      }

      .btnText {
        margin-top: -2px;
      }
    }

    .headerText {
      font-size: 32px;
      font-family: $roboto;
      font-weight: 500;
      vertical-align: top;

      span {
        color: $secondary-text-color;
      }
    }
  }

  .dashboardLoadingText {
    position: relative;
    bottom: 6px;
  }

  .positiveGreen {
    color: $positiveGreen;
  }

  .colorSecondary {
    color: $primary-text-color;
  }

  div.neo-card.card .card-header {
    padding: 10px 10px;
    color: $card-header-color;
  }

  div.neo-card.card .card-body {
    padding-top: 5px;
    padding-bottom: 0px;
    display: flex !important;
    flex-direction: column !important;
    color: $primary-text-color;
  }

  .moveSwitchRight {
    text-align: right;
  }

  input.input-group::placeholder {
    font-size: 16px;
  }

  .replyColor {
    color: $dash-board-color;
  }

  .fa-clipboard-check {
    color: turquoise;
    font-size: 0.9vw;
  }

  // New Dashboard stuff Start
  .reportingFormGroups {
    .form-group {
      float: right;
    }
  }

  .form-group {
    .neo-datepicker-container {
      z-index: 3;

      input.form-control {
        width: 12vw;
        float: right;
        height: 40px;
      }
    }

    select {
      height: 40px;
    }
  }

  #positiveHeader {
    font-size: 11px;
  }

  #neutralHeader {
    font-size: 11px;
  }

  #replyHeader {
    font-size: 11px;
  }

  .running-campaign-counter {
    margin: 0px 0px 32px -0.05vw;
    font-family: $roboto-font;
    font-size: 32px;
    font-weight: 500;
    color: $primary-text-color;

    .areRunning {
      color: $secondary-text-color
    }
  }

  .noCampaignsBox {
    border: solid 2px $dashboard-row-segregator;
    font-size: 0.8vw;
    margin: 40px 10px;
    padding: 50px;
    color: $primary-text-color;

    .primaryNoCampaignText {
      font-weight: 600;
      font-size: 18px;
    }

    .noCampaignsIcon {
      font-size: 1.1vw;
      color: $bs-default;
    }

    .failedCampaignRetrieval {
      width: 25px;
      height: 33px;
      font-family: "AppleColorEmoji";
      font-size: 32px;
      color: $bs-light;
    }
  }

  .campaign-item {
    margin: 0 0 4px;
    font-size: 18px;
    color: $primary-text-color;
    font-weight: bold;
  }

  .csTeamVideoArrowDown {
    padding: 4px 0px 0px 0px;
    float: right;
    border-radius: 50%;
    width: 32px;
    height: 32px;
    color: $dashboard-expand-indicator;
    border: none;
    background-color: $yellow-spectre-32;

    svg {
      margin-top: 2px;
    }
  }

  .csTeamVideoArrowUp {
    padding: 0;
    float: right;
    border-radius: 50%;
    width: 32px;
    height: 32px;
    color: $dashboard-expand-indicator;
    border: none;
    background-color: $yellow-spectre-32;

    svg {
      margin-top: 2px;
    }
  }

  .dashboardTableContainer {
    .row {
      margin: 0px;
    }

    .dashboardRowContainer {
      border-bottom: solid 1px $dashboard-row-segregator;

      button.campaignEmailOverview {
        &.btn.btn-light {
          height: 32px;
          width: 56px;
          padding: 4px 16px 4px 16px;
        }
      }

      .campaignsTableHeader {
        font-size: 11px;
        font-weight: 500;
        color: $secondary-text-color;
        padding-top: 7px;
        padding-bottom: 0px;
      }

      #nameHeader>span>button>i {
        cursor: default !important;

        :hover {
          cursor: default !important;
        }
      }

      .campaignDataStatusContainer {
        width: 100%;
        height: auto;
        padding: 3px 16px 3px 16px;
        border-radius: 20px;
        background-color: $innerCardBackground;
      }

      .rowFontBig {
        font-size: 22px;
        vertical-align: middle;
        font-weight: bold;
      }

      .generalPercentage {
        font-size: 13px;
        color: $refer-us-color;
        letter-spacing: normal;
        width: 100%;
      }

      /// these are on a depreciated screen ---------------------------------------------------------------------------------------------
      .generalStat {
        width: auto;
        height: 19px;
        margin: 4px 0 5px;
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: $mediumGrey;
        padding-left: 8px;
      }

      .generalStat .textStyle1 {
        color: #343332;
      }

      .darkText {
        color: $primary-text-color;
        font-size: 16px;
        font-weight: bold;
      }

      .mediumGrey {
        color: $mediumGrey;
        font-size: 16px;
        font-family: $lato;
      }

      .statBadge_TooltipSeen {
        width: auto;
        height: auto;
        padding: 1px 0px 2px 6px;
        border-radius: 10px;
        margin-top: 10px;

        &:hover {
          background-color: $stat-hover-border;
          cursor: pointer;
        }
      }

      .statBadge_TooltipUnseen {
        width: auto;
        height: auto;
        padding: 1px 0px 2px 6px;
        border-radius: 10px;
        margin-top: 10px;

        &:hover {
          cursor: pointer;
        }

        &:active {
          border: solid 1px #758d9c;
          background-color: #f0f3f5;
        }
      }

      .oval {
        padding: 2px;
        border-radius: 50px;

        &:hover {
          opacity: 0.8;
        }
      }

      * {
        box-sizing: border-box;
      }

      .score {
        margin: auto;
        text-align: center;

        .scoreRectangle {
          height: 40px;
          width: 62px;
          margin: auto;
          border-radius: 20px;
          background-color: $innerCardBackground;
          padding-top: 3px;
        }

        &:hover {
          cursor: pointer;
        }
      }

      /// -------------------------------------------------------------------------------------------------------

      .neutral-color {
        color: $dashboard-neutrality;
      }

      .icon {
        width: 25px;
        height: 33px;
        font-family: "AppleColorEmoji";
        font-size: 20px;
        color: $bs-default;
      }

      .rowFontBig {
        font-size: 22px;
        font-weight: bold;
        margin: auto 0;
      }
    }
  }

  body {
    margin: 100px;
  }

  * {
    box-sizing: border-box;
  }

  .hrecommendedBody {
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    color: $primary-text-color;
    margin-top: 0.4vw;
    min-width: 280px;
  }

  .highlyRecommendedCS {
    border-radius: 50%;
    padding: 2px;
    background-color: $blue-spectre;
    color: $white;
    bottom: 2px;
    position: relative;
    font-size: 0.45vw;
  }

  .csTeamVideoSection {
    background-color: $yellow-spectre;
    margin: 12px;
  }

  .csTeamVideoSectionSmall {
    background-color: $yellow-spectre;
    margin: 12px;
  }

  ul {
    padding-left: 18px;
    margin-bottom: 0px;
  }

  li {
    padding-bottom: 10px;
  }

  .notEnoughChartDataWords {
    position: absolute;
    text-align: center;
    z-index: 2;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    user-select: none;
    display: flex;
    align-items: center;

    .form-group {
      padding-left: 24px;
      width: 500px;
      pointer-events: none;
      margin: auto;

      .form-control {
        text-align: center;
        font-size: 18px;
        font-weight: bold;
        color: $mediumGrey;
      }
    }
  }

  .notEnoughPieChart {
    margin: 80px;

    .form-control {
      text-align: center;
    }
  }

  .notEnoughChartData {
    opacity: 0.6;

    .selectedIcon {
      color: $switchIconGrey;
    }

    .selectedButton {
      background-color: $secondary-text-color;
    }
  }

  .highcharts-markers {
    .highcharts-point {
      opacity: 0;
    }
  }

  .columnChartStyling {
    .hideTooltip {
      opacity: 0;
    }

    .hoverEffect {
      .highcharts-series-group {
        .highcharts-series {
          .highcharts-point {
            &:not(.highcharts-point-hover) {
              opacity: 0.1;
            }
          }
        }
      }
    }

    .highcharts-exporting-group {
      .highcharts-button {
        rect.highcharts-button-box {
          width: 34px;
        }
      }
    }

    .noHoverEffect {
      .highcharts-series {
        .highcharts-point {
          &:not(.highcharts-point-hover) {
            opacity: 1;
          }
        }
      }
    }

    .highcharts-crosshair {
      opacity: 0;
    }

    .highcharts-data-label {
      &:not(.highcharts-point-hover) {
        opacity: 0.5;
      }
    }
  }

  .videoRadius {
    div {
      border-radius: 10px;
    }
  }

  .runStatus {
    color: $positiveGreen;
  }

  .pausedStatus {
    color: $pausedRed;
  }

  .draftStatus {
    color: $mediumGrey;
  }

  .statusFontSize {
    font-size: 11px;
  }

  .refresh {
    color: $switchIconGrey;
    font-weight: 500;
    font-size: 14px;
    line-height: 140%;

    button.btn.btn-light {
      font-weight: 500;
      height: 32px;
      width: 109px;
      padding-top: 3px;
      padding-left: 0;
      padding-right: 0;

      svg {
        height: auto;
        width: auto;
      }
    }
  }

  .refreshingButton {
    div.spinner-border {
      width: 20px;
      height: 20px;
    }

  }

  .white {
    color: #273845;
    border-left: none !important;
    background-color: $form-background-color !important;
    border-left: none !important;
    border-right: 1px solid $form-background-color !important;
    border-top: 1px solid $form-background-color !important;
    border-bottom: 1px solid $form-background-color !important;
  }

  .appendedIcon {
    color: $secondary-text-color;
  }

  .pointerHover {
    &:hover {
      cursor: pointer;
      color: $darkSilver;
    }
  }

  i.columnHeaderIcon {
    color: $secondary-text-color;
    opacity: 0.8;

    &:hover {
      color: $black;
      opacity: 1;
    }

    svg {
      width: 24px;
      height: 18px;
      margin-top: 2px;
    }
  }

  .smallVideoSize {
    height: 6.875rem;
    width: 12.5rem;
  }

  .highlyRedirect svg {
    height: 1.2vw;
    width: 0.8vw;
  }

  .neo-card {
    border-radius: 16px;

    .card-body {
      padding: 0;
    }
  }

  .onChart {
    .filterOnChart {
      margin-top: 2px;
      margin-bottom: -2px;
      z-index: 1;
    }

    .gridChartSwitch {
      margin-bottom: -36px;
      display: flex;
      z-index: 1;
      position: absolute;
    }

    g.highcharts-legend.legendStyling {
      z-index: 0;
    }
  }

  .form-group {
    .form-control {
      border-radius: 100px;
    }
  }
}

//mobile version styling end
//mobile show hide
@media (min-width: 325px) and (max-width: 575px) {
  .dashboardTableContainer {
    .dashboardRowContainer {
      .dashboard-grid-layout.mobile {
        .campaign-item {
          font-size: 16px;
        }

        .statusFontSize {
          font-size: 11px;
        }

        .rowFontBig {
          font-size: 16px;

          .generalPercentage {
            font-size: 11px;
          }
        }
      }
    }
  }

  .dashboardEmailRowContainer {
    .mobile.dataRow {
      font-size: 16px;
    }

    .mobile.dataRow {
      span {
        font-size: 11px;
      }
    }
  }
}

@media (min-width: 576px) and (max-width: 845px) {}

@media (min-width: 846px) and (max-width: 1361px) {}

@media (min-width: 1362px) {}

//mobile show hide
// New Dashboard stuff end

//small mobile version start
@media (min-width: 325px) and (max-width: 575px) {}

//small mobile version end

//larger mobile start
@media (min-width: 576px) and (max-width: 845px) {}

@media (min-width: 846px) and (max-width: 1299px) {}

//larger mobile end
//15 inch smaller laptop screens start

@media (min-width: 1300px) and (max-width: 1690px) {}

@media (min-width: 1361px) and (max-width: 1444px) {}

//15 inch smaller laptop screens end

div.TargetMarketViewClient {
  .highlightedText {
    color: $secondary-color;
  }
}

.modalContentText {
  padding-bottom: 0.75rem;
  color: $primary-text-color;
}

.customMarginBottom {
  margin-bottom: 2px;
}

a {
  color: $darkSilver;
  cursor: pointer;
}

a:hover {
  color: $darkSilver;
  opacity: 0.8;
}

.dashboardEmailRowContainer {
  margin: auto;
  color: $primary-text-color;
  background-color: $form-background-color;
  height: calc(100vh - #{50px});

  .DashboardView {
    background-color: #fff;
    margin: 0;
  }

  div.neo-card {
    .card-body {
      padding: 16px 0px;
    }
  }

  span {
    font-size: 13px;
    color: $refer-us-color;
    width: 100%;
  }

  .backgroundMail {
    background-color: $bs-light;
    border-radius: 25%;
    height: 40px;
    width: 40px;
    display: flex;

    svg {
      margin-bottom: 0;
    }
  }

  .dataRow {
    font-size: 23px;
    font-weight: bold;
  }

  .neutral-color {
    color: $dashboard-neutrality;
  }

  .positive-color {
    color: $positiveGreen;
  }

  .bottomBorder {
    border: none;
    border-bottom: 1px solid $light-grey-background;
  }

  .emailsTableHeader {
    font-size: 11px;
    font-weight: 500;
    color: $secondary-text-color;
    border-bottom: 1px solid $light-grey-background;
  }

  .header {
    font-size: 28px;
    font-weight: bold;
  }

  .emails {
    font-size: 20px;
    font-weight: bold;
    margin-top: 5px;
  }
}

.dashboard-grid-layout {
  display: grid;

  &.non-mobile {
    grid-template-areas:
      'header content content content content content content';
    grid-template-columns: 1fr 0.2fr 0.2fr 0.2fr 0.2fr 0.2fr 0.2fr;
    gap: 16px;
    padding: 24px;
  }

  &.mobile {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    gap: 10px;
    padding: 16px;
  }

  &.mobile .header-item {
    grid-column: 1 / -1;
    grid-row: 1;
  }
}

.overview-grid-layout {
  display: grid;

  &.non-mobile {
    grid-template-areas:
      'header content content content content';
    grid-template-columns: 1fr 0.4fr 0.2fr 0.2fr 0.2fr;
    gap: 16px;
    padding: 24px;
  }

  &.mobile {
    gap: 10px;
    // grid-template-columns: 0.4fr 0.2fr 0.2fr 0.2fr;

    .header-item {
      grid-column: span 4;
    }

    padding: 16px;
  }
}

.dashboard-card-image {
  height: 260px;
  border-radius: 8px;
}