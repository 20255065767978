.MeetingView {
    div.neo-card.card {
        width: 100%;
        margin-top: 0px;
    }

    p {
        color: $primary-text-color;
    }

    h2 {
        margin-bottom: 0px;
    }

    h3 {
        font-size: 24px;
        padding-bottom: 8px;
        padding-top: 20px;
    }

    .icon {
        height: 32px;
        width: 32px;
        background-color: $bs-light;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 8px;
        margin-right: 10px;

        svg {
            margin: 0px;
        }
    }

    .innerCard {
        box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1) !important;
        padding: 12px 32px 20px 35px;
        margin-left: 0px !important;
        margin-top: 32px !important;
    }

    .rowAlign {
        display: flex;
        align-items: center;
    }

    .reschedule {
        float: right;
        justify-content: right;
        color: $switchIconGrey;
        display: inherit;
        align-items: center;

        .hover {
            cursor: pointer;
        }
    }

    .innerCardHeader {
        .col-10 {
            padding-left: 0px;
        }
    }

    .headerText {
        font-size: 20px;
        font-weight: bold;
    }

    .spinnerDiv {
        line-height: 800px;
    }
}