.ActionListTab {

    // Styling applied to all the tabs
    button {
        border-radius: 16px;
        height: 72px;
        background-color: $white;
        font-weight: 600;
        border: 1.5px solid;
        padding: 16px;
        font-size: 1.2em;

        svg {
            margin-top: 8px;
            margin-left: 8px;
        }

        &:hover {
            color: $tertiary-color;
            border-color: $tertiary-color;
            transition: all 0.2s ease-in-out;

            .tabIcon {
                box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
                background: $tertiary-color;

                svg {
                    color: $white;
                }
            }
        }
    }

    // Styling applied to the tab is selected tab
    .selected {
        color: $tertiary-color;
        border-color: $tertiary-color;
        .tabIconSelected {
            box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
        }
    }

    // Styling applied to unselected tabs
    .unselected {
        color: $switchIconGrey;
        border-color: $bs-light;
    }

    // Styling applied to the non Archived tabs
    .activeLeads {
        width: 100%;
        text-align: left;
    }

    // Styling applied to the Archived tab
    .archivedLeads {
        grid-column: span 1 / auto;
        width: 100%;

        svg {
            margin-left: 0px;
        }
    }

    // Styling applied to the unseen count
    .unseenCount {
        position: absolute;
        top: -8px;
        right: -9px;
        background-color: $validation-shadow-error-color;
        color: white;
        border-radius: 50%;
        font-size: 11px;
        min-width: 18px;
        height: 18px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    // Styling applied to the unseen count when > 99
    .unseenCount.plus {
        min-width: 23px;
    }

    // Styling applied to the unseen count notification when all items have been seen (unseenCount < 1)
    .allSeen {
        position: absolute;
        top: -8px;
        right: -9px;
        background-color: $bs-success;
        color: white;
        border-radius: 50%;
        font-size: 11px;
        width: 18px;
        height: 18px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-top: 3px;

        svg {
            margin-left: 0px;
            margin-bottom: 10px;
            padding: 3px;
            stroke-width: 4px;
        }
    }

    // Styling applied to the span which houses unseen count
    .tabIcon {
        position: relative;
        margin-right: 18px;
        background-color: $bs-light;
        border-radius: 8px;
        display: inherit;
        height: 40px;
        width: 40px;
    }

    // Styling applied to the span which houses unseen count when the tab is selected
    .tabIconSelected {
        color: $white;
        background-color: $tertiary-color;
        position: relative;
        margin-right: 18px;
        border-radius: 8px;
        display: inherit;
        height: 40px;
        width: 40px;
    }

    // Styling applied to the tab name
    .tabName {
        position: relative;
        top: 3px;
    }
}