.GreyListedProspect {
  .buttonWidthBlacklist {
    min-width: 105px;
    width: auto;
  }
  .customCheckPadding {
    padding-left: 8px;
  }
  .tab-pane {
    padding: 5px 0px 5px 0px;
  }
  @media (min-width: 375px) and (max-width: 980px) {
    .customCheckPadding {
      padding-left: 0px;
    }
  }
  @media (min-width: 375px) and (max-width: 580px) {
    //overridding client view
    div.custom-control.custom-checkbox {
      position: relative;
      top: 0px !important;
    }
    .checkPositionResponsive {
      position: relative;
      top: 36px !important;
      left: 8px;
    }
  }
  @media (min-width: 375px) and (max-width: 980px) {
    .customCheckPadding {
      padding-left: 0px;
    }
  }
  @media (min-width: 1680px) and (max-width: 2000px) {
    .customCheckPadding {
      padding-left: 18px;
    }
    .dangerButtonPositionResponsive {
      margin-top: 23px;
    }
  }
  
  .InovuaReactDataGrid__column-header__content.InovuaReactDataGrid__box--ellipsis:last-child{
    text-align: center;
  }
}
