.listSelectorSelectedItems {
    .itemHeaderContainer {
        border-bottom: 1px solid $bs-light;
    }

    .addButton {
        border: none;
        width: 200px;
        height: 56px;
        border-radius: 8px;
        background-color: $onboardingMenuActive;
        color: $white;
        margin-left: 50px;
        padding-left: 0px;

        &.rolesAndFunctions {
            width: 250px;
        }

        &:hover {
            opacity: 0.8;
        }

    }

    .selectedListContainer {
        max-height: 100%;
        max-width: 100%;
    }

    .itemName {
        font-size: 16px;
    }

    .selectedSubHeading {
        font-size: 14px;
        color: $mediumGrey;
    }

    .buttonContainer {
        border-radius: 8px;
        width: 40px !important; // had to make these important, as there is a card wide style applied to SVGs
        height: 40px !important;
        background-color: $white;

        &:hover {
            cursor: pointer;
            opacity: 0.8;
        }
    }

    svg {
        &.buttonContainer {
            padding: 7px;
        }

        &.trash {
            padding: 9px;
            margin-right: 3px;
        }
    }

    .font12 {
        font-size: 12px;
    }

    .priority {
        margin-top: 4px;
        color: $switchIconGrey;
    }

    .functionSVG {
        color: $switchIconGrey;
        ;
    }

    svg {
        &.priorityAllSvg {
            padding: 0px;
            color: $switchIconGrey;
        }

        &:hover {
            cursor: pointer;
        }
    }

    .subItemSelected {
        font-size: 14px;

        svg {
            margin-bottom: 1px;
        }

        .cursorDefault {
            cursor: default;
        }
    }

    div.neo-card.card {
        margin: 0px;
    }

    .cardBackgroundGrey {
        background-color: $form-background-color;

        .card-body {
            max-width: 100%;
        }
    }

    div.custom-control.custom-switch {
        label.custom-control-label::before {
            background: $table-switch-color;
            color: $white;
        }

        label.custom-control-label::after {
            background: $white;
        }

        .custom-control-input:checked~.custom-control-label::before {
            color: $white;
            border-color: $switchIconOrange;
            background: $switchIconOrange;
            outline: none;
        }
    }

    .listSelectorRolesAndFunctionsContainer {
        border-radius: 8px;
        height: 87px;
        width: 100%;
        background-color: $bs-tertiary;

        &.warning {
            height: 50px;
            text-align: center;
            background-color: $onBoardingCampaignMessageBorder;
        }
    }
}