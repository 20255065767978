.clientNavigation {
  button.singleIconBtn {
    &.btn.btn-outline-secondary {
      color: $tertiary-color;

      &:hover,
      &:active,
      &.active,
      &:focus,
      &:visited {
        opacity: 0.8;
        color: inherit;
        background: $form-background-color;
        box-shadow: none;
      }

      .fa.fa-cogs {
        color: $tertiary-color;
      }
    }
  }
}