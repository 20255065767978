.InfoVideo{
  .highlyRedirect {
    color: $primary-text-color;
    text-decoration: underline;
    font-weight: bold;
    font-size: 13px;
    padding-left: 0px;
    margin-bottom: 0.85vw;

    &:hover {
        text-decoration: none;
        cursor: pointer;
    } 
  }

  .highlyRecommendedCS {
    border-radius: 50%;
    padding: 0.1vw;
    background-color: $blue-spectre;
    color: $white;
    bottom: 0.1vw;
    position: relative;
    font-size: 10px;
  }

  .hRecommendedBody {
    font-size: 11px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    color: $primary-text-color;
  }

  .informationBox {
    background-color: $informationBox;
    margin-left: 0px;
    margin-right: 0px;
  }

  .externalLinkPadding {
    margin-right: 5px;
    font-size: 13px;
  }

  .playMargin {
    margin-bottom: 3px;
    margin-right: 5px;
  }

  p{
    color: $primary-text-color;
    font-size: 13px;
  }

  li{
    color: $primary-text-color;
    font-size: 13px;
  }

  h5{
    color: $primary-text-color;
    font-size: 16px;
    margin-bottom: 0px;
    padding-top: 5px;
  }

  ul{
    padding-left: 12px;
  }

  h6{
    font-family: "AppleColorEmoji";
    font-size: 24px;
  }
}