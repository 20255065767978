.customerProfileQuestionnaireView {
  background-color: $form-background-color;

  .capitalize {
    position: relative;
    box-sizing: border-box;
    text-transform: capitalize;
  }

  .questionnaireTooltipListPadding {
    padding-left: 15px;
  }

  .noWrap {
    span:last-child.rc-slider-mark-text {
      white-space: nowrap;
      padding-right: 22px;
    }
  }

  textarea {
    resize: vertical;
    overflow-y: auto;
  }

  ::placeholder {
    color: $secondary-text-color;
    font-size: 16px;
  }

  .questionnaireTooltip {
    display: inline-block;
    color: $secondary-text-color;
    margin-left: 8px;

    &:hover {
      color: $secondary-color;
    }
  }

  p {
    font-size: 16px;
  }

  .infoVideoStyle {
    p {
      font-family: "Lato-Regular";
      font-style: normal;
    }
  }

  .questionnaireTooltipText {
    font-family: Lato;
    font-size: 16px;
    font-style: normal;
    color: $primary-text-color;
  }

  .backICPButton.btn {
    width: 105px;
    height: 40px;
    margin: 24px 51px 0 9px;
    padding: 0 8px 0 8px;
    border-color: $bs-light;
    border-radius: 20px;
    background-color: $bs-light;
  }

  .titleAddICPText {
    color: $primary-text-color;
    font-family: Lato;
    font-size: 32px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    margin-bottom: 30px;
  }

  .questionnaireTooltipWide {
    display: inline-block;
    color: black;
    margin-left: -5px;

    .hoverContainer {
      width: 400px !important;
    }
  }

  .videoTutorialContainer {
    margin: 0 600px 0 0;
    background-color: $informationBox;
  }

  .questionsText {
    display: inline-block;
  }

  .dragBar {
    background-color: $bs-light;
    color: $bs-light;
  }

  .dragBarSubQuestion {
    font-family: Lato;
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38;
    letter-spacing: normal;
    color: $mediumGrey;
  }

  .questionStyle {
    color: $primary-text-color;
    font-size: 20px;
    font-family: Lato-Bold;
    font-weight: bold;
  }

  .subQuestionStyle {
    color: $mediumGrey;
    margin: 0 0 10px;
    font-family: Lato;
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38;
    letter-spacing: normal;

    p {
      font-size: 13px;
      font-family: Lato;
    }
  }

  .textboxICP {
    margin: 0;
    border-radius: 1px;
    font-size: 16px;
    border-radius: 8px;
    border-color: $form-background-color;
    background-color: $form-background-color;
  }

  .ICPNumberStyle {
    width: 38px;
    height: 77px;
    margin: 0 12px 0 0;
    font-family: Lato;
    font-size: 64px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: $secondary-text-color;
  }

  .numeric-input {
    text-align: left;
  }

  .needHelp {
    width: 80px;
    height: 26px;
    margin: 44px 0 7px 12px;
    font-family: Lato;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.63;
    letter-spacing: normal;
    color: $secondary-text-color;
  }

  .checkboxICP {
    border-radius: 1px;
    border: solid 1px;
    background-color: $secondary-text-color;
  }

  .questionTextBox {
    border-radius: 8px;

    input.form-control {
      border-radius: 8px;
    }
  }

  p {
    margin-bottom: 0px;
    font-family: Lato-Bold;
  }

  .idealCustomerProfileCS {
    border-radius: 50%;
    padding: 2px;
    background-color: $blue-spectre;
    color: $white;
    bottom: 2px;
    position: relative;
    font-size: 9px;
  }

  .form-control.numeric-input {
    width: 20vw;
    height: 48px;
    margin: 16px 0 0;
    border-radius: 1px;
    background-color: $form-background-color;
  }

  .form-group {
    bottom: 20px;
  }

  .css-1wa3eu0-placeholder {
    color: $secondary-text-color;
    margin-left: 0px;
  }

  .css-1n7v3ny-option {
    background-color: $bs-light;
  }

  .css-1rhbuit-multiValue {
    border-radius: 4px;
    font-weight: normal;
    padding: 0 2px 0 2px;
    font-weight: 400;
    color: $sidemenu-inactive-text;

    .css-xb97g8 {
      svg {
        color: $sidemenu-inactive-text;
      }
    }
  }

  .css-12jo7m5 {
    padding: 8px;
  }

  .css-1okebmr-indicatorSeparator {
    background-color: transparent;
  }


  // ICP dropdown, now dropping up
  // Wildcard attribute needed for the Dropdown for deployed environments not live
  // .css-11unzgr is the Live value
  [class$=-menu-list],
  .css-11unzgr {
    width: 100%;
    background-color: $form-background-color;
    border-radius: 8px;
  }

  // Currently Just ICP, may need be changed globally
  ::-webkit-scrollbar {
    width: 4px;
    height: 8px;
    background: $tabBorder;
  }

  // Going to be global font eventually

  .ICPBody {
    padding-bottom: 96px;
  }

  div.neo-card.card {
    .card-body {
      padding: 24px;
    }
  }

  .headerText {
    h6 {
      display: block;
      font-size: 16px;
      font-family: 'Lato-Regular';
      font-weight: 400;
      color: $sidemenu-inactive-text;
      margin-bottom: 16px;
    }

    h5 {
      font-size: 20px;
      font-weight: bold;
      font-family: 'Lato-Regular';
      color: $sidemenu-inactive-text;
      margin-bottom: 16px;
    }
  }

  // Wildcard attribute needed for the Dropdown for deployed environments not live
  // .css-11unzgr is the Live value
  [class$=-menu-list],
  .css-11unzgr {
    width: 100%;
    max-height: 400px;
    background-color: $form-background-color;
    border-radius: 8px;
    margin-top: 0px;

    [class$=-options] {
      margin: 4px 0px;
    }
  }

  .subHeading {
    color: $subHeaderGrey;
    font-weight: bold;

    p {
      font-size: 14px;
      font-family: 'Lato-Regular';
    }

    line-height: 140%;

    textarea.textboxICP {
      min-height: 88px;
      height: 88px;
    }

    span {
      color: $tertiary-color;
      font-size: 14px;
    }

    // ICP dropdown, now dropping up
    // Wildcard attribute needed for the Dropdown for deployed environments not live
    // .css-11unzgr is the Live value
    [class$=-menu-list],
    .css-11unzgr {
      position: absolute;
      max-height: 500px;
      bottom: 60px;
    }
  }

  .css-1wa3eu0-placeholder {
    font-size: 14px;
    font-weight: 500;
  }

  textarea.textboxICP {
    min-height: 200px;
    max-height: 600px;
  }

  .css-tlfecz-indicatorContainer {
    svg {
      color: $primary-text-color;
    }
  }

  .form-control {
    color: $sidemenu-inactive-text;
  }

  label.custom-control-label {
    padding: 5px;
    margin: 4px 0px;

    &::before {
      width: 24px;
      height: 24px;
    }

    &::after {
      width: 24px;
      height: 24px;
    }
  }

  h6 {
    display: none;
  }

  .sliderComment {
    padding-top: 75px;
  }

  .lessThanDropbox {
    .downloadExerciseTemplates {
      color: $switchIconOrange;

      svg {
        margin: 0px 8px 3px 8px;
      }
    }

    .uploadExerciseTemplates {
      svg {
        height: 20px;
        width: 20px;
        margin: 0px 8px 3px 8px;
      }
    }

    .neo-file-drop {
      border: 2px dashed $tabBorder;
      background-color: $form-background-color;
      border-radius: 8px;
      justify-content: center;
      align-items: center;

      .btn.btn-light {
        color: $tertiary-color;
        background-color: $form-background-color;

        &:active,
        &:focus,
        &:focus-visible {
          background-color: $form-background-color;
          background: none;
          box-shadow: none;
        }

        &:hover {
          text-decoration: underline;
          opacity: 0.8;
        }
      }
    }
  }

  .clickable {
    cursor: pointer;
    user-select: none;

    &:hover {
      opacity: 0.8
    }
  }
}