.rc-slider.rc-slider-with-marks {
    .rc-slider-track {
        background-color: $tertiary-color;
    }

    .rc-slider-dot {
        border-color: $white;
        background-color: $tabBorder;
        padding: 4px;
        margin-bottom: -2px;
    }

    .rc-slider-dot-active {
        border-color: $white;
        background-color: $tertiary-color;
        padding: 4px;
        margin-bottom: -2px;
    }

    .rc-slider-handle {
        background-color: $tertiary-color;
        border-radius: 4px;
        margin-top: -6px;
        width: 17px;
        height: 17px;
        border: none;
    }
}