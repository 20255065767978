.TargetMarketAccountsView {
  div.custom-control.custom-switch input.custom-control-input:checked .custom-control-label::before {
    color: #ffffff;
    border-color: $bs-danger;
    background: $bs-danger -webkit-gradient(linear,
        left top,
        left bottom,
        from($bs-danger),
        to($bs-danger)) repeat-x;
    background: $bs-danger linear-gradient(180deg, $bs-danger, $bs-danger) repeat-x;
  }

  .custom-control-label::before {
    left: -1.3rem;
  }

  .custom-control-label::after {
    left: -1.3rem;
  }

  // overriding Theme
  .card-body {
    padding-top: 0px !important;
  }

  .marginTop18 {
    margin-top: 18px;
  }

  .dateProspectedWidth {
    width: 130px;
  }

  .cleanCompanyName {
    width: 290px;
  }

  td {
    button {
      &.gridButton {
        min-width: 40px;
        width: auto;
      }
    }
  }

  .gridButton {
    min-width: 40px;
    width: auto;
  }

  .hideLabel {
    .custom-control {
      label {
        display: none;
      }
    }
  }

  table.table>thead th {
    font-size: 11px;
  }

  table.table td {
    font-size: 11.5px;
  }

  table th.column-is-sorted.column-sortable {
    padding-right: 22px !important;
  }

  .unblacklistButton {
    background: $bs-light;
    color: #00000091;
  }

  .unblacklistButton:hover {
    background: white;
    color: black;
    border-color: black;
  }

  .bulkUnblacklistButton {
    background: white;
    color: #00000091;
    border-color: #00000091;
  }
}

.errorMessage {
  font-size: 12px !important;
  margin-bottom: 2px;
}

.collapsibleCard {
  .neo-card {
    .card-body {
      padding-bottom: 0px !important;
    }
  }
}

.tab-pane {
  padding: 0px 15px 15px 15px;
}

div.tabs-header {
  z-index: 0;
}

button.circled.btn.btn-light:disabled:hover {
  span.hoverText {
    display: none;
  }
}

button.circled.btn.btn-danger:disabled:hover {
  span.hoverText {
    display: none;
  }
}

@media (max-width: 768px) and (min-width: 375px) {
  .app-breadcrumb {
    position: relative;
    left: 34px;
    bottom: 51px;
    font-size: 9px !important;
  }
}


@import './TargetMarketDataGrid.scss';