.AddClientListView{
  //bootstrap overrides for this page only

  .form-group{
    input{padding-left: 0px}
  }
  input.form-control{
    width: 160%!important;
  }
  select.form-control{
    width: 160%!important;
  }
  div.neo-card.card .card-body {
    padding-top: 5px;
}
//bootstrap overrides for this page only
}
@media (max-width: 768px) and (min-width: 375px){
  label.col-4.col-form-label{
    flex:none!important;
    max-width:100%!important;
  }
}

#TMNameButtonAlign {
  vertical-align:bottom;
  // padding: 8px 0px 0px 0px
}