.OnboardingView {

  display: grid;

  .CMComingOverview {

    .bodyContainer-border {
      border-top: 1px solid $form-background-color;
    }

    .bodyContainer {

      background-color: $form-background-color;
      font-size: 18px;
      padding: 40px;
      border-radius: 8px;

      p {
        font-size: 18px;
        margin-bottom: 0px;
      }
    }

    h2 {
      font-size: 24px;
    }
  }

  .noCampaignGridMargin {
    
    .neo-card {
      min-height: 340px;
    }

    .CMComingOverview {
      min-height: unset;
    }

    .ClientCampaignsView  {
      .campaignGridMargin {
        margin-bottom: 0px;
      }
    }
  }

  .sendEmailSpecsButton {
    background-color: inherit;
    height: 48px;
    border: 2px solid #002648;
    border-radius: 8px;
    padding: 16px 24px;
    display: flex;
    align-items: center;

    border-radius: 8px;
    flex: none;
    order: 2;
    align-self: stretch;
    flex-grow: 0;
    color: #002648;
    &:hover {
      color: #002648;
      text-decoration: none;
      opacity: 0.8;
    }
  }
}