.InvitedUsersView {
  .invitedUsers{
    .neo-tab-container{
      .col-12{
        padding-left: 24px;
        padding-right: 24px;
      }
    }
  }
  .addUserGroup{
    svg{
      margin-bottom: 0px;
    }
  }
  .nav.nav-tabs {
    padding-right: 24px !important;
  }
  .col-2 {
    padding-left: 24px;
    h2 {
      padding-left: 0px !important;
    }
  }

  .tab-content {
    .tab-pane{
      h2 {
        font-size: 20px;
        padding:0px!important;
      }
      h4 {
        font-size: 20px;
      }
    }
    
  }

 

  .fontSize {
    font-size: 20px;
  }
  .tabs-header.col-12 {
    .col-12 {
      padding: 0px !important;
    }
  }

  .table.neo-grid {
    button.btn {
      max-height: 32px;
      height: 32px;
      &.btn-danger {
        min-width: 32px;
        width: 32px;
        height: 32px;
        padding: 5px 6px 9px 5px;
        svg {
          height: 20px;
          width: 20px;
        }
      }
    }
  }

  .inviteUser {
    margin-top: 40px;
    margin-bottom: 16px;
  }
  @media (max-width: 768px) and (min-width: 375px) {
    .responsiveButtonMargin {
      margin-bottom: 12px;
    }
    .nav-link {
      overflow-wrap: break-word;
      width: 74px;
      font-size: 11px;
    }
  }
}
