.LineGraphComponent{
  .highcharts-legend .highcharts-no-tooltip{
    margin-left: 100px;
  }

  .gridChartSwitch{
    padding-left: 24px;
    margin-bottom: -36px;
    display: flex;
    z-index: 1;
    position:absolute;
  }

  g.highcharts-legend.legendStyling{
    position: absolute;
    z-index: 2;
  }
}