.BatchReviewUpload {

  table.neo-grid.table-bordered{
    th{
      padding-right: 24px;
    }
  }

  .tab-pane {
    padding: 5px 0px 5px 0px;
  }
  .downloadButton {
    float: right;
    position: relative;
    margin: 5px;
  }

  table.table {
      td {
        border-right-style: none !important;
        text-decoration-color: $white;
        height: 48px;
        margin: 72px 0 0 256px;
        padding-left: 24px;
        padding-right: 24px;
        border-right: none !important;
      }
      th{
        padding-left: 24px;
        padding-right: 24px;
      }
   
    }
  }

.text-white {
  text-decoration-color: $white;
}

.BatchReviewProspectView {
  div.custom-control.custom-switch
    input.custom-control-input:checked
    .custom-control-label::before {
    color: #ffffff;
    border-color: $bs-danger;
    background: $bs-danger -webkit-gradient(
        linear,
        left top,
        left bottom,
        from($bs-danger),
        to($bs-danger)
      ) repeat-x;
    background: $bs-danger linear-gradient(180deg, $bs-danger, $bs-danger)
      repeat-x;
  }
  .custom-control-label::before {
    left: -1.3rem;
  }
  .custom-control-label::after {
    left: -1.3rem;
  }
  // overriding Theme
  .card-body {
    padding-top: 0px !important;
  }
  .marginTop18 {
    margin-top: 18px;
  }
  .dateProspectedWidth {
    width: 130px;
  }
  .cleanCompanyName {
    width: 290px;
  }
  td {
    button {
      &.gridButton {
        min-width: 40px;
        width: auto;
      }
    }
  }
  .gridButton {
    min-width: 40px;
    width: auto;
  }
  .hideLabel {
    .custom-control {
      label {
        display: none;
      }
    }
  }
  table.table > thead th {
    font-size: 11px;
  }
  table.table td {
    font-size: 11.5px;
  }
  table th.column-is-sorted.column-sortable {
    padding-right: 22px !important;
  }
  .blacklistButton {
    background: #000000b9;
    border-color: #00000000;
  }
  .blacklistButton:hover {
    background: rgba(0, 0, 0, 0.897);
    border-color: black;
  }
  .unblacklistButton {
    background: white;
    color: #00000091;
    border-color: #00000091;
  }
  .unblacklistButton:hover {
    background: white;
    color: black;
    border-color: black;
  }
  .bulkUnblacklistButton {
    background: white;
    color: #00000091;
    border-color: #00000091;
  }

  .InovuaReactDataGrid--theme-default-light {
    fill: black;

    .InovuaReactDataGrid__header {
      background-color: #9eb3c4 !important;
    }

    .InovuaReactDataGrid__column-header--last-in-section.InovuaReactDataGrid__column-header--locked-start {
      .InovuaReactDataGrid__column-header__content {
        fill: #9eb3c4 !important;
      }
    }
  }

  td.neo-child-row-cell {
    border-left-style: none !important;    
    padding-top: 24px;
  }

  .neo-child-row-container {
    max-height: 100%;
    width: 96.5%;
  }
  
  .expandGridIcon {
    // Work in progress
    position: relative;
    top: 7px;
    z-index: 2;
    padding: 1px;
    color: $primary-text-color;
    background-color: transparent;
    min-width: 20px !important;
    left: 7px;
    :hover{
      opacity: 0.8;
    }
  }
  button {
    outline: none;
  }
  .btn:focus {
    outline: thin dotted;
    outline: 5px auto;
    outline-offset: -2px;
    -webkit-focus-ring-color: none;
  }
  .btn:focus {
    outline: none !important;
  }

  .btn:focus {
    box-shadow: none;
  }

  .heightNone {
    height: 16px;
  }

  &.btn-outline-secondary {
    border: none !important;
    color: $bs-secondary !important;
    &:hover,
    &:focus,
    &:active,
    &:visited,
    &:focus-visible {
      background-color: transparent !important;
      border: transparent !important;
      color: $bs-secondary !important;
    }
  }

  .collapseGrid{
    .neo-grid-expand-column{
      .btn-sm{
        padding: 0;
        height: 32px;
        max-height: 32px;
        width: 32px;
        min-width: 32px;
      }
    }
  }

  table.table td{
    font-size: 13px;
  }

  .pageManager{
    div{
      margin-top: 0 !important;
    }
  }

  table.neo-grid.table-bordered{
    th{
      padding-right: 24px;
    }
  }

  .neo-child-row-container{
    table{
      border: none;
    }
  }

  table.table th{
      border-left: none;
      border-right: none;
  }
  .redRow {
    background: #e1b9b9;
    th{
      color: $primary-text-color;
    }
  }
  .neo-child-row-cell th{
    height: 48px;
    padding-bottom: 0px;
  }
}
