.MasterAccountsView {
    button.gridButton.btn.btn-outline-info {
        min-width: 40px;
        width: auto;
    }

    button.gridButton.btn.btn-danger {
        min-width: 40px;
        width: auto;
    }

    .pull-right {
        float: right !important;
    }

    .float-right {
        float: right !important;
    }

    div.modal-footer>div.col-md-6 {
        padding: 0px;
        margin-left: 5px;
    }

    .InovuaReactDataGrid__cell__content {
        button {
            &.gridButton.btn.btn-light {
                svg {
                    position: relative;
                    left: 3px;
                    top: 2px;
                }
            }
        }
    }
}

.submitButtonStyles {
    button.btn.btn-secondary {
        background: $tertiary-color;
        color: $white;

        &:hover,
        &:active,
        &.active,
        &:focus,
        &:visited {
            background: $tertiary-color;
            color: $white;
            opacity: 0.8;
        }

        &:disabled {
            opacity: 0.4;
        }
    }
}