.infoBox {
  .emptyState {

    background-color: $form-background-color;
    font-size: 18px;
    padding: 40px;
    border-radius: 8px;

    p {
      font-size: 18px;
      margin-bottom: 0px;
    }
  }
}