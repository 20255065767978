.WelcomeView {
    display: flex;
    justify-content: center;
    color: $primary-text-color;

    .container {
        display: flex;
        justify-content: center;
        margin: 0%;
    }

    img{
        margin-top: 7.5vw;
        margin-bottom: 2.8vw;
        width: 100%;
    }

    p{
        padding-top: 0.8vw;
        margin-bottom: 1.6vw;
    }

    h1{
        letter-spacing: 0;
        font-size:32px;
        font-weight: bold;
        margin-bottom: 0px;
    }
}