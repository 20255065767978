.ModalOverlay {
    width: 100%;
    height: 100%;
    z-index: 2;
    position: absolute;
    top: 81%;
    left: 0;
    background-color: white;
    background-color: rgba(133, 129, 129, 0.6);
    overflow-x: hidden;
    transition: 0.5s;

    .overlay-content {
        position: relative;
        width: 100%;
        height: 100%;
        .whiteboxOnTop{
            background-color: white;
            width: 100%;
            height: 100%;
            position: relative;                
            img{
                width: 129px;
                position: absolute;
                top: -2%;
                right: 8%;
            }
            .fontSize{
                font-size: 25px;             
            }
            .divWidth{
                width:100%
            }
        }
    }
}


