.placeClipboard {
    float: right;
    z-index: 8;
    position: relative;
    top: 5px;
    right: 15px;
    cursor: pointer;
    font-size: 1.1rem;
}

.noClick-placeClipboard {
    float: right;
    z-index: 8;
    position: relative;
    top: 4px;
    right: 15px;
    font-size: 1.1rem;
    color: gray;
}

.formGroupPosition {
    textarea {
        position: relative;
        top: -30px;
    }

    .form-group {
        margin-bottom: 0px !important;
    }
}

.formGroupPosition2 {
    textarea {
        position: relative;
        top: -2px;
    }

    .form-group {
        margin-bottom: 0px !important;
    }
}

.yourEditsMargin {
    margin-top: 16px;
}

// Need sto be added to maintenance as well for the campaign message maintennanance
.CampaignEmailSignature {

    .richTextEditor {
        .wrapper {
            min-height: 350px;
            max-height: 350px;
            height: 100%;

            display: flex;
            flex-direction: column;

            &.disabled {
                .rdw-editor-toolbar {
                    display: none;
                }

                .editor {
                    background-color: $form-widget-color;
                    max-height: inherit;
                    min-height: inherit;
                }
            }

            .editor {
                min-height: 240px;
                border: 1px solid $tabBorder;
            }

            &.validationFailed {
                .editor {
                    border: 1px solid $validationRed;
                }
            }

        }

        .public-DraftStyleDefault-block {
            margin: 0;
        }
    }
}