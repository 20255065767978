.TargetMarketView {
  table.neo-grid.table-bordered{
    th{
      padding-right: 24px;
    }
    td {
      font-size: 14px;
    }
  }

  .form-control{
    height: 32px;
  }

  .searchComponent{
    .form-control{
      height: auto;
    }
  }

  button.btn-outline-secondary {
    border: none;
  }

  option:nth-child(6) {
    color: grey;
    font-weight: 500;
  }

  option:nth-child(5) {
    color: $color-background-blue;
    font-weight: 500;
  }

  option:nth-child(4) {
    color: $bs-success;
    font-weight: 500;
  }

  option:nth-child(2) {
    color: $bs-primary;
    font-weight: 500;
  }

  option:nth-child(3) {
    color: $bs-warning;
    font-weight: 500;
  }

  option:nth-child(1) {
    color: $bs-danger;
    font-weight: 500;
  }

  select {
    font-weight: 500;
  }

  .progress {
    min-width: 108px;
    width: 100%;
    display: flex;
    height: 2rem;
    overflow: hidden;
    line-height: 0;
    font-size: 0.75rem;
    border-radius: 8px;

    &.incomplete {
      font-size: 16px;
      justify-content: center;
      padding: 16px 0px;
    }
  }

  .progress-bar {
    color: $primary-text-color;
  }

  .pe-auto.user-select-auto {
    cursor: pointer;
    font-size:13px;
  }

  div.tabs-header {
    padding-left: 0px;
    padding-right: 0px;
  }

  .customBadgeFontSize {
    font-size: 10px;
  }

  .customLabelFontSize {
    font-size: 10px;
  }

  .disabledCloneTooltipContainer{
    display: inline-block;
    position: relative;
    
    &:hover{
      // when we hover over the tooltip container, show the tooltip box
      .disabledCloneTooltip{
        display: block;
      }
    }

    // The tooltip box
    .disabledCloneTooltip{
      background-color: #f8f8f8;
      color: #111e1e;
      padding: 4px 8px;
      font-style: normal;
      position: absolute;
      display: none;
      text-align: center;
      width: 300px;
      white-space: normal;
      border-radius: 8px;
      z-index: 10;
      top: -33px;
      left: 50%;
      transform: translateX(-50%); 

      // A young triangle just below the tooltip pointing downward (always)
      &::after{
        content: "";
        position: absolute;
        width: 0;
        height: 0;
        border-left: 8px solid transparent;
        border-right: 8px solid transparent;
        border-top: 8px solid #f8f8f8;
        top: 100%;
        left: 50%;
        -webkit-transform: translateX(-50%);
        -ms-transform: translateX(-50%);
        transform: translateX(-50%);
      }
    }
  }

  .legendRight {
    text-align: center;
  }

  .form-control {
    padding: 6px 13px 0px 12px;
  }

  .ModalOverlay {
    &.targetMarketOverlay {
      height: 120%;
    }
  }

  //leaving as fixed width as its small enough to respond as it should in smaller screens (icon edit)
  button.btn.btn-light {
    &.editButton {
      min-width: 32px;
      height: 32px;
      border-radius: 20px;
    }
  }

  //
  .marginAuto {
    margin-left: auto;
    display: flex;
  }

  .highlightedText {
    color: $secondary-color;
  }

  .cogStyle {
    border: none !important; // Overwriting bootstrap button
    margin-bottom: 4.5px;
    font-size: larger;
  }

  button.gridButton.btn.btn-outline-primary {
    border: none;
    color: $bs-primary;
    float: right;

    &:hover {
      background-color: unset !important; //overwriting boostrap button
    }
  }

  .TMWidth {
    width: 250px !important;
  }

  .progressColumn {
    color: $primary-text-color;
    width: 350px !important;
    text-align: center;
  }

  .bordered {
    border: 2px solid $onBoardingCampaignMessageBorder;
  }

  .centered {
    position: flex;
    justify-content: center;
  }

  td.neo-grid-button-column {
    padding-left: 0;
    padding-right: 0;
  }

  .fa-cogs{
    color: $secondary-color;
  }

  button.primaryBackground.btn-primary{
    background: white;
    &:hover{
       background-color: $offWhite;
    }
  }
}

//overwriting theme loader
div:nth-child(3)>div.loader-overlay {
  display: none !important;
}

@media (max-width: 1161px) {
  .progressLegend {
    display: none;
  }
}

@media (min-width: 1540px) {
  .progressLegendPosition {
    margin-left: 58px;
  }
}

@media (min-width: 375px) and (max-width: 1440px) {

  table.table th.column-is-sorted.column-sortable {
    padding-right: 22px !important; //overwriting theme for responsivness
  }
}

.InfoLabelHover {
  .hoverContainer {
    display: none;
  }

  i {
    svg{
      width:20px;
      height:16px;
    }
    &:hover {
      .hoverContainer {
        display: block;
        width: 250px;
        position: absolute;
        z-index: 10;
        background-color: $tmTooltip;
        color:$primary-text-color;
        padding: 4px;
      }
    }
  }
}

.TargetMarketDefault {
  color: $primary-text-color;

  .input-group {
    background-color: white;
  }

  button.btn {
    padding: 0px;
  }

  .input-group-append .btn {
    background-color: $white;
    height: 32px;
    width: 32px;
    min-width: 32px;
    top: 10%;
    margin-right: 5px;
  }

  button.btn.btn-light {
    svg {
      height: 24px;
      width: 24px;
      margin-bottom: 0;
    }
  }
}

.TargetMarketEdit {
  &.form-control {
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 0px;
    color: $primary-text-color;
    width: 2%;

    &:disabled {
      background-color: white;
    }

    &:not(:disabled) {
      background-color: transparent;
    }
  }
}

.TargetMarketNotEdit {
  &.form-control {
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 0px;
    width: 2%;
    font-size:18px;

    &:disabled {
      background-color: white;
    }
  }
}

.EditNameTargetMarket{
  background-color: $form-background-color;
  button.btn.btn-light.btn-sm{
    background-color: transparent;
  }
}