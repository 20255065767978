.nav.nav-tabs {
  border: none;
  &::before{
    z-index: 1;
  }
  .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    color: $primary-text-color;
    background-color: $white;
    border: none;
    border-bottom: 2px solid $primary-text-color;
    z-index: 15;
  }
  a {
    border: none;
    color: $mediumGrey;
    text-decoration: none;
    background-color: $white;
    padding-left: 0;
  }

  .nav-link {
    border-bottom: 2px solid $tabBorder;
    margin-bottom: -2px;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
    display: block;
    padding: 5px 1px 4px 0px;
  }
  
  .nav-item {
    position: relative;
    z-index: 7;
    padding-right:40px;
    border-bottom: $tabBorder 2px solid;
    
    &:last-child{
      padding-right: 0px;
    }
  }
}

.tab-content {
  box-shadow: none;
  background-color: $white;
  padding: 0%;
  z-index: 0;
}

div.tabs-header {
  padding-left: 24px;
  padding-right: 24px;
}

.nav-item.nav-link.active{
  position: relative;
  z-index: 5;
}
.nav-item.nav-link{
  position: relative;
  z-index: 0;
}
.nav-tabs .nav-link{
  border-color: #e9ecef #e9ecef #dee2e6;
}
.nav-tabs .nav-link.active{
  border-color: white;
}

.OnboardingMaintenanceView{
  div.tabs-header {
    padding-left: 24px;
    padding-right: 24px;
    margin-left: 16px;
  }
}