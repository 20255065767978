@import url(https://fonts.googleapis.com/css?family=Ubuntu+Mono|Roboto:400italic,700italic,400,700);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100;300&display=swap);

// Font Variables
$roboto: "Roboto", sans-serif;
$robotoBold: "Roboto Bold", sans-serif;
$onboardingMenu-width: 256px;

.side-menu-container {
  position: fixed;
  top: 54px; // Account for header
  left: 0px;
  z-index: 10;
  min-height: calc(100vh - 54px);
  max-height: calc(100vh - 54px);
  width: 256px;
  background: $yellow-spectre;
  margin: 0px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: stretch;
  align-content: stretch;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  transition: none !important;
  border: none !important;
  letter-spacing: normal;
  color: $primary-text-color;

  button.btn {
    min-width: 100%;
  }

  p {
    font-weight: 300;
    font-size: 16px;
    margin-bottom: 0px;
  }

  h4,
  h5 {
    font-size: 0.9vw;
    font-family: $lato;
  }

  h5 {
    font-weight: normal;
  }

  h4 {
    font-weight: bold;
  }

  .row {
    margin-left: 0px;
    margin-right: 0px;
  }

  .header-box {
    align-content: flex-start;
    flex-shrink: 0;
    flex-grow: 0;
    background-color: $yellow-spectre;
    flex: 0 0 auto;
    margin: 0px;
    padding-bottom: 0px;

    h1 {
      font-size: 32px !important;
      line-height: 1.31;
      font-weight: 500;
      margin-bottom: 0px;
      margin-top: 10px;
      padding-left: 16px;
      padding-right: 16px;
    }

    iframe {
      width: 100%;
      height: 100%;
    }
  }

  .tab-button-box {
    flex-shrink: 0;
    flex-grow: 0;

    .tab-buttons {
      box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.3);
      background-color: $yellow-spectre;
      color: $onboarding-inactive;
      width: 100%;
      height: 40px;
      font-size: 0.7vw;
      font-weight: normal;
      border-radius: 0px;
      border-bottom: 2px solid $onboarding-inactive-border;
      box-shadow: none;
      //overwriting bootstrap with important
      &:hover {
        background: $yellow-spectre !important;
        border: 1px solid $yellow-spectre !important;
        color: $primary-text-color !important;
        height: 40px;
        font-size: 14px;
        opacity: 0.8;
      }
      &:active {
        background-color: $yellow-spectre !important;
        color: $primary-text-color;
        font-weight: 500;
        width: 100%;
        border-bottom: $primary-text-color 3px solid;
        box-shadow: none;
      }
    }

    .selected-btn {
      background-color: $yellow-spectre !important;
      color: $primary-text-color;
      font-weight: normal;
      width: 100%;
      font-size: 0.8vw;
      border-bottom: $primary-text-color 3px solid !important;

      &:active {
        background-color: $yellow-spectre !important;
        color: $primary-text-color;
        font-weight: 500;
        width: 100%;
        border-bottom: $primary-text-color 3px solid !important;
        box-shadow: none;
      }
    }
  }

  .text-box {
    max-height: calc(100vh - 35vh - 120px);
    overflow-y: scroll;
    flex-shrink: 1;
    flex-grow: 1;
    margin: 0px;
    padding: 16px;
    overflow-wrap: break-word;
    span {
      font-family: $lato !important; //overwriting database
    }
  }

  .prev-next-box {
    flex-shrink: 0;
    flex-grow: 0;
    min-height: 80px;
    align-content: flex-end;
    padding: 20px 16px 20px 16px;
    border: 1px solid $yellow-spectre-50 !important;

    .prev-next-buttons {
      width: 100%;
      height: 40px;
      border-radius: 6px;
      margin: 0 0;
      font-size: 16px;
      font-weight: 500;
      background: $yellow-spectre-32;
      color: $primary-text-color;
      font-weight: bold;
      border-radius: 20px;

      &.btn-primary {
        background: $secondary-color;
        color: $primary-text-color;
        border: none;

        &:hover {
          background: $secondary-color;
          color: $primary-text-color;
          box-shadow: 0 0px 0px 0 $secondary-color;
          background-image: none;
          opacity: 0.8;
        }

        &:focus,
        :focus-within {
          border: none;
          outline: none;
        }
        &:disabled {
          background-color: $yellow-spectre-32;
          color: $yellow-spectre-108;
          box-shadow: 0 0px 0px 0 $yellow-spectre-32;
          background-image: none;
          &:hover {
            background-color: $yellow-spectre-32;
            color: $yellow-spectre-108;
            box-shadow: 0 0px 0px 0 $yellow-spectre-32;
            background-image: none;
          }
        }
      }
    }

    .single-next-button {
      width: 100%;
      height: 40px;
      border-radius: 20px;
      margin: 0 auto;
      font-size: 0.8vw;
      font-weight: bold;
      &:hover {
        background: $bs-secondary;
        border: 1px solid $bs-secondary;
        opacity: 0.8;
      }
    }

    .prev-button-color {
      background-color: $bs-light;
      color: $primary-text-color;
      box-shadow: 0 0px 0px 0 $bs-light;
      border-radius: 20px;
      outline: none;
      border: none;
      &:hover {
        background: $bs-light;
        opacity: 0.8;
        outline: none;
        border: none;
      }

      &:focus,
      &:focus-visible,
      &:visited {
        border: none;
        outline: none;
      }
    }
    .stuck-wording {
      width: 100%;

      .email-link {
        color: $white !important;
        text-decoration: underline;
      }
    }
  }

  svg {
    margin-bottom: 0px;
  }
}

.app-header,
.app-footer,
.sidebar,
.main,
.onboarding-menu {
  transition: none !important;
  border: none !important;
}
.onboarding-menu-fixed .onboarding-menu .tab-content {
  height: calc(100vh - 2.375rem - 524px) !important;
  border-radius: 15px;
}
.marginTop2 {
  margin-top: 2px;
}

.ml-6 {
  margin-left: 6rem !important;
}

.smallText {
  font-size: 14px;
  text-transform: capitalize;
  padding-bottom: 5px;
  font-weight: 400;
}
.largeText {
  font-size: 22px;
  text-transform: capitalize;
}
.list-group-item {
  background-color: $innerCardBackground;
  margin-bottom: 0px;
  border: 0px;
  min-width: 299px;
}
.app a {
  color: $bs-default;
}
.list-group-item.active {
  z-index: 2;
  color: $white;
  background-color: $secondary-color-alternate;
  border-color: $secondary-color-alternate;
  padding: 17px 12px 16px 33px;
  height: 56px;
}
.list-group-accent .list-group-item.list-group-item-divider {
  position: relative;
  height: 53px;
  padding-left: 33px;
  padding-top: 16px;
}

.imgWidth {
  width: 100%;
}

.backgroundColor {
  background-color: $white;
  color: $primary-text-color;
}

.colorWhite {
  color: $white;
}

.colorSecondary{
  color:$primary-text-color;
}

h2 {
  font-size: 32px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}
h5 {
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.56;
  letter-spacing: normal;
}
.app-body .main {
  flex: 1 1;
  min-width: 0;
  margin-left: 306px !important;
}
//delete when actual text is in this section

//delete when actual text is in this section

//video section - this section is imbedded so need to be overwritten with important tags
.vp-center {
  height: 0px;
}
.bg-light {
  background: $innerCardBackground; //overwriting theme
}

iframe {
  width: 100%;
  height: 100%;
}
.player,
.fallback {
  width: 100% !important;
  height: 100% !important;
  margin: 0;
  padding: 0;
  background-color: transparent !important;
}
.bg-light.font-weight-bold.text-muted.text-uppercase.small.list-group-item {
  background: $innerCardBackground !important; //overwriting bootstrap
}

//onboarding content styling (responsive)
@media (min-width: 375px) and (max-width: 768px) {
  .onboarding.app-right-panel {
    padding-left: $onboardingMenu-width;
  }
  .onboarding-menu .sidebar h1 {
    font-size: 22px;
  }
  // .onboardingStepsMenu {
  //   right: 35%;
  // }
}
@media (min-width: 769px) and (max-width: 1360px) {
  div.onboarding.app-right-panel {
    padding-left: $onboardingMenu-width;
  }
  div.mini-nav .onboarding.app-right-panel {
    padding-left: $onboardingMenu-width;
    transition: all 1000ms ease;
  }
  .onboarding-menu .sidebar h1 {
    font-size: 28px;
  }
  // .onboardingStepsMenu {
  //   right: 35%;
  // }
}

@media (min-width: 1361px) and (max-width: 1536px) {
  .onboarding.app-right-panel {
    padding-left: $onboardingMenu-width;
  }
}
@media (min-width: 1537px) {
  .onboarding.app-right-panel {
    padding-left: $onboardingMenu-width;
  }
}

//onboarding content styling (responsive)

//video section
@media (max-width: 991.98px) {
  .d-md-down-none {
    display: initial !important;
  }
}
@media (min-width: 992px) {
  .onboarding-menu-off-canvas .app-header .app-body .onboarding-menu {
    height: calc(100vh - 423px) !important;
  }
}
@media (min-width: 445px) and (max-width: 799px) {
  .onboarding-menu-show .onboarding-menu,
  .onboarding-menu-lg-show .onboarding-menu {
    margin-right: 62% !important;
    height: calc(100vh - 423px) !important;
  }
}

@media (min-width: 800px) and (max-width: 990px) {
  .onboarding-menu-show .onboarding-menu,
  .onboarding-menu-lg-show .onboarding-menu {
    margin-right: 71% !important;
    height: calc(100vh - 423px) !important;
  }
}
@media (min-width: 991px) and (max-width: 1100px) {
  .onboarding-menu-show .onboarding-menu,
  .onboarding-menu-lg-show .onboarding-menu {
    margin-right: 74% !important;
    height: calc(100vh - 423px) !important;
  }
}
@media (min-width: 1101px) and (max-width: 1500px) {
  .onboarding-menu-show .onboarding-menu,
  .onboarding-menu-lg-show .onboarding-menu {
    margin-right: 80% !important;
    height: calc(100vh - 423px) !important;
  }
}
@media (min-width: 1501px) {
  .onboarding-menu-show .onboarding-menu,
  .onboarding-menu-lg-show .onboarding-menu {
    margin-right: 82% !important;
    height: calc(100vh - 423px) !important;
  }
}
@media (min-width: 992px) {
  .onboarding-menu-show.onboarding-menu-fixed .main,
  .onboarding-menu-show.onboarding-menu-fixed .app-footer,
  .onboarding-menu-lg-show.onboarding-menu-fixed .main,
  .onboarding-menu-lg-show.onboarding-menu-fixed .app-footer {
    margin-right: 0px !important;
  }
}
@media (min-width: 992px) {
  .onboarding-menu-fixed {
    &.onboarding-menu {
      &.tab-content {
        height: calc(100vh - 327px) !important;
        width: 100%;
        background-color: $innerCardBackground;
      }
    }
  }
}

//elements
#triangle-up {
  width: 0;
  height: 0;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  border-bottom: 15px solid $innerCardBackground;
  margin-left: 10px;
}
