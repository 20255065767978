@import "../../Styles/Variables.scss";
$header-height: 48px;
$level-2-height: 56px;

.sidebar {
  color: $darkSilver;
  background: $emailModal;
  height: calc(100% - 48px);
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2),
    0 1px 5px 0 rgba(0, 0, 0, 0.12) !important;
  display: flex;
  flex-flow: column nowrap;
  margin: 0 0px 0 0;

  .nav-link {
    color: $color-text;
  }

  // Needs to be added for when the sidebar has too many items
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 2px;
  }

  img {
    width: 50%;
    padding: 10px;
    text-align: center;
    display: inherit;
    margin-left: 25%;
    transition: width 1s;
    visibility: visible;
    margin-top: 0px;
    margin-bottom: 5px;
  }

  .sidebar-header {
    flex: 0 0 auto;
    text-align: center;
    visibility: visible;
    opacity: 1;
    transition: ease-in-out visibility 1s linear 1s, opacity 1s;
    margin-top: -9px;
  }

  .sidebar-toggle {
    position: absolute;
    right: -0.7em;
    top: 50%;
    transform: translate(0, -50%);
    animation-duration: 0.1s;
    z-index: 10;
    width: 25px;
    height: 25px;
    -moz-border-radius: 50px;
    -webkit-border-radius: 50px;
    border-radius: 50px;
    background-color: #f7f7f7;
    text-align: center;
    border: solid 1px;
    border-color: #a7b1c2;

    a {
      color: #111e1e;
    }
  }

  .menu-separator {
    margin-top: 16px;
    margin-bottom: 16px;
    border-top: $tabBorder;
    border-top-width: 2px;
    border-top-style: solid;
    width: 208px;
    margin-left: auto;
    margin-right: auto;
  }

  .mini-menu-separator {
    margin-top: 16px;
    margin-bottom: 16px;
    border-top: $tabBorder;
    border-top-width: 2px;
    border-top-style: solid;
  }

  .sidebar-menu-item-padding {
    padding-left: 20px;
  }

  .sidebar-content {
    flex: 1;

    ul {
      padding: 0;
      margin: 0;
      transition: max-height ease-in-out 0.3s;

      li {
        list-style-type: none;
        position: relative;

        div.menu-expander {
          position: absolute;
          right: 10px;
          top: 10px;

          i.fa.fa-caret-right {
            background: none;
            position: relative;
            left: 79%;
          }

          i.fa.fa-caret-down {
            background: none;
            position: relative;
            left: 79%;
          }
        }

        div.mini-menu-expander {
          height: 0px;

          i.fa.fa-caret-right {
            background: none;
            position: relative;
            left: 80%;
            bottom: 50px;
            color: white;
          }

          i.fa.fa-caret-down {
            background: none;
            position: relative;
            left: 39%;
            bottom: 31px;
            color: white;
          }
        }

        @media (min-width: 769px) and (max-width: 1360px) {
          div.mini-menu-expander {
            position: absolute;
            right: 10px;
            top: 10px;

            i.fa.fa-caret-right {
              background: none;
              position: relative;
              left: 79%;
              bottom: unset;
              color: $primary-color;
            }

            i.fa.fa-caret-down {
              background: none;
              position: relative;
              left: 79%;
              bottom: unset;
              color: $primary-color;
            }
          }

          div.menu-expander {
            height: 0px;
            position: unset;
            right: unset;
            top: unset;

            i.fa.fa-caret-right {
              background: none;
              position: relative;
              left: 80%;
              bottom: 50px;
              color: white;
            }

            i.fa.fa-caret-down {
              background: none;
              position: relative;
              left: 39%;
              bottom: 31px;
              color: white;
            }
          }
        }
      }

      .menu-item {
        &.has-children {
          cursor: pointer;
        }

        &:hover {

          a,
          span {
            background-color: $hoverBlue;
          }
        }
      }

      .menu-item {
        .fa {
          color: $tertiary-color;
          width: 40px;
          height: 40px;
          vertical-align: center;
          padding-top: 12px;
          border-radius: 19px;
          margin-right: 10px;
        }

        a.active {
          background-color: $sidemenu-skyblue;
          height: 56px;
          padding-top: 14.5px;
          padding-left: 18px;

          .sidebar-menu-item-padding {
            color: $tertiary-color;
          }

          svg {
            color: $tertiary-color;
          }
        }

        a,
        span {
          display: block;
          padding: 10px;
          padding-top: 14px;
          text-decoration: none;
          font-weight: 500;
          color: $sidemenu-text;
          height: 56px;
          padding: 14.5px 10px 10px 18px;
          border-radius: 16px;
          color: $sidemenu-inactive-text;

          svg {
            color: $sidemenu-inactive-text;
          }
        }

        .sidebarIcons {
          a {
            margin: 16px 8px 16px 8px;
            border-radius: 16px;
          }
        }

        .sidebarIconsMini {
          a {
            margin: 16px 4px 16px 4px;
            padding: 14.5px 10px 10px 15px;
            border-radius: 16px;
          }
        }
      }
    }

    ul.menu-level-2 {
      background: #ddd;

      .menu-item {
        height: $level-2-height;

        a:hover {
          height: $level-2-height;
        }

        a.active {
          height: $level-2-height;
        }
      }

      a,
      span {
        font-size: 0.9em;
        padding: 10px 0px 10px 25px;
      }

      .menu-item i {
        height: 21px;
        width: 18px;
        padding-top: 8px;
        font-size: 18px;
      }
    }
  }
}

@media (min-width: 769px) and (max-width: 1360px) {
  .sidebar {
    box-shadow: none;

    img {
      margin-top: 10px;
    }

    .sidebar-header {
      visibility: hidden;
      opacity: 1;
      transition: ease-in-out visibility 1s linear 1s, opacity 1s;
    }
  }
}