//General colors
$white:#ffffff;
$SliderColor: #fff;
$positiveGreen: #1bc871;
$offWhite: #f7f7f7;
$backgroundGrey: #fafafa;
$innerCardBackground:#f0f3f5;
$offGrey: #ecefef;
$e6Grey:#e6e6e6;
$modalSideMenuGrey: #E6E7E7;
$openFolloUpTemplateModalBlue: #e9f2f7;
$veryLightGreyBlue: #ECF0F4;
$lighterGrey: #eeeeee;
$TabBackground:#f1f1f1;
$lightGrey: #dddddd;
$greyA: #aaaaaa;
$grey: #CCCCCC;
$skyBlue: #CFE6EE;
$headingGrey:#949292;
$mediumBlue: #2B6094;
$darkBlue: #25408F;
$darkerBlue: #182969;
$royalBlue: #0C2666;
$greyDarkBlue: #202C36;
$grayBlue: #2A3B4A;
$turquoise: #04C4D1;
$offBlue: #4F95BB;
$hoverBlue: #889fb2;
$lighterBlue: #00BCFF;
$waterBlue: #4dc7e4;
$green: #109110;
$lightGreen: #58a058;
$onboardingGreen: #5fd593;
$pink: #ff007a;
$darkPink: #ff007a;
$red: #D90000;
$orange: #e76565;
$lightOrange: #f6871f;
$warningOrange: #f8881f;
$lighterOrange: #f19d4e;
$hoverOrange:#fff5f0;
$silver: #A7B1C2;
$darkSilver: #7a7a7a;
$greyBlue: #607d8b;
$brown: #58595b;
$darkBrown: #555555;
$coal: #444444;
$darkCoal: #333333;
$lightBlack: #222222;
$darkBlack: #111111;
$black: #000000;
$lightPurple: #9579ac;
$concrete: #dee2e6;
$burgerMenu:#555e68;
$buttonGrey:#b2b2b2;
//Background colors
$primary-color:#ff652d;
$primary-color-alternate:#Ed5f62;
$primary-text-color: #111e1e;
$warning-color:#fef4de;
$secondary-color:#f6bd3a;
$secondary-color-alternate:#f3bb90;
$secondary-text-color: #b3b7b7;
$tertiary-color: #002648;
$default-color: $silver;
$pausedRed: #e9533b;
$sidemenu-skyblue: #D2E7F1;
$sidemenu-text: #002648;
$sidemenu-inactive-text: #0B0B0C;
$panelHover: #CADBD9;
$pillBackground: #F8F4E9;
$recipientBlue: #405D76;
$darkGrey: #d3d5d5;
$activeBorderGrey: #cccfcf;
$mediumGreyText: #535C5C;
$subHeaderGrey: #535C5C;
$svgDefaultColour: #121212;
$screenNavigatorBackground: #97CBE6;
$screenNavigatorCompleteBackground: #e9f2f7;

$background-color: #ffffff;
$search-background: #f8f8f8;
$search-background-active: #f8f8f8;
$search-color: #b3b7b7;

$validationRed: #cb5151;

//COMX Colors
$table-header-color:$white; 
$border-bottom-table-color: #dcdddd;
$darker-tooltip-color:#b3b7b7;
$card-header-color:#949292;
$dash-board-color:#343332;
$refer-us-color:#b2b2b2;
$table-switch-color: #9da2a2;
// override bootstrap 4 variables
$bs-primary: $primary-color;
$bs-secondary: #f6bd3a;
$bs-tertiary: #f5ebd0;
$bs-info: #369ed4;
$bs-success: #3fb560;
$bs-danger: #fdecec;
$bs-warning: $warning-color;
$bs-light: #edeeee;
$bs-dark: #111e1e;
$bs-purple: #b974e0;
$bs-default:$default-color;
$bs-primary-disabled:#fce8ba;
$bs-primary-hover:#fce8ba;
$bs-secondary-disabled:#50687c;
$bs-secondary-hover:#172531;
$bs-lightGreen:#ebf7ef;

// Theme Colours &  override bootstrap 4 variables
$theme-colors: (
  "primary": $bs-primary,
  "secondary": $bs-secondary,
  "tertiary":$bs-tertiary,
  "success": $bs-success,
  "danger": $bs-danger,
  "warning": $bs-warning,
  "info": $bs-info,
  "light": $bs-light,
  "dark": $bs-dark,
);

$alert-bg-level: -9.5;
$alert-border-level:-8;
$alert-color-level: 7;

$form-border: #ced4da;
$form-focused-border: #419de5;
$form-widget-color: #e9ecef;
$form-focused-border: #80bdff;
$form-focused-shadow-color: rgba(0,123,255,.25);
$form-focused-shadow: 0 0 0 0.2rem $form-focused-shadow-color;
$form-background-color: #f8f8f8;
$emailPill-background-colour: #F8F4E9;

// Validation colours
$validation-color-error: $bs-danger;
$validation-color-warning: $bs-warning;
$validation-color-info: #117a8b;

$validation-border-error: $validation-color-error;
$validation-shadow-error-color: #f04541;
$validation-shadow-error: 0 0 0 0.2rem $validation-shadow-error-color;

$validation-border-warning: $validation-color-warning;
$validation-shadow-warning-color: rgba(214, 101, 0, .25);
$validation-shadow-warning: 0 0 0 0.2rem $validation-shadow-warning-color;

$validation-border-info: $validation-color-info;
$validation-shadow-info: 0 0 0 0.2rem rgba(17, 122, 139, .25);

// Application Layout/Sidebar Colours
$color-background: #f5f5f5;
$color-hover: #f6f6f6;
$color-sidebar: #2f353a;
$color-footer: #f0f3f5;
$color-text: #343332;
$color-silver: silver;
$color-coal:  #23222270;
$color-background-blue:#167495;
$color-hover-blue:#5b809e;
$app-container-background:#F7F7F7;
$color-hover: #dbdfe3;
$onboarding-inactive:#b3b7b7;
$onboarding-inactive-border:#edeeee;
$onboarding-disabled-color:#e2ae35;
$mediumGrey: #818888;
$tooltipLine: #dedede;
$informationBox: #fffaf0;
$emailModal: #f8f8f8;
$onBoardingCampaignMessageBorder: #fade9c;
$onboardingMenuActive:#002648;
$tabBorder:#dcdddd;
$progress-green:#daeedf;
$tmTooltip:#efeaea;
$selectedOrange: #f9f0e9;
$switchIconGrey: #7F92A3;
$switchIconOrange: #FF6731;
$inputEndIconColor: #E8EEFA;


// Gradients on buttons
$enable-gradients: true;

// Shadows on buttons / modals
$enable-shadows: false;

//button input margin top 
$button-top:31px;

// main button width
$button-100:100px;

// table column full width
$table-full-width:100%;

// Dashboards
$dashboard-row-segregator: #f0f3f5;
$dashboard-header: #9fa7ac;
$dashboard-expand-indicator: #343332;
$dashboard-neutrality: #f1c40f;
$light-grey-background: #f0f3f5;
$stat-hover-border: #d4dde3;
$stat-active-border: #d4dde3;
$yellow-spectre: #fffaf0;
$yellow-spectre-32: #fce8ba;
$yellow-spectre-17: #fef4de;
$yellow-spectre-108:#e2ae35;
$yellow-spectre-50:#fade9c;
$yellow-spectre-82:#f7c85b;
$blue-spectre: #001223;

// Action List
$campaign-name-black: #374242;
$checkbox-background: #002648;
$checkbox-unchecked-border: #adb5bd;


// Slightly modifies the xl breakpoint by changing the max-width from 1140 to 1160 (to cater for larger padding).
// Adds an xxl breakpoint for full HD screens.
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1800px
);
$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1160px,
  xxl: 1559px
);

@import url(https://fonts.googleapis.com/css?family=Ubuntu+Mono|Roboto);

// Font Variables
$roboto-font: 'Roboto', sans-serif;
$roboto: "Roboto", sans-serif;
$lato: "Lato";
$GTWalsheimMedium:"GT-Walsheim-LC-Medium";
$GTWalsheimLight:"GT-Walsheim-LC-Light";