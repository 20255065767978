/* Grow */
@mixin grow {
	@include hacks();
	@include prefixed(transition-duration, $mediumDuration);
	@include prefixed(transition-property, transform);

	&:hover,
	&:focus,
	&:active {
		@include prefixed(transform, scale(1.1));
	}
}
//Hover Animations
.grow:hover {
	-webkit-transform: scale(1.3);
	-ms-transform: scale(1.3);
	transform: scale(1.3);
	transition: width 2s;
	transition-duration: 2s;
  }
  .grow-button:hover {
	-webkit-transform: scale(1.1);
	-ms-transform: scale(1.1);
	transform: scale(1.1);
	transition: width 2s;
	transition-duration: 2s;
  }
  .shadow:hover {
	box-shadow: 1px 1px rgba(0, 0, 0, 0.14), 2px 2px rgba(0, 0, 0, 0.2), 3px 3px rgba(0, 0, 0, 0.12);
	-webkit-transform: translateX(-3px);
	transform: translateX(-3px);
  }
  .shrink:hover {
	-webkit-transform: scale(0.8);
	-ms-transform: scale(0.8);
	transform: scale(0.8);
  }