.demoClientMaintenance{
  .neo-grid-button-column {
    min-width: 150px;
    padding: 0rem 1rem 0rem 1rem;
    button {
      display: flex;
      margin: auto;
      text-align: center;
    }
  }
}