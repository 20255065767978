.TargetMarketViewClient {
  .TMVColumnWidthNumber {
    width: 50px;
    text-align: center;
  }

  table {
    td {
      font-size: 14px;
    }
  }

  h2 {
    padding-top: 0px;
  }

  .progress {
    min-width: 108px;
    width: 100%;
    display: flex;
    height: 2rem;
    overflow: hidden;
    line-height: 0;
    font-size: 0.75rem;
    border-radius: 8px;

    &.incomplete {
      font-size: 16px;
      justify-content: center;
      padding: 16px 0px;
    }
  }

  .progress-bar {
    color: $primary-text-color;
  }

  .pe-auto.user-select-auto {
    cursor: pointer;
    font-size: 13px;
  }

  div.tabs-header {
    padding-left: 0px;
    padding-right: 0px;
  }

  .ModalOverlay {
    &.targetMarketOverlay {
      height: 120%;
    }

    img {
      right: 1%;
    }
  }

  //leaving as fixed width as its small enough to respond as it should in smaller screens (icon edit)
  button.btn.btn-light {
    &.editButton {
      min-width: 32px;
      height: 32px;
      border-radius: 20px;
    }
  }

  button.btn.btn-danger {
    svg {
      margin-bottom: 0px;
    }
  }

  table.table th.column-is-sorted.column-sortable {
    padding-right: 22px !important;
  }

  .TMNameWidth {
    min-width: 130px;
  }

  .TMViewProgressWidth {
    min-width: 350px;
  }

  @media (min-width: 375px) and (max-width: 1440px) {
    table.table th.column-is-sorted.column-sortable {
      padding-right: 22px !important; //overwriting theme for responsivness
    }
  }

  .text-indent {
    text-indent: 6px;
  }

  .disabledCloneTooltipContainer{
    display: inline-block;
    position: relative;
    
    &:hover{
      // when we hover over the tooltip container, show the tooltip box
      .disabledCloneTooltip{
        display: block;
      }
    }

    // The tooltip box
    .disabledCloneTooltip{
      background-color: #f8f8f8;
      color: #111e1e;
      padding: 4px 8px;
      font-style: normal;
      position: absolute;
      display: none;
      text-align: center;
      width: 300px;
      white-space: normal;
      border-radius: 8px;
      z-index: 10;
      top: -33px;
      left: -50%;
      transform: translateX(-50%); 

      // A young triangle just below the tooltip pointing downward (always)
      &::after{
        content: "";
        position: absolute;
        width: 0;
        height: 0;
        border-left: 8px solid transparent;
        border-right: 8px solid transparent;
        border-top: 8px solid #f8f8f8;
        top: 100%;
        left: 69%;
        -webkit-transform: translateX(-50%);
        -ms-transform: translateX(-50%);
        transform: translateX(-50%);
      }
    }
  }
}