@import '~bootstrap/scss/_functions.scss';
@import '~bootstrap/scss/_variables.scss';

div.custom-control.custom-switch{
    input.custom-control-input:checked ~ .custom-control-label::before {
        color: $white;
        border-color: $secondary-color;
        background:  $secondary-color -webkit-gradient(linear, left top, left bottom, from($secondary-color), to( $secondary-color)) repeat-x;
        background:  $secondary-color  linear-gradient(180deg, $secondary-color,  $secondary-color) repeat-x;
    }
}
