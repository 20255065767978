.customPager {

    // Style the dropdown
    select:valid {
        color: $mediumGreyText;
        border-radius: 8px;
        border: 1px solid $tabBorder;
        margin-top: 4px;
        padding-left: 2px;
        margin-bottom: 10px;
        padding-top: 2px
    }

    .marginTop12 {
        margin-top: 12px;
    }
}

.customPager2 {
    select:valid {
        padding-top: 6px;
        margin-bottom: 7px;
    }
}