.LeadMessageSideMenuButton {
    // Styling applied to selected Hot/Warm/Cold button
    .selectedButton {
        transition: background-color 0.2s ease-in-out;
        color: $white;
        background-color: $tertiary-color;
        border-radius: 8px;
        padding: 8px;
        border: none;
        height: 40px;
        width: 40px;
    }

    // Styling applied to unselected Hot/Warm/Cold buttons
    .unselectedButton {
        border-radius: 8px;
        padding: 8px;
        background-color: $bs-light;
        border: none;
        height: 40px;
        width: 40px;

        &:hover {
            background-color: $form-background-color;
        }
    }

    // Styling applied to the unseen count
    .unseenCount {
        position: relative;
        top: -42px;
        right: -24px;
        background-color: $validation-shadow-error-color;
        color: $white;
        border-radius: 50%;
        font-size: 9px;
        width: 14px;
        height: 14px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-top: 1px;
    }

    // Styling applied to the unseen count when > 99
    .unseenCount.plus {
        min-width: 23px;
    }

    // Styling applied to the unseen count notification when all items have been seen (unseenCount < 1)
    .allSeen {
        position: relative;
        top: -42px;
        right: -24px;
        background-color: $bs-success;
        color: $white;
        border-radius: 50%;
        font-size: 9px;
        width: 14px;
        height: 14px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-top: 3px;

        svg {
            margin-bottom: 3px;
            padding: 3px;
            stroke-width: 4px;
        }
    }
}