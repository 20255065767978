@import "~bootstrap/scss/_functions.scss";
@import "~bootstrap/scss/_variables.scss";

// Input override
form {
  .form-control {
    font-size: 14px;
    font-weight: 400;
    line-height: 1.5;
    color: $color-text;
    border-radius: 1px;

    &:focus {
      outline: $secondary-text-color;
      box-shadow: none;
      border: 1px solid $secondary-text-color;
    }
  }

  .form-group {
    display: block;
    border-radius: 1px;
  }
}

//react drop down overwriting plugin styles which is why importants are being used.
.css-yk16xz-control {
  border-color: transparent !important;
  background-color: $emailModal !important;
  min-height: 48px !important;
  outline: $secondary-text-color !important;
  border-radius: 8px !important;
}

.css-yk16xz-control:active {
  border: 1px solid $secondary-text-color !important;
  box-shadow: none !important;
  border-style: none !important;
  border-width: 1px !important;
}

.css-yk16xz-control:focus {
  border: 1px solid $secondary-text-color !important;
  box-shadow: none !important;
  border-width: 1px !important;
}

.css-yk16xz-control:hover {
  border: 1px solid $secondary-text-color !important;
  box-shadow: none !important;
  border-width: 1px !important;
}

.css-yk16xz-control:focus-within {
  border: 1px solid $secondary-text-color !important;
  box-shadow: none !important;
  border-width: 1px !important;
}

.css-yk16xz-control:visited {
  border: 1px solid $secondary-text-color !important;
  box-shadow: none !important;
  border-width: 1px !important;
}

.css-1pahdxg-control {
  background-color: $emailModal !important;
  border-color: none !important;
  min-height: 48px !important;
  border-radius: 8px !important;
}

.css-1pahdxg-control:active {
  border: 1px solid $secondary-text-color !important;
  box-shadow: none !important;
  border-style: none !important;
  border-width: 1px !important;
}

.css-1pahdxg-control:focus {
  border: 1px solid $secondary-text-color !important;
  box-shadow: none !important;
  border-style: none !important;
  border-width: 1px !important;
}

.css-1pahdxg-control:hover {
  border: 1px solid $secondary-text-color !important;
  box-shadow: none !important;
  border-width: 1px !important;
}

.css-1pahdxg-control:focus-within {
  border: 1px solid $secondary-text-color !important;
  box-shadow: none !important;
  border-width: 1px !important;
}

.css-1pahdxg-control:visited {
  border: 1px solid $secondary-text-color !important;
  box-shadow: none !important;
  border-width: 1px !important;
}

.css-lj44tc-multiValue {
  background-color: $bs-light !important;
  border-radius: 20px;
  color: $secondary-text-color !important;
  padding: 6px;
  display: flex;
  margin: 2px;
  min-width: 0;
  box-sizing: border-box;
}

.css-11hh5z3 {
  border-radius: 2px;
  color: $primary-text-color !important;
  font-size: 85%;
  overflow: hidden;
  padding: 3px;
  padding-left: 6px;
  text-overflow: ellipsis;
  white-space: nowrap;
  box-sizing: border-box;
  font-weight: bold;
}

.css-9kjjb6:hover {
  background-color: $bs-light !important;
  color: $primary-text-color !important;
  border-radius: 15px;
  opacity: 0.8;
}

.css-9kjjb6 {
  color: $primary-text-color !important;
}

.capitalize.mt-3.col-6.p-0.css-2b097c-container {
  box-shadow: none !important;
  outline: $secondary-text-color !important;
  border-radius: 0px;
  padding: 5px !important;

  &:focus,
  &:hover &:active,
  &:focus-within,
  &:visited {
    outline: $secondary-text-color !important;
    box-shadow: none !important;
    border-radius: 0px !important;
    border: none;
  }
}

.btn-link-inline {
  padding: 0;
  vertical-align: inherit;
}

.btn.form-btn {
  margin-top: 31px;
  width: 100%;
}

.btn-width-100 {
  min-width: 100px !important;
  width: auto;
}

.btn-width-100.btn-light svg {
  padding-right: 5px;
}

.noTopBorder {
  border-top: none;
  font-size: 14px;
  width: 101%;
}

.noTopBorderRight {
  border-top: none;
  font-size: 14px;
  width: 101%;
  float: right;
}

.InovuaReactDataGrid--theme-default-light .InovuaReactDataGrid__cell--show-border-right.InovuaReactDataGrid__cell--last-in-section.InovuaReactDataGrid__cell--locked-start,
.InovuaReactDataGrid--theme-default-light .InovuaReactDataGrid__footer-row-cell--show-border-right.InovuaReactDataGrid__footer-row-cell--last-in-section.InovuaReactDataGrid__footer-row-cell--locked-start,
.InovuaReactDataGrid--theme-default-light .InovuaReactDataGrid__locked-row-cell--show-border-right.InovuaReactDataGrid__locked-row-cell--last-in-section.InovuaReactDataGrid__locked-row-cell--locked-start {
  padding-right: 7px;
}

.app-header-panel .app-hamburger-container.justifyLeft {
  float: right;
  margin: 0 auto;
}

.negative-row-margin {
  margin-left: -7px;
}

.pull-right {
  float: right;
}

//tooltip
.neo-tooltip {
  background-color: $form-background-color;
  border-radius: 8px;
  color: $primary-text-color;
  font-size: 16px;
}

// Alerts and toasts
@each $color,
$value in $theme-colors {
  .toast-header-#{$color} {
    @include alert-variant(theme-color-level($color, $alert-bg-level),
      theme-color-level($color, $alert-border-level),
      theme-color-level($color, $alert-color-level));
  }

  .toast-count-down-#{$color} {
    background-color: theme-color-level($color, -3);
  }
}

.alert-warning {
  @include alert-variant(theme-color-level("warning", -9),
    theme-color-level("warning", -6),
    theme-color-level("warning", 7));
}

.alert-dark {
  @include alert-variant(theme-color-level("dark", -3),
    theme-color-level("dark", -1),
    $bs-light );
}

.toast-header-warning {
  @include alert-variant(theme-color-level("warning", -7),
    theme-color-level("warning", -4),
    theme-color-level("warning", $alert-color-level));
}

.toast-header-dark {
  @include alert-variant(theme-color-level("dark", -8),
    theme-color-level("dark", -4),
    theme-color-level("dark", $alert-color-level));
}

// ol & ul - this is specifically for upload pages
ol {
  padding: 0px;
  color: $primary-text-color;
  font-size: 15px;
  font-weight: 600;
  margin-bottom: 0px;

  .dropZoneListDisplay {
    display: inline;
  }

  .customDownloadLink {
    float: right;
    color: $primary-text-color;
    font-weight: 600;
    font-size: 16px;
    cursor: pointer;
    position: relative;
    bottom: 4px;
  }

  .customDownloadLink::before {
    font-weight: 400;
    content: "\2B73";
    margin-right: 6px;
    font-size: 20px;
  }

  .secondaryDownloadLink {
    float: left;
    color: $switchIconOrange;
    font-weight: 500;
    font-size: 16px;
    cursor: pointer;
    position: relative;
    padding-bottom: 24px;

    svg {
      margin: 0px 8px 3px 8px;
    }
  }

  .secondaryDownloadLink::before {
    font-weight: 400;
    content: "\2B73";
    margin-right: 6px;
    font-size: 20px;
  }

  .updateLinkColor {
    color: $primary-text-color;
  }
}

// ol & ul - this is specifically for upload pages

/* Card overrides */
div.neo-card.card {
  margin: 1.5rem;
  border-radius: 0px;
  border-color: none;
  border: none;
  border-radius: 16px;
  box-shadow: none;

  .card-header {
    background: $color-footer;
    padding: 10px 20px;
    border-bottom: none;
    margin-bottom: 0;
    color: $color-text;
    font-weight: 500;
    font-size: 15px;
    border-radius: 0px;

    i {
      margin-right: 10px;
    }
  }

  .card-body {
    padding: 0px;
    border-radius: 0px;
  }

  .card-header-right-section {
    .header-div {
      width: 100%;
      position: absolute;
      z-index: 0;
      top: 0;
      left: 0;
      height: 40px;
      cursor: pointer;
    }

    i.fa.fa-caret-right {
      float: right;
      float: right;
      font-size: 19px;
      margin-top: 13px;
    }

    i.fa.fa-caret-down {
      float: right;
      float: right;
      font-size: 19px;
      margin-top: 13px;
    }
  }
}

@media (max-width: 1360.98px) {
  .neo-card.card {
    border: none;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
      0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);

    .card-header {
      background: $color-footer;
      padding: 10px 20px;
      border-bottom: none;
      margin-bottom: 0;
      color: $color-text;
      font-weight: 500;
      font-size: 15px;
    }

    .card-body {
      padding: 15px;
    }
  }
}

.app-header-panel .app-user-icon {
  .app-user-card {
    ul.list-group.list-group-flush {
      display: flex;
      flex-direction: column;
      padding-left: 0;
      margin-bottom: 0;
      background: $primary-color;
    }

    .card>.list-group:last-child .list-group-item:last-child {
      border-bottom-right-radius: 0.25rem;
      border-bottom-left-radius: 0.25rem;
      background: $primary-color;
    }

    //bootstrap override
    a {
      color: $white !important;
    }

    .card-arrow {
      border-width: 0 10px 10px 10px;
      border-color: transparent transparent $offWhite transparent !important;
      border-style: solid;
      position: absolute;
      top: -8px;
      right: 19px;
      z-index: 1;
    }
  }
}

//Custom inner card style / Overwriting out
.neo-card.card.inner-card {
  margin-top: 15px;
  margin-bottom: 15px;
  border-radius: 0px;
  border-color: none;
  border: 1px solid $e6Grey;
  border-radius: 0px;
  box-shadow: none;
}

//Custom inner card style
.inner-card {
  margin: 10px 15px 10px 15px;
  border-radius: 0px;
  border: 1px solid $lightGrey;

  .inner-card-header {
    background: $innerCardBackground;
    padding: 10px 20px;
    border-bottom: none;
    margin-bottom: 0;
    color: $color-text;
    font-weight: 500;
    font-size: 15px;
    margin-top: 0px;
  }

  .inner-card-body {
    flex: 1 1 auto;
    min-height: 1px;
    padding: 15px;
  }
}

.card-body-only {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
  padding: 15px;
  background: $white;
}

//custom margins and padding
.customMarginTop24 {
  margin-top: 24px;
}

.customMarginTop31 {
  margin-top: 31px !important;
}

.custom-text-white {
  color: $white;
}

.marginTop24 {
  margin-top: 24px !important;
}

.marginTop4 {
  margin-top: 4px !important;
}

.marginRight24 {
  margin-right: 24px !important;
}

.marginBottom24 {
  margin-bottom: 24px !important;
}

.bordered {
  border: 2px solid $onBoardingCampaignMessageBorder;
}

.lightGreenBackground {
  background-color: $bs-lightGreen;
}

.centered {
  position: flex;
  justify-content: center;
}

.ml-7 {
  margin-left: 10%;
}

// TO DO: We should not need to override buttons. Look into removing this (button.btn.btn-light)

// TO DO: We should not need to override buttons. Look into removing this (button.btn.btn-light)

button.btn.btn-light {
  // background: none;
  color: inherit;
  border: none;
  box-shadow: none;
}

button.btn.btn-light:hover,
button.btn.btn-light:focus button.btn.btn-light:visited {
  // background: none;
  color: inherit;
  border: none;
  // opacity: 0.8;
  box-shadow: none;
}

button.btn.btn-light.hover button.btn.btn-light.focus button.btn.btn-light.visited {
  // background: none;
  color: inherit;
  border: 2px solid $primary-color;
  opacity: 0.8;
  box-shadow: none;
}

//custom margins and padding

.scrollbar {
  overflow: auto;
  height: 500px;
}

::-webkit-scrollbar {
  width: 10px;
  height: 8px;
  background: $onboarding-inactive-border;
}

::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background: $yellow-spectre-50;
}

.custom-btn {
  width: 100% !important;
  height: 48px;
  font-size: 0.8vw;
}

.border-bottom-light {
  border-bottom: 1px solid $silver;
}

.selectedCheck {
  input {
    height: 16px;
    border: 1px solid black;
    background-color: black;
    left: 24px;
    position: relative;
    top: -10px;
  }

  content: "";
  position: relative;
  top: 3px;
}

.gridButton {
  min-width: 40px !important; // Overwriting global button style
  width: auto;
}

button {
  &.buttonHover {
    .hoverText {
      background-color: $bs-light;
      display: none;
      color: $primary-text-color !important;
      font-weight: 400 !important;
      font-size: 14px;
    }

    &:hover {
      .hoverText {
        display: block;
        position: absolute;
        padding-left: 5px;
        padding-right: 5px;
        top: -30%;
        z-index: 1;
        color: #1f5785;
        font-weight: bold;
        background-color: #bdc0c4;
      }
    }
  }
}

@media (min-width: 330px) and (max-width: 768px) {
  .nav-link {
    padding: 5px;
    height: 40px;
  }

  .nav-tabs .nav-item {
    margin-bottom: 0px;
  }

  .col6ResponsivePosition {
    position: initial !important;
    width: unset !important;
  }

  table.w-xs-100 {
    width: 100%;
  }

  table.w-sm-100 {
    width: 100%;
  }

  table.neo-grid th.column-is-sorted {
    padding-right: 0px;
  }
}

@media (min-width: 769px) and (max-width: 1345px) {
  table.w-md-100 {
    width: 100%;
  }

  table.w-lg-100 {
    width: 100%;
  }

  .nav-link {
    padding: 5px;
  }

  .nav-tabs .nav-item {
    margin-bottom: 0px;
  }
}

@media (min-width: 1346px) {
  table.w-xl-50 {
    width: 50%;
  }
}

.ComxDataGrid {
  font-size: 11.5px !important;

  .inovua-react-pagination-toolbar__icon--named--REFRESH {
    display: none;
  }

  .inovua-react-toolkit-combo-box {
    font-size: 11.4px !important;
  }

  .InovuaReactDataGrid__row {
    .InovuaReactDataGrid__cell__content {
      button {
        &.gridButton {
          min-width: 32px !important; // Overwriting global button style
          width: auto;
        }

        height: 32px;
        width: 32px !important;

        text-align: center;
        padding: none;

        font-size: 13px;

        i {
          position: relative;
          top: -2px;
          left: -2.5px;
        }

        svg {
          position: relative;
          vertical-align: middle;
          padding-right: 5px;
          left: -4px;
          top: -2px;
          width: 20px;
          height: 20px;
        }
      }
    }
  }

  .inovua-react-toolkit-menu__table {
    tbody {
      tr:last-child() {
        display: none;
      }

      tr:nth-last-child(3) {
        display: none;
      }

      tr:nth-last-child(4) {
        display: none;
      }

      tr:nth-last-child(5) {
        display: none;
      }
    }
  }
}

.smallText {
  font-size: 0.9rem !important;
}

.extraSmallText {
  font-size: 0.7rem !important;
}

.commentContainerStyle {
  margin-top: 1.5%;

  .commentorInternalNameValue {
    font-size: 0.9rem !important;
    margin-bottom: 0px;
    color: $primary-text-color;
    font-weight: 600;
  }

  .commentorExternalNameValue {
    font-size: 0.9rem !important;
    margin-bottom: 0px;
    color: $primary-text-color;
    font-weight: 600;
  }

  .commentorTimeValue {
    font-size: 0.9rem !important;
    margin-bottom: 5px;

    button:hover {
      opacity: 0.6;
    }
  }

  .commentText {
    font-weight: 500;
    color: $primary-text-color;
  }

  .commentValue {
    padding-bottom: 1% !important;
    margin-bottom: 48px;
  }
}

.commentDeleteBtn {
  border: unset;
  margin-left: 0px;
  margin-right: 0px;
  border-radius: 50%;
  padding-top: 5px;
  margin-left: 20px;
  background-color: #fdecec;
  color: #f04541;
  float: right;
}

.verticalAlignCenter {
  display: flex;
  align-self: center;
  vertical-align: middle;
}

.blue {
  color: #1f5785;
}

.bigTextPadding {
  font-size: 14px;
  padding-right: 40%;
  padding-bottom: 5px;
}

.moneySlider {
  padding-left: 20px !important;
  margin-right: 50px !important;
}

.singleIconBtn {
  min-width: 50px !important;
  margin-left: 2px;
  border: none;
  text-align: center;
  margin-bottom: 5px;
}

.clientText {
  color: $primary-text-color;
  font-weight: bold;
}

.backButton {
  svg {
    float: left;
  }

  div {
    color: $primary-text-color;
    font-size: 1rem;
    float: left;
    padding-left: 12px;
  }
}

.validationRed {
  color: #cb5151;
}

.validationFontSize {
  font-size: 12.8px;
}

.redBorder {
  border-width: 1px;
  border-color: #cb5151;
}

//calendy
.EvjIIpBybRxh9ht5Pu7_.NyIQsFUSjWPcSDvt1EvE {
  padding-right: 0% !important;
  padding-left: 0% !important;
}

.EvjIIpBybRxh9ht5Pu7_ {
  display: inline-block !important;
  justify-content: left !important;
}

.EvjIIpBybRxh9ht5Pu7_.nI_zoWhcrj_usnPrutGy {
  margin-top: 30px !important;
  margin-bottom: 30px !important;
}

#page-region {
  float: left !important;
  padding-left: 0% !important;
  padding-right: 0% !important;
}

.formatValidationIcon {
  position: relative;
  z-index: 999;

  div {
    display: inline-block;
  }
}

.rdw-link-modal {
  height: auto !important;
}

.feather {
  width: 24px;
  height: 24px;
  stroke: currentColor;
  stroke-width: 2;
  stroke-linecap: round;
  stroke-linejoin: round;
  fill: none;
}

.highlyRedirect {
  color: $primary-text-color;
  text-decoration: underline;
  font-weight: bold;
  font-size: 13px;
  padding-left: 0px;
  margin-bottom: 0.85vw;

  &:hover {
    text-decoration: none;
    cursor: pointer;
  }
}

.highlyRecommendedCS {
  border-radius: 50%;
  padding: 0.1vw;
  background-color: $blue-spectre;
  color: $white;
  bottom: 0.1vw;
  position: relative;
  font-size: 0.5vw;
}

.hrecommendedBody {
  font-size: 0.65vw;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: $primary-text-color;
}

.hrecommendedSubtitle {
  color: $headingGrey;
  margin-bottom: 0.5rem;
  font-size: 11px;

  i {
    svg {
      width: 16px;
      height: 16px;
    }
  }
}

.informationBox {
  background-color: $informationBox;
  margin-left: 0px;
  margin-right: 0px;
}

.externalLinkPadding {
  margin-right: 0.25vw;
}

.playMargin {
  margin-bottom: 0.15vw;
  margin-right: 0.25vw;
}

.flipIcon {
  -webkit-transform: scaleY(-1);
  transform: scaleY(-1);
}

.mobileVideoStyle {
  height: 260px !important;
  margin-top: 0.8vw;
  margin-bottom: 1.2vw;
}

.htmlBullets {
  li {
    font-size: 13px;
  }

  ul {
    padding-left: 16px;
  }

  h5 {
    font-size: 16px;
    margin-bottom: 0;
  }
}

.searchInTab {
  margin-top: 40px;
  margin-bottom: 16px;
}

.validationText {
  span {
    color: $validationRed;
  }
}

.marginLeft24 {
  margin-left: 24px;
}

.transparentButton {
  background: none !important;
  color: $primary-text-color !important;
  border: none;

  &:hover {
    background: none !important;
    text-decoration: underline;
    cursor: pointer;
  }

  &:disabled {
    opacity: 0.3 !important;
    text-decoration: none;
    cursor: default;
  }
}

.fontSize16 {
  font-size: 16px !important;
}

.ExpandCollapseBtn {
  border: unset;
  margin-left: 0px;
  margin-right: 0px;
  border-radius: 50%;
  padding-top: 5px;
  margin-top: 25px;
  margin-left: 20px;
}

.ExpandHeaderText {
  display: inline-block;
}

.flagRadius {
  canvas {
    border-radius: 2px;
  }
}

.appFooter {
  user-select: none;
  position: fixed;
  margin: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  height: 96px;
  background-color: $form-background-color;
  display: grid;
  padding-left: 256px;
  padding-right: 24px;
  border-top: 1px solid $tabBorder;
  z-index: 9;

  &.miniNav {
    padding-left: 62px;
  }

  &.helpCenterAllowance {
    padding-right: 395px;
  }

}

.appFooterRestrictions {
  margin-bottom: 96px;
}

.gridName {
  input.form-control {
    font-size: 18px;
  }
}

.CustomInputLabel {
  color: $mediumGrey;
  font-size: 13px;
  line-height: 18px;
  padding-bottom: 10px;
  padding-top: 12px;
}

.CustomUpdatedLabel {
  color: mediumGrey;
  font-size: 11px !important;
}

.CustomInputControl textarea {
  background-color: #ffffff;
  padding-bottom: 9px;
  padding-top: 12px;
}

.CustomSaveButton {
  margin-top: 20px;
}

.Tooltip {
  display: inline-block;
}

.negSideMargins24 {
  margin-left: -24px;
  margin-right: -24px;
}

.collapsedButton {
  .neo-grid-expand-column {
    button {
      padding: 0;
      min-width: 32px;
      width: 32px;
      height: 32px;
    }
  }
}

// ICP and add edit TM dropdown styling
.css-6q0nyr-Svg {
  color: $mediumGrey;
  margin: 0px;
  width: 20px;
  height: 20px;
}

.css-xb97g8 {
  color: $mediumGrey;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 2px;

  display: -ms-flexbox;
  display: flex;
  padding-left: 4px;
  padding-right: 4px;
  box-sizing: border-box;

  svg {
    height: 20px;
    width: 20px;
    margin-bottom: 0;
  }

  &:hover {
    background-color: transparent !important;
  }
}

//slider stlying
.rc-slider-mark-text {
  margin-top: 8px;
}

.textColour {
  color: $primary-text-color;
}

.rc-slider-mark-text-active {
  font-weight: bold;
  color: var(--neutral-greys-cold-grey-10);
}

.marginLeft9 {
  margin-left: 9px;
}

.padding4 {
  padding: 4px !important;
}

.calculatingAccountsButton {
  background: none !important;
  padding-left: 0px !important;
}

.customGridButtonPadding {
  padding: 6px 0px 0px 7px !important;
}

.customGridBulkButtonPadding {
  padding: 0px 0px 0px 1px !important;
}

.popupMaintenance {
  .neo-grid-button-column button.btn.btn-light {
    padding: 0;
    width: 32px;
    min-width: 32px;

    svg {
      width: 20px;
      height: 20px;
      margin-bottom: 0px;
    }
  }
}

.userDisplayedIcon {
  margin: auto;
  height: 24px;
  width: 24px;
  background-color: $tertiary-color;
  color: white;
  border-radius: 100%;
  font-size: 12px;
  font-weight: 500;
  display: flex;
  align-items: center;

  .userDisplayedText {
    height: 24px;
    margin: auto;
    text-align: center;
    display: inherit;
    align-items: inherit;
  }
}

.lineSeparator {
  height: 17px;
  width: 2px;
  background-color: $tabBorder;
  top: 45%;
  transform: translateY(-55%);
  bottom: 0;
  margin: auto;
  float: right;
  display: flex;
  align-items: center;
  position: relative;
}

.roundedDropDown {
  width: 12vw;
  float: right;

  select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    padding-right: 36px;
    height: 40px;
  }

  .form-control {
    background-color: $form-background-color;
    border-radius: 100px;
    border: none;
  }
}

.roundedDropDown:after {
  content: '\f078';
  font: normal normal normal 16px/1 FontAwesome;
  color: $primary-text-color;
  right: 11px;
  top: 3px;
  padding: 8px 4px 0px 8px;
  position: absolute;
  pointer-events: none;
}

.invalid-feedback {
  &.error {
    color: $validationRed;
  }
}

.paddingBottom24 {
  padding-bottom: 24px;
}

.pb-96 {
  padding-bottom: 96px;
}

.EditBackButton {
  /* previous_step */

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px;
  gap: 8px;

  width: 48px;
  height: 48px;

  background: $bs-light;
  border-radius: 8px;
  border: none;
  margin: 24px 0px;

  svg {
    margin: 0px;
  }
}

.EditContinueButton {
  width: 200px;
  margin: 4px 0px;
}

.EditLinkButton {
  text-decoration-line: underline;
  color: $tertiary-color;
  font-weight: 500;
  font-size: 14px;
}

.introductionPageIsMultipleSteps {
  // required to overwrite the styled component
  border-radius: 8px 8px 0px 0px !important;
}

.zendeskGridMarginBottom {
  margin-bottom: 42px;
}

.richTextEditor {
  .wrapper {

    .editor {
      border: 1px solid $tabBorder;
      color: $primary-text-color;
    }

  }
}

.separator {
  border-left: 1px solid $bs-light;
  width: 1px;
}

button.btn.btn-secondary.form-submit {
  background: $tertiary-color;
  color: $white;

  &:hover,
  &:active,
  &.active,
  &:focus,
  &:visited,
  &:focus:active {
    background: $tertiary-color;
    color: $white;
    opacity: 0.8;
    box-shadow: none;
    border: $tertiary-color;
  }

  &:disabled {
    opacity: 0.4;
  }
}

.custom-scrollbar-expand {

  textarea,
  .editor.rdw-editor-main {

    // Sets the default width of the scrollbar
    &::-webkit-scrollbar:vertical {
      // Base width of the webkit-scrollbar
      width: 16px;
      background-color: $form-background-color;

      // Just the top right corner.
      border-radius: 0px 8px 0px 0px;
    }

    &::-webkit-scrollbar-track:vertical {
      border-radius: 8px;
      background-clip: content-box;
    }

    &::-webkit-scrollbar-thumb {
      // Reduces the width of the thumb of the scrollbar, cannot use margin or padding for this.
      border: 5px solid transparent;
      background-clip: content-box;
    }

    &::-webkit-resizer {
      // Custom resizer Icon.
      background-image: url('../../assets/img/WebkitResizer.png');
    }

    // Sets the expand icon background to form-background-color
    &::-webkit-scrollbar-corner {
      background: $form-background-color;
    }
  }
}

.visually-hidden{
  display: none;
}