.LeadMessageSideMenu {
    // Styling applied to the menu
    min-width: 88px;
    background: $form-background-color;
    border-right: 1px solid $tabBorder;
    padding: 24px;
    height: calc(100vh - 43px);
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    position: fixed;
    z-index: 1;

    // Stlying applied to Back, Up and Down buttons
    .button {
        border-radius: 8px;
        padding: 8px;
        background-color: $bs-light;
        border: none;
        height: 40px;
        width: 40px;
        transition: background-color 0.2s ease-in-out;
        transition: box-shadow 0.2s ease-in-out;

        &:not(:disabled):hover {
            background-color: $form-background-color;
        }

        &:not(:disabled):active {
            background-color: $darkGrey;
            border-color: $activeBorderGrey;
            box-shadow: 0 0 0 0.2rem rgba(206, 208, 208, 0.5);
        }
    }

    // Styling applied to the separator 
    .line {
        width: 39px;
        height: 1px;
        background: $tabBorder;
    }

    // Styling applied to the unseen count
    .unseenCount {
        position: relative;
        top: -42px;
        right: -24px;
        background-color: $validation-shadow-error-color;
        color: $white;
        border-radius: 50%;
        font-size: 9px;
        width: 14px;
        height: 14px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-top: 1px;
    }

    // Styling applied to the unseen count when > 99
    .unseenCount.plus {
        min-width: 23px;
    }

    // Styling applied to the unseen count notification when all items have been seen (unseenCount < 1)
    .allSeen {
        position: relative;
        top: -42px;
        right: -24px;
        background-color: $bs-success;
        color: $white;
        border-radius: 50%;
        font-size: 9px;
        width: 14px;
        height: 14px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-top: 3px;

        svg {
            margin-bottom: 3px;
            padding: 3px;
            stroke-width: 4px;
        }
    }
}