.OnboardingMaintenanceView {
    .btn-light {
        padding-left: 0px !important;
        padding-right: 0px !important;
    }

    .foundersURL {
        padding-left: 26px;
        padding-top: 15px;
        font-size: 13px;
    }

    .input-group {
        flex-wrap: nowrap;
    }
}