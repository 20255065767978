.leadMessageView {
    padding-left: 125px;
    padding-right: 406px;
    padding-top: 27px;
    padding-bottom: 120px;

    .modifiedDate {
        text-align: right;
        font-size: 14px;
    }

    .infoYellowPanel {
        background-color: $onBoardingCampaignMessageBorder;
        border-radius: 8px;
        padding: 16px;
        align-items: center;
        color: $sidemenu-inactive-text;
    }

    .roundedDropDown.form-group {
        width: 100% !important;
        padding: 0px;
    }

    .white {
        background-color: $white;
    }

    .initialsButton {
        border-radius: 100px;
        width: 32px;
        height: 32px;
        background-color: $onboardingMenuActive;
        text-align: center;
        color: $white;
        font-size: 12px;
        align-items: center;
        justify-content: center;
        display: inline-flex;
    }

    .learnMoreButton {
        border-color: $onboardingMenuActive;
        border-radius: 100px;
        border-style: solid;
        border-width: 2px;
        color: $onboardingMenuActive;
        background-color: transparent;
    }

    .closeButton {
        &:hover {
            cursor: pointer;
        }
    }

    .alignCenter {
        align-items: center;
    }

    .messageBodyRow {
        padding: 16px;
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
        background-color: $white;
    }

    .emailNamePill {
        border-radius: 100px;
        align-items: center;
        padding: 4px 8px;
        background-color: $pillBackground;
        border-color: transparent;
        font-size: 14px;
        color: $sidemenu-inactive-text;

        &.recipient {
            color: $recipientBlue;
            background-color: $form-background-color;
        }
    }

    .alignCenter {
        align-items: center;
    }

    .textAlignRight {
        text-align: right;
    }

    .textAlignLeft {
        text-align: left;
    }

    p {
        font-size: 16px;
    }

    .greyPanel {

        border-radius: 8px;
        padding: 8px 16px;
        align-items: center;
        color: $onboardingMenuActive;
        background-color: $panelHover;

        &.messagePanel {
            background-color: $border-bottom-table-color;
            border-bottom-left-radius: 0px;
            border-bottom-right-radius: 0px;
        }
    }

    .prospectNameHeader {
        font-size: 24px;
        font-weight: 500;
        padding-top: 0.5%;
        padding-left: 0px;
        align-self: center;
    }

    .archiveButton {
        width: 81%;
        height: 52px !important;
        border-radius: 30px;
        width: 112%;
        width: 124px;
        float: right;
    }

    .statusDropdown {
        float: right;
        min-width: 224px;
        margin: 0px 16px;
    }

    .leadmessage-tooltip {
        font-size: 12px;
        width: 96px;
        text-align: center;
        transform: translate(-50%, -130%);
    }
}