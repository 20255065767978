.UpdateTargetMarketsView{
  color: $primary-text-color;

  div{
    &.neo-tab-container{
        div{
          &.col-12{
            padding: 0;
          }
        }
    }
  }

  .cardStyling{
    width: 100%;
  }
}