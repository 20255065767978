.technicalSettingsContainer {
  padding-bottom: 96px;
}

.AdvancedEmailSettings {
  &.modal-dialog {
    margin: 1.5rem auto;
    max-width: 760px;
  }

  .testEmailInfoTextContainer {
    width: 100%;
  }

  .testEmailsContainer {
    display: grid;
    grid-template-columns: auto 48px;
    grid-gap: 10px;
    width: 100%;

    .testInputBoxStyling {
      .form-control {
        border-radius: 8px;
        height: 48px;
      }
    }
  }

  .testEmailLabel {
    margin-bottom: 8px;
  }

  .hideSubmitButton {
    display: none;
  }

  .sectionSegregator {
    border-top: 1px solid $bs-light;
    margin-left: 24px;
    margin-right: 24px;
  }

  .form-group div.custom-control.custom-checkbox input.custom-control-input:checked~.custom-control-label::before {
    background: #002648;
    border: 2px solid #002648;
    border-radius: 4px;
  }

  .customSVG {
    path {
      stroke: rgb(0, 38, 72);
    }
  }

  .modal-header {
    padding: 40px 40px 24px 40px;

    .close {
      color: $svgDefaultColour;

      span {
        color: $svgDefaultColour;
      }

      font-size: 2rem;
      bottom: 0;
      right: 0;
    }
  }

  .modal-title {
    font-size: 24px;
    float: left;
  }

  .modal-body {
    padding: 0px 40px 40px 40px;

    .form-group {
      label {
        font-weight: 600;
        color: $sidemenu-inactive-text;
        margin-bottom: 8px;
      }

      input,
      .input-group {
        border-radius: 8px;
        height: 48px;
      }
    }

    .dropdownLabel {
      font-weight: 600;
      color: $sidemenu-inactive-text;
      margin-bottom: 8px;
    }

    .dropDownStyle {
      width: 100%;
    }

    .dropdownDefault {
      color: $mediumGrey;
    }

    .saveButton {
      font-size: 18px;
    }

    .passwordBox {
      border: none;
      background: $form-background-color;
      text-align: center;
      padding: 10px 16px 10px 16px;
      border-radius: 8px;

      svg {
        margin-bottom: 0px;
      }
    }
  }

  .modal-content {
    border-radius: 16px;
  }

  .quickAddParagraphBorder {
    border-bottom: solid 2px $bs-light;
  }

  .plusButton {
    border-radius: 8px;
    width: 48px;
    height: 48px;
    padding: 0px 0px !important;
  }

  form .form-control {
    border-radius: 8px;
    font-size: 16px;
  }

  .errorMessage {
    color: $red;
    font-size: 16px;
  }

  .selectedItemsContainer {
    max-height: 50vh;
    overflow-y: auto;
  }

  .modal-footer {
    display: none;
  }

  .modal-button {
    margin-bottom: -16px;
  }

  .invalid-feedback.error {
    color: $validationRed;
  }

  .pageManagement {
    padding-top: 10px;
    padding-bottom: 2px;
    padding-left: 24px;
  }

  .addMargin {
    margin-right: 6px;
  }

  .IconQuestions {
    width: 4vw;
    height: 4vw;
    padding: 0.4vw;
  }

  .emailTitle {
    margin-top: 2vw;

    label {
      font-size: 16px;
      font-weight: 400;
      color: $mediumGrey;
    }
  }

  .borderRadius {
    border-radius: 0%;
  }

  .itemInfoFirewall {
    font-weight: bold;
    font-size: 16px;
    padding-bottom: 1vw;
  }

  .itemInfo {
    font-weight: bold;
    font-size: 16px;
    padding-bottom: 1vw;
    padding-top: 2vw;
  }

  .form-group {
    div.custom-control {
      &.custom-checkbox {
        input.custom-control-input:checked~.custom-control-label::before {
          background: #002648;
          border: 2px solid #002648;
          border-radius: 4px;
          width: 20px;
          height: 20px;
        }

        label.custom-control-label::before {
          border-radius: 4px;
          border: 1px solid #002648;
          width: 20px;
          height: 20px;
        }

        label.custom-control-label::after {
          width: 20px;
          height: 20px;
        }

        label.custom-control-label {
          padding: 5px;
          margin: 4px 0px;
        }
      }


    }
  }

  table.neo-grid {
    .neo-grid-button-column {
      text-align: left;
    }

    &.table-bordered {
      border: none;
    }
  }

  .neo-grid-button-column {
    padding-right: 24px;

    button.btn {
      min-width: 0px;
      padding: 0px;
      width: 72px;
      height: 32px;
    }
  }

  .passwordShow {
    .input-group-append {
      .btn-outline-primary {
        background-color: transparent;
        color: $secondary-text-color;

        &:hover,
        &:active,
        &:focus {
          background-color: transparent;
          text-shadow: none;
          box-shadow: none;
        }

        border-color: transparent;

        svg {
          width: 14px;
          height: 14px;
        }
      }
    }
  }

  .appendIcons {
    padding: 10px 16px 0px 16px;
  }
}