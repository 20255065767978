.TabHeaderBadges {
  .hoverText {
    display: block;
  }

  &:hover {
    .hoverText {
      display: block;
      width: 20%;
      position: absolute;
      background-color: black;
      z-index: 9999;
      top: -30px;
    }
  }

  margin: auto;

  .tabNumbers {
    color: $secondary-text-color;
  }
}

// data grid styling for the headers
.InovuaReactDataGrid__header.InovuaReactDataGrid__header--direction-ltr {
  color: $secondary-text-color;
  font-size: 11px;
}

// data grid styling for the records
.InovuaReactDataGrid__virtual-list.inovua-react-virtual-list {
  color: $primary-text-color;
  font-size: 13px;
}

// Dash CheckBox
.inovua-react-toolkit-checkbox.inovua-react-toolkit-checkbox--theme-default-light.inovua-react-toolkit-checkbox--indeterminate
  .inovua-react-toolkit-checkbox__icon-wrapper
  svg {
  border-radius: 2px;
  background: #898383 !important;
}

// Check CheckBox
.inovua-react-toolkit-checkbox.inovua-react-toolkit-checkbox--theme-default-light.inovua-react-toolkit-checkbox--checked
  .inovua-react-toolkit-checkbox__icon-wrapper {
  fill: #898383 !important;
  stroke: #e8e8e8 !important;
}

// Selected Item background
.InovuaReactDataGrid--theme-default-light
  .InovuaReactDataGrid__row--no-zebra.InovuaReactDataGrid__row--selected {
  background-color: $emailModal !important;
}

.targetMarketGrid {
  .accountSelectTax {
    font-size: 14px;
  }

  .nav-item {
    a {
      background-color: $innerCardBackground;

      &.nav-link {
        min-width: 100% !important;
        min-height: 30px;
      }

      &.active {
        font-weight: bold;
      }
    }
  }
}
