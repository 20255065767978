.UserGroupsView {
    .neo-tab-container {
        .col-12 {
            padding-left: 24px;
            padding-right: 24px;
        }
    }

    button.btn.btn-light.circled {
        svg {
            margin-right: 10px;
            margin-left: -7px;
            margin-bottom: 11px;
        }
    }

    .btn-secondary.disabled,
    .btn-secondary:disabled {
        margin-top: 38px;
        float: right;
    }

    span.d-lg-inline {
        color: $white !important;
    }

    .btn-info {
        color: $white !important;
    }

    .marginTop31 {
        margin-top: 31px;
    }

    .manage-user-group .tab-content {
        padding: 0px 0px 0px 0px;
    }

    @media (min-width: 992px) {
        .d-lg-inline {
            color: $white !important;
        }
    }

    th.neo-grid-button-column {
        padding-right: 24px;
    }
}