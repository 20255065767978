.BlacklistedDomain {

  .buttonWidthBlacklist {
    min-width: 105px;
    width: auto;
  }

  .customCheckPadding {
    padding-left: 8px;
  }

  .tab-pane {
    padding: 5px 0px 5px 0px;
  }

  .InovuaReactDataGrid__column-header__content.InovuaReactDataGrid__box--ellipsis:last-child {
    text-align: center;
  }
}

.cardPadding {
  padding: 24px;
}

.extraLightButton{
  button{
    background-color: $search-background;

    &:not(.disabled):hover{
      background-color: $bs-light;
    }
  }
}

// Negate un-needed margins built into StyledButton
.negativeMargins {
  margin-top: -16px !important; 
  margin-bottom: -16px !important;
}

.bulkDelete {
  margin-top: -24px;
  margin-bottom: -24px;
  margin-left: -9px;

  svg{
    margin-bottom: 12px;
  }
}