.OidcLoginRedirect {
    .ModalOverlay {
        position: absolute;
        top: 0% !important;
        left: 0px !important;
    }
    .spinner-border {
        position: absolute;
        left: 50%;
        top: 45%;
        color:$secondary-color;
    }
}
