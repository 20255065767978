.form-control {
    border-radius: 0;
    font-size: 16px;
    background-color: $emailModal;
    border: none;
    height: 48px;

    &[readonly] {
        background-color: $emailModal;
    }
}

.input-group {
    background-color: $emailModal;
}

.form-group {
    div.custom-control {
        &.custom-checkbox {
            input.custom-control-input:checked~.custom-control-label::before {
                background: $tertiary-color;
                border: 2px solid $tertiary-color;
                border-radius: 4px;
            }

            label.custom-control-label {
                &::before {
                    border-radius: 4px;
                    border: 1px solid $tertiary-color;
                }
            }
        }
    }
}

.input-group-prepend .btn,
.input-group-append .btn {
    position: relative;
    z-index: 2;
    min-width: 39px;
    background: $emailModal ;
}

label {
    font-size: 16px;
    font-weight: 400;
}

label.text-secondary {
    font-size: 15px;
    font-weight: 600;
}

div.text-secondary {
    font-size: 14px;
}

.form-group {
    margin-bottom: 5px;

    input.form-control {
        &::-webkit-input-placeholder {
            color: $secondary-text-color;
        }

        &::placeholder {
            color: $secondary-text-color;
            font-size: 16px;
        }

        &::-moz-placeholder {
            color: $secondary-text-color;
        }
    }
}

.input-group>.input-group-append>.btn {
    border-radius: 50%;
}

.CampaignMessageText label {
    font-size: 13px;
    font-weight: 400;
    color: $mediumGrey;
}

.filterDropDown:after {
    content: '\f078';
    font: normal normal normal 16px/1 FontAwesome;
    color: $primary-text-color;
    right: 11px;
    top: 3px;
    padding: 8px 4px 0px 8px;
    position: absolute;
    pointer-events: none;
}

.dropdownPositionRelative {
    position: relative;
    z-index: 3;
}

.filterDropDown {
    width: 12vw;
    float: right;

    select {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        padding-right: 36px;
        border-radius: 20px;
        height: 40px;
    }

    .form-control {
        background-color: $form-background-color;
        border: none;
    }
}