.newEmail {
    &.showAdditionalEmails {
        &:not(.maximize):not(.minimized) {
            height: 525px;
            bottom: 96px;

            .emailBody {
                min-height: 485px;
                max-height: 485px;
            }

            #emailContent {
                min-height: 238px;
                max-height: 260px;
            }
        }
    }

    &.maximize {
        top: 48px;

        .emailBody {
            min-height: 353px;
            height: calc(100vh - 88px - 96px);
            max-height: calc(100vh - 88px - 96px);
            overflow: hidden;

            #emailContent {
                max-height: calc(100vh - 400px);
                min-height: calc(100vh - 400px);
            }
        }
    }

    .row {
        margin: 0;
    }

    margin: 0px;
    position: fixed;
    bottom: 96px;
    left: 89px;
    right: 370px;
    height: 396px;
    z-index: 2;
    background: $form-background-color;
    width: auto;

    input {
        padding: 0;
    }

    .validationError {
        font-size: 12px;
        font-weight: normal;
        font-family: 'Work Sans';
        color: $validationRed;
    }

    .email-input {
        width: auto;
        max-width: 100%;

        svg {
            margin: 0px;
        }

        button {
            border: none;
            padding-right: 0;
            padding-left: 12px;
            background-color: inherit;
        }
    }

    .email-input-form {
        flex: 1;
        min-width: 50px;
    }

    .emailHeader {
        height: 40px;
        background: #374242;
        color: #FFFFFF;
        width: 100%;
        font-size: 16px;
        padding: 5px 16px;

        .minimize {
            margin-top: 10px;
        }
    }

    &.minimized {
        height: 0;
        bottom: 136px;
    }

    .emailBody {
        min-height: 353px;
        max-height: 353px;
        background-color: $white;
        padding: 0px 16px;
        display: block;
        width: inherit;

        .characterCount {
            position: fixed;
            bottom: 96px;
            left: 0;
            right: 0;
            padding-right: 24px;
            padding-left: 90px;
        }

        .invalid {
            color: $validationRed;
        }

        #emailContent {
            border: none !important;
            box-shadow: none;
            max-height: 220px;
            min-height: 193px;
            scroll-behavior: auto;
            overflow: scroll;
            overflow-x: auto;
            overflow-y: auto;
            border-color: none;
            resize: none;
            outline: none;
            padding-top: 8px;
            width: 100%;
        }

        .wrapper.rdw-editor-wrapper {
            position: relative;
        }

        .email-pill {
            font-style: normal;
            font-weight: 400;
            background-color: $emailPill-background-colour;
            width: fit-content;
            padding: 0px 8px;
            border-radius: 100px;
            margin: 0px 2px;
        }

        .additionRecipients {
            .email-pill {
                background-color: $form-background-color;
            }
        }
    }

    .emailMessage {
        display: flex;
        align-items: center;
        height: 48px;
        overflow: auto;
        margin: 2px 0px;

        &.emailSubject {
            border-top: 2px solid $form-background-color;
            border-bottom: 2px solid $form-background-color;

            #subject {
                min-width: 90%;
            }
        }

        padding: 5px 0;
        font-weight: bold;
        font-size: 14px;
        line-height: 20px;

        input,
        form {
            outline: none;
            font-weight: normal;
            display: inline;
            border: none;
            width: 250px;
            min-width: auto;
        }


        a {
            font-weight: normal;
            padding-left: 8px;
        }
    }

    .emailFrom {
        border-bottom: 2px solid $form-background-color;

        .emailFromDropdown {
            width: 30%;
            font-size: 14px;
        }

        .emailFromText {
            font-size: 14px;
            margin-right: 7px;
        }
    }
}