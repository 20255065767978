@import url(https://fonts.googleapis.com/css?family=Ubuntu+Mono|Roboto:400italic,700italic,400,700);
@import url(https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,400;0,700;0,900;1,400;1,700;1,900&display=swap);
@import url(https://fonts.cdnfonts.com/css/gt-walsheim);
@import url(https://fonts.googleapis.com/css2?family=Work+Sans&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Work+Sans:wght@100;200;300;400;500);

@font-face {
  font-family: "GT-Walsheim-LC-Medium";
  src: local("GT-Walsheim-LC-Medium"),
    url(../assets/fonts/GT-Walsheim-LC-Medium.woff) format("woff");
}

@font-face {
  font-family: "GT-Walsheim-LC-Light";
  src: local("GT-Walsheim-LC-Light"),
    url(../assets/fonts/GT-Walsheim-LC-Light.woff) format("woff");
}

@font-face {
  font-family: "Lato-Regular";
  src: local(Lato-Regular),
    url(../assets/fonts/Lato-Regular.woff2) format("woff2"),
    url(../assets/fonts/Lato-Regular.woff) format("woff"),
    url(../assets/fonts/Lato-Regular.ttf) format("truetype");
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.63;
  letter-spacing: normal;
  color: $primary-text-color;
}

@font-face {
  font-family: "Lato-Bold";
  src: local(Lato-Bold), url(../assets/fonts/Lato-Bold.woff2) format("woff2"),
    url(../assets/fonts/Lato-Bold.woff) format("woff"),
    url(../assets/fonts/Lato-Bold.ttf) format("truetype");
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
}

svg {
  margin-bottom: 5px;
}

.primary-text-color {
  color: $primary-text-color;
}

.text-info {
  color: $bs-info;
}

.text-primary {
  color: $bs-primary;
}

.text-danger {
  color: $bs-danger;
}

.text-default {
  color: $darkSilver;
}

.text-spectre {
  color: $yellow-spectre;
}

.text-yellow {
  color: $onboarding-disabled-color;
}

.text-success {
  color: $progress-green;
}

.bg-primary {
  background-color: $bs-secondary !important; //overwriting NEO
}

.bg-primary.progress-bar.progress-bar-striped {
  background-color: $bs-secondary;
}

.dropdown-selected-value {
  .statusCircle {
    display: inline-block;
  }

  .selectedText {
    top: -2px;
    position: relative;
  }
}

.sc-gLDzan.OgkIO {
  padding: 0px 8px;
}

.statusCircle {
  width: 16px;
  height: 16px;
  border-radius: 100px;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin-top: 4px;
  margin-right: 12px;

  // Colors have not been confirmed when it comes to the statuses so not adding to the variables for now.
  &.status1 {
    background: #94C446;
  }

  &.status2 {
    background: #EF7E28;
    ;
  }

  &.status3 {
    background: #266D3A;
  }

  &.status4 {
    background: #7FA4A5;
  }
}

.progress-bar-striped {
  background-image: none;
  background-size: none;
}

// Font Variables
$roboto: "Roboto", sans-serif;
$lato: "Lato-Regular";
$lato-bold: "Lato-Bold";
$GTWalsheimMedium: "GT-Walsheim-LC-Medium";
$GTWalsheimLight: "GT-Walsheim-LC-Light";

// GeneralVariables
$menu-width: 256px;
$header-height: 48px;
$footer-height: 50px;
$footer-gap: 50px;
$padding-top: 39px;
$margin-top-md: 39px;
$margin-top: 63px;
$btn-radius: 20px;
$page-height: $header-height + $footer-height + $footer-gap;
$footer-height-app: 40px;

//base fonts

h1,
h2,
h3,
h4,
h5,
h6 {
  color: $primary-text-color;
  font-family: $lato-bold;
}

h4 {
  padding-top: 1.6vw;
}

h3 {
  padding-top: 2vw;
  margin: 0;
}

h2 {
  font-size: 32px;
}

div {
  font-family: $lato;
}

p {
  font-family: $lato;
  font-size: 14px;
}

.app-right-panel {

  background: $form-background-color;

  &.greyBackground {
    background: $form-background-color;
  }

  padding-left: 256px;

  input.clickableCursor {
    cursor: pointer !important;
  }

  div.clickableCursor {
    cursor: pointer !important;
  }

  a.noUnderlineHover {
    color: unset;

    &:hover {
      text-decoration: none !important;
    }
  }
}

.root {
  font-size: 13px;
}

.closeIcon {
  font-size: 15px;
}

button.btn {
  border-radius: $btn-radius;
  height: 40px;
  font-family: $lato;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.63;
  letter-spacing: normal;
  font-size: 16px;

  svg {
    width: 24px;
    height: 24px;
  }
}

div.tabs-header {
  font-size: 16px;
  font-family: $lato;
}

table.table td {
  font-size: 13px;

  button.btn {
    font-size: 13px;
  }
}

table.table th {
  font-size: 11px;
}

.searchbox.form-group input::placeholder {
  font-size: 16px;
}

table.table>thead th {
  font-size: 11px;
}

.validationFontSize {
  font-size: 16px;
  padding-top: 10px;
}

//base fonts

div.container-xl {
  max-width: 1160px;
  padding: 0px 15px;
  margin: 0px -15px;
}

.app-container {
  background: $form-background-color;
  margin-bottom: -24px;

  &.dashboardView {
    background: $form-background-color;

    .app-content-area {
      background-color: $form-background-color;
    }
  }

  &.mini-nav.dashboardView {
    background: $form-background-color;

    .app-content-area {
      background-color: $form-background-color;
    }
  }
}

.app-left-panel {
  position: fixed;
  width: $menu-width;
  z-index: 10;
  height: 100%;
  top: $header-height !important;
  display: block;

  &.show {
    display: block;
    top: $header-height;
    position: fixed;
    top: 49px;
    left: 0px !important;
  }
}

.refer-us-header {
  font-family: $roboto;
  float: right;
  font-size: 13px;
  padding: 15px 10px;
  color: $primary-text-color !important;
  text-decoration: none;
  font-weight: 500;
  cursor: pointer;
}

.app-header-panel {
  position: absolute;
  z-index: 1001;
  width: 100%;
  max-width: 100%;
  padding-left: 0;
  padding-right: 0;

  .page-progress {
    position: fixed;
    top: 0px;
    left: 0;
    width: 100%;
    border-radius: 0;
    height: 8px;
  }

  &.pinned {
    position: fixed;
    z-index: 1001;

    .page-progress {
      top: 52px;
      left: 0;
      width: 100%;
    }

    &.scrolled {
      box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.14);
    }
  }

  .app-header {
    height: $header-height;
    background: $emailModal;
    color: $black;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.14);

    .fa.fa-user-circle {
      color: $secondary-text-color;
    }

    .fa.fa-user {
      color: $secondary-text-color;
    }
  }

  .fa-bars {
    cursor: pointer;

    .fa-sm {
      -webkit-transition: 0.5s ease-in-out;
      -moz-transition: 0.5s ease-in-out;
      -o-transition: 0.5s ease-in-out;
      transition: 0.5s ease-in-out;
      cursor: pointer;
    }
  }

  .fa-bars {
    -webkit-transition: 0.5s ease-in-out;
    -moz-transition: 0.5s ease-in-out;
    -o-transition: 0.5s ease-in-out;
    transition: 0.5s ease-in-out;
    cursor: pointer;
  }

  .app-logo-header-container {
    padding: 14px 1px;
    width: 112px;
  }

  .app-hamburger-container {
    margin-right: 15px;
    height: $header-height;
    width: $header-height;
    font-size: 18px;
    align-items: center;
    justify-content: center;
    display: flex;
    float: left;
    background: none;
    color: $primary-text-color;
    width: 62px;
    cursor: pointer;
  }

  .app-breadcrumb {
    float: left;
    padding: 17px 0px;
    font-weight: 400;
    font-size: 14px;
  }

  .app-user-icon {
    float: right;
    font-size: 20px;
    padding: 9px 24px;
    color: $primary-text-color;
    height: 48px;
    cursor: pointer;

    &:hover .app-user-card {
      display: inline-block;
      opacity: 1;
      visibility: visible;
    }

    .fa.fa-user {
      color: $primary-text-color;
    }

    .app-user-card {
      text-align: right;
      display: inline-block;
      min-width: 250px;
      position: fixed;
      top: $header-height;
      right: 10px;
      z-index: 10;
      font-size: 1rem;
      opacity: 0;
      visibility: hidden;
      transition: opacity ease 0.5s;

      .card-arrow {
        border-width: 0 10px 10px 10px;
        border-color: transparent transparent $bs-dark transparent;
        border-style: solid;
        position: absolute;
        width: 200px;
        top: -10px;
        right: -10px;
        z-index: 3;
      }

      a {
        color: $bs-dark;
      }
    }
  }

  .app-header-thumb {
    position: fixed;
    top: 10px;
    right: 5px;
    padding: 2px 5px;
    border: 1px solid transparent;
    background-color: $white;

    &:hover {
      border: 1px solid $lightGrey;
    }
  }
}

div.bread-crumb {
  display: inline;
  color: $color-text;

  a {
    color: $darkSilver;
    text-decoration: none !important;

    &:visited {
      color: $darkSilver;
      text-decoration: none;
    }

    &:hover {
      opacity: 0.8;
      text-decoration: underline !important;
    }
  }

  div.bread-crumb-level {
    display: inline;

    span.bread-crumb-seperator {
      padding: 0 10px;
    }
  }
}

.app-right-panel {
  margin-left: 0px;
  padding-top: $padding-top;

  .pull-right {
    float: right;
  }

  .pull-left {
    float: left;
  }

  .pull-right2 {
    float: right;
    margin-right: 30px;
  }

  .customMarginTop {
    margin-top: $margin-top-md;
  }

  .onboarding-content-shift {
    margin-left: 100px;
  }

  .app-content-area {
    min-height: calc(100vh - #{$footer-height-app});
    color: $color-text;
    padding-top: 10px;

    .dashboardEmailRowContainer {
      margin-top: -4px;
    }
  }

  .loader-container {
    overflow: auto;
  }
}

.highcharts-legend {
  font-family: $roboto;
}

.highcharts-title {
  font-family: $roboto;
}

.app-footer {
  padding: 10px 13px;
  height: $footer-height;
  text-align: center;
  margin-top: $margin-top;
  color: $color-text;
  background: $color-footer;
}

div.toast-container {
  position: fixed;
  right: 0px;
  top: $header-height;
  padding: 10px;
  backdrop-filter: blur(3px);
  border-radius: 5px;
}

div.responsive-hidden {
  margin-top: -19px;
}

.container-fluid {
  margin-left: 0;
  padding-left: 0px;
  padding-right: 0px;
  padding-top: 15px;
}

.p-24 {
  padding-left: 24px;
  padding-right: 24px;
}

button.btn {
  border-radius: $btn-radius;
  height: 40px;
  font-family: $lato;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.63;
  letter-spacing: normal;
  font-size: 16px;
}

.pr-24 {
  padding-right: 24px;
}

.pl-24 {
  padding-left: 24px;
}

.m-24 {
  margin-left: 24px;
  margin-right: 24px;
}

.mr-24 {
  margin-right: 24px;
}

.ml-24 {
  margin-left: 24px;
}

.p-t-32 {
  padding-top: 32px;
}

// Small devices ( phones, 375px and up to larger mobiles)
//Here the menu will display full - with an faded background
//as you click the fadded background the FULL menu Disappear
@media (min-width: 375px) and (max-width: 768px) {
  .app-header-panel {
    z-index: 205;

    .app-header {
      position: fixed;
      width: 100%;

      .refer-us-header {
        font-size: 9px;
        padding: 5px;
        display: none;
      }
    }
  }

  //Hidden Start
  .responsiveOverlay {
    visibility: hidden;
    opacity: 0;
    transition: visibility 1s linear 1s, opacity 1s;

    &.show {
      bottom: 0px;
      visibility: visible;
      opacity: 1;
      left: 0px;
      position: fixed;
      right: 0px;
      top: 0px;
      z-index: 195;
      background: rgba(9, 30, 66, 0.54);
      transition: visibility 0s linear 0s, opacity 1s;

      display: none;
    }
  }

  div.loader-container.page-loader {
    >div.loader-overlay {
      left: $menu-width;
    }
  }

  .app-left-panel {
    transition: margin 0s;
    left: 0 !important; //overidding inline style

    div.responsive-hidden {
      display: inline-block;
    }

    //Hidden end
    //Full Start
    &.show {
      z-index: 205;
      margin-left: 0px;
      -webkit-transition: margin 0s;
      /* For Safari 3.1 to 6.0 */
      transition: margin 0s;

      //this is here, as its relative to the app-left-panel
      .sidebar img {
        width: 50%;
        margin-top: 10px;
      }

      div.responsive-hidden {
        display: contents;
      }

      //this is here as its relative to the app-left-panel
      .sidebar .sidebar-content ul li .menu-item .fa {
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.63;
      }
    }
  }

  .app-right-panel {
    padding-left: 0px;

    .customMarginTop {
      margin-top: 0px;
      margin-bottom: 20px;
    }

    .onboarding-content-shift {
      margin-left: 100px;
    }
  }

  .app-breadcrumb {
    position: relative;
    left: -20px;
    bottom: 0px;
    font-size: 12px !important;
  }
}

//Full end

//media for Tablet up to tablet and laptop
@media (min-width: 769px) and (max-width: 1360px) {

  // This is reversed for in tablet mode.
  //While the class is mini-nav, it will default to the mini-nav, but not have the class
  //therefore adding mini-nav, will make it show.
  //Collapsed Start
  div.mini-nav .app-right-panel {
    padding-left: 256px;
    // transition: all 1000ms ease;
  }

  div.mini-nav .app-left-panel {
    display: block;
    top: $header-height;
    width: 256px;

    // this is here, as its relative to the app-left-panel
    .sidebar img {
      width: 50%;
    }

    .sidebar-header {
      margin-top: 0px;
    }

    div.responsive-hidden {
      display: inline-block;
      visibility: visible;
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.63;
    }

    //this is here as its relative to the app-left-panel
    .sidebar .sidebar-content ul li .menu-item .fa {
      font-size: 16px;
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.63;
    }

    .sidebar .sidebar-content ul li .menu-item .fa .app-container.mini-nav {
      color: $white;
      position: relative;
      left: 95%;
    }
  }

  //Collapsed end

  // Display Full  start
  div.app-right-panel {
    padding-left: 62px;

    .customMarginTop {
      margin-top: 0px;
      margin-bottom: 20px;
    }
  }

  .app-left-panel {
    width: 62px;
    // -webkit-transition: width 0.5s; /* For Safari 3.1 to 6.0 */ leaving these here - due to us doing rnd on this in the next sprint to get it working
    // transition: width 0.5s;

    //this is here as its relative to the app-left-panel
    .sidebar img {
      width: 0px;
      padding: 0px;
    }

    div.responsive-hidden {
      visibility: hidden;
      display: none;
    }

    //this is here as its relative to the app-left-panel
    .sidebar .sidebar-content ul li .menu-item .fa {
      font-size: 18px;
    }
  }

  .app-breadcrumb {
    position: relative;
    bottom: 0px;
    font-size: 12px !important;
  }

  .app-header {
    position: fixed;
    width: 100%;
  }

  .refer-us-header {
    font-size: 12px !important;
  }
}

.cursorPointer {
  cursor: pointer;
}

// Display Full End

//media for Laptop and secondary screens or larger
// Smaller than 1360px - menu will be icons with option to make bigger on burger icon
@media (min-width: 1361px) {

  //Collapsed Start
  div.loader-container.page-loader {
    >div.loader-overlay {
      left: 0;
    }
  }

  div.mini-nav .app-right-panel {
    padding-left: 62px;
    transition: all 1000ms ease;
  }

  .app-left-panel {
    width: 62px;
    // -webkit-transition: width 0s; /* For Safari 3.1 to 6.0 */ leaving these here - due to us doing rnd on this in the next sprint to get it working
    // transition: width 0s;

    div.responsive-hidden {
      visibility: hidden;
      margin-top: -19px;
      display: none;
    }

    //this is here, as its relative to the app-left-panel
    .sidebar img {
      width: 0%;
      margin-top: -15px;
    }

    //this is here as its relative to the app-left-panel
    .sidebar .sidebar-content ul li .menu-item .fa {
      font-size: 18px;
    }

    div>div:nth-child(1)>ul>li:nth-child(5)>div>a {
      height: 56px;
    }

    //Collapsed End

    //Full Start
    &.show {
      width: 256px;

      //this is here, as its relative to the app-left-panel
      .sidebar img {
        width: 50%;
        margin-top: 15px;
      }

      div.responsive-hidden {
        display: inline-block;
        visibility: visible;
      }

      //this is here as its relative to the app-left-panel
      .sidebar .sidebar-content ul li .menu-item .fa {
        font-size: 14px;
      }

      ul.menu-level-1 div.menu-item {
        font-size: 16px;
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.63;
      }
    }
  }

  .app-right-panel {
    padding-left: 256px;
  }

  .fa.fa-search {
    color: white;
  }

  .-append button.btn.btn-info {
    min-width: 45px;
    width: auto;
  }

  .input-group-pre-pend button.btn.btn-info {
    min-width: 45px;
    width: auto;
  }

  td.neo-grid-button-column>button.btn.btn-danger {
    min-width: 40px;
    width: auto;
  }

  .input-group>.form-control,
  .input-group>.form-control-plaintext,
  .input-group>.custom-select,
  .input-group>.custom-file {
    position: relative;
    flex: 1 1 auto;
    margin-bottom: 0;
    width: auto;
    height: 40px;
  }

  .form-control {
    font-size: 14px;
    font-weight: 400;
    line-height: 1.5;
    color: $color-text;
    border-radius: 1px;

    &:focus {
      outline: $secondary-text-color;
      box-shadow: none;
      border: 1px solid $secondary-text-color;
    }
  }

  //Tab
}

@media (min-width: 375px) and (max-width: 575px) {
  .app-header-panel {
    .app-header {
      .app-breadcrumb {
        position: relative;
        bottom: -5px;
        display: none;
      }

      .refer-us-header {
        display: none;
      }
    }
  }
}

@media (min-width: 375px) and (max-width: 575px) {
  .app-header-panel {
    .app-header {
      .refer-us-header {
        position: relative;
        bottom: -5px;
        display: none;
      }
    }
  }
}

@media (min-width: 576px) and (max-width: 768px) {
  .app-header-panel {
    .app-header {
      .refer-us-header {
        position: relative;
        bottom: -15px;
      }
    }
  }
}

@media (min-width: 769px) and (max-width: 1690px) {
  .app-header-panel .app-breadcrumb {
    margin-left: 35px;
  }
}

//Full End
@include media-breakpoint-down(xl) {
  .app-header-thumb {
    display: none;
  }

  div.responsive-hidden {
    opacity: 1;
    transition: ease-in-out visibility 1s linear 1s, opacity 1s;
  }

  td.neo-grid-button-column>button.btn.btn-danger {
    min-width: 40px;
    width: auto;
  }

  h2 {
    font-size: 21px;
  }
}