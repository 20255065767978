.margin {
  margin-top: 1rem;
}

.onboarding-step-menu-data {
  .modal {
    .modal-container {
      width: 70%;
    }

    .modal-content {
      width: 110vh;
      height: 80vh;
    }

    .modal-body {
      height: 65vh;
    }

    .rdw-editor-main {
      height: 55vh;
    }
  }
}
.modal-dialog.modal-style-test {
  min-width: 80vh ;
  margin-left: 22.5%;

  .modal-container {
    width: 70%;
  }

  .modal-content {
    width: 110vh;
    height: 80vh;
  }

  .modal-body {
    height: 65vh;
  }

  .rdw-editor-main {
    height: 55vh;
  }
}
