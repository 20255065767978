.CompleteYourSetupView {
  display: flex;
  justify-content: center;
  color: $primary-text-color;

  .container {
    display: flex;
    justify-content: center;
    margin: 0%;
  }

  h2 {
    font-size: 32px;
    padding-bottom: 0.8vw;
    margin-bottom: 0px;
  }

  img {
    margin-top: 7.5vw;
    width: 100%;
  }

  .circleComplete {
    height: 22px;
    width: 22px;
    background-color: $emailModal;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    font-family: 'Lato';
    font-weight: bold;
    font-size: 13px;
  }

  span {
    padding-left: 0.5vw;
    font-size: 16px;
  }

  .row{
    padding-top: 0.6vw;
  }

  .fa-check-circle {
    color: $bs-success;
    font-size: 22px!important;
    line-height: normal;
    font-size: 16px;
  }
}