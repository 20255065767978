@import "../../Styles/Variables.scss";

div.MaintenanceView {
  .stroke-transparent {
    -webkit-text-stroke: 1px $bs-secondary;
    -webkit-text-fill-color: transparent;
  }

  a {
    color: $primary-text-color;
    font-family: $lato;

    &:hover {
      color: $primary-text-color;
    }

    span {
      position: relative;
      top: 20px;
      color: $primary-text-color;

      &:hover {
        -webkit-transform: scale(1.3);
        -ms-transform: scale(1.3);
        transform: scale(1.3);
        transition: width 2s;
        transition-duration: 2s;
        text-decoration: underline;
        border-radius: 0px;
      }
    }
  }

  button.btn {
    display: inline-block;
    border-radius: 10px;
    transition: all 0.3s;
    position: relative;
    z-index: 1;
    height: 63px;
    width: 100%;
    padding: 0px;
    background: transparent;
    border: none;
    color: $tertiary-color;

    &:hover {
      color: $primary-text-color;
      background: $offWhite;
      border-radius: 0px;
      border: none;
      outline: 0;
    }

    &:focus {
      color: $primary-text-color;
      background: transparent;
      border: none;
      outline: 0;
    }

    &:active {
      color: $primary-text-color;
      background: transparent;
      border: none;
      outline: 0;
    }

    i.fa {
      font-size: 28px;
      position: absolute;
      color: $bs-secondary;
      top: 3px;
      right: 46%;

      &.fa-language {
        right: 45%;
      }

      &.fa-hospital {
        right: 45%;
      }

      &.fa-industry {
        right: 45%;
      }

      &.fa-building {
        right: 47%;
      }

      &.fa-newspaper {
        right: 47%;
      }

      &.fa-file-image {
        right: 48%;
      }

      &.fa-check-square {
        right: 47%;
      }

      &.fa-question {
        right: 48%;
      }

      &.fa-cog {
        right: 45%;
      }

      &.fa-graduation-cap {
        right: 44%;
      }

      &.fa-xmarks-lines {
        right: 44%;
      }

      .stroke-transparent {
        -webkit-text-stroke: 1px $tertiary-color;
        -webkit-text-fill-color: transparent;
      }
    }
  }

  .btn-secondary:not(:disabled):not(.disabled):active,
  .btn-secondary:not(:disabled):not(.disabled).active,
  .show>.btn-secondary.dropdown-toggle {
    color: $bs-secondary;
    background-color: transparent;
    background-image: none;
    border-color: transparent;
  }

  button.btn.btn-secondary:hover {
    background: $bs-light !important;
    border: none;
    box-shadow: none;
    height: 63px;
    width: 96%;

    &:active {
      border: none;
      outline: 0;
    }
  }

  //media tags
  @media (min-width: 760px) and (max-width: 1101px) {
    button.btn i.fa {
      right: 43%;
      top: -3px;
    }

    @media (min-width: 1102px) and (max-width: 1690px) {
      button.btn i.fa {
        right: 42%;
        top: -3px;
      }
    }
  }
}

.appended-btn-info-mng {
  .input-group-append {
    button.text-center.btn.btn-outline-primary {
      position: relative;
      z-index: 0;
      min-width: 39px;
      background: $white !important;
      border: 1px solid $bs-secondary !important;
      border-radius: 0px;
      border: none;
      outline: 0;
    }
  }
}

.campaignMessageMaintenanceRichTextEditor {
  .public-DraftStyleDefault-block {
    margin: 0;
  }
}